import React from 'react'
import styles from './CustomMarks.module.scss' // Assuming you have styles for custom marks

interface CustomMarksProps {
  min: number
  max: number
  step: number
  value: number
  onChange: (value: number) => void
}

const CustomMarks: React.FC<CustomMarksProps> = ({ min, max, step, value, onChange }) => {
  const renderMarks = () => {
    const marks = []
    for (let i = min; i <= max; i += step) {
      marks.push(
        <div
          key={i}
          className={`${styles.mark} ${i === value ? styles.activeMark : ''}`}
          style={{ left: `${((i - min) / (max - min)) * 100}%` }}
          onClick={() => onChange(i)}>
          {/* {i === 0 || i === 10 || i === 50 || i === 100 ? (
            <span>{i}</span>
          ) : (
            <span style={{ fontSize: '10px' }}>|</span>
          )} */}
          <span>{i}</span>
        </div>,
      )
    }
    return marks
  }

  return <div className={styles.customMarks}>{renderMarks()}</div>
}

export default CustomMarks
