import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithValidation } from 'common/api/baseQueryWithValidation'

export const UpgradeApi: any = createApi({
  reducerPath: 'UpgradeApi',
  baseQuery: baseQueryWithValidation,
  endpoints: (builder) => ({
    getPricingPlans: builder.query({
      query: () => ({
        url: `${process.env.REACT_APP_API_URL}/upgrade/getPricingPlans`,
        method: 'GET',
      }),
    }),
    generateStripeLink: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/upgrade/generateStripeLink`,
        method: 'POST',
        body: params,
      }),
    }),
    getSessionStatus: builder.query({
      query: (params: any) => ({
        url: `${process.env.REACT_APP_API_URL}/upgrade/getSessionStatus`,
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const { useLazyGetPricingPlansQuery, useLazyGenerateStripeLinkQuery, useLazyGetSessionStatusQuery } = UpgradeApi
