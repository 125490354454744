import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Input, Menu, Select, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { industries, personas } from 'common/components/OutreachAI/Helpers/CampaignConstants'
import { CountryRegionData } from 'react-country-region-selector'
import styles from './SettingsUtils.module.scss'
const { Option } = Select

interface BusinessDataType {
  key: string
  name: string
  job_title: string[]
  locations: string[]
  industry: string[]
  number_of_employees: string[]
  competitive_advantage: string[]
  problem: string
  solution: string
  results: string
}
interface IndividualDataType {
  key: string
  persona_id: string
  name: string
  locations: string[]
  problem: string
  solution: string
  results: string
  description?: string
  competitive_advantage: string[]
  interests?: string[]
}

const industryValues = industries.map((industry) => ({ value: industry }))
const personasValues = personas.map((persona) => ({ value: persona }))

const sortSelectedOptions = (options: any, selectedValues: any) => {
  const selectedOptions = options.filter((option: any) => selectedValues.includes(option.value))
  const unselectedOptions = options.filter((option: any) => !selectedValues.includes(option.value))
  return [...selectedOptions, ...unselectedOptions]
}

const formatOptions = () => {
  return CountryRegionData.flatMap(([countryName, , regionsString]) => {
    const regions = regionsString ? regionsString.split('|') : []
    const countryOption = {
      value: countryName,
      label: (
        <div>
          <label style={{ fontWeight: 600, cursor: 'pointer' }}>
            {countryName} <span style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>country</span>
          </label>
        </div>
      ),
    }
    const regionOptions = regions.map((region) => {
      const [regionName, regionCode] = region.split('~')
      return {
        value: `${regionName}, ${regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''}${countryName}`,
        label: (
          <div style={{ cursor: 'pointer' }}>
            <label style={{ fontSize: '12px', cursor: 'pointer' }}>{`${regionName}, ${
              regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''
            }${countryName} `}</label>
            <label style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>region</label>
          </div>
        ),
      }
    })

    return [countryOption, ...regionOptions]
  })
}

const options = formatOptions()

const sortedLocationOptions: any = options //sortSelectedOptions(options, location)

export const renderTags = (tags: string[], color: string, backgroundColor: string) => {
  if (!tags || tags.length === 0) return null

  const validTags = Array.isArray(tags) ? tags : []

  if (validTags.length === 0) return null

  let [firstTag, secondTag, ...restTags]: any = validTags

  // Check if the firstTag length is greater than 10 and include the secondTag in restTags
  if (firstTag && firstTag.length > 0 && secondTag) {
    restTags = [secondTag, ...restTags]
    secondTag = undefined // Clear the secondTag since it is moved to restTags
  }

  const tooltipContent = (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center', alignItems: 'center' }}>
      {restTags?.map((tag: string, index: number) => (
        <Tag style={{ margin: 0, backgroundColor, color }} key={index}>
          {tag}
        </Tag>
      ))}
    </div>
  )

  return (
    <div
      style={
        {
          // display: 'flex',
          // maxWidth: '100%',
          // flexDirection: 'column',
          // gap: '5px',
          // justifyContent: 'center',
          // alignItems: 'center',
        }
      }>
      <Tooltip
        className='whiteTooltip'
        title={firstTag?.length > 15 ? <Tag style={{ backgroundColor, color, margin: 0 }}>{firstTag}</Tag> : ''}
        overlayStyle={{ maxWidth: 300 }}
        overlayInnerStyle={{ backgroundColor: 'white' }}>
        <Tag style={{ backgroundColor, color }}>
          {firstTag?.length > 15 ? firstTag?.slice(0, 15)?.trim() + '...' : firstTag}
        </Tag>
      </Tooltip>
      {secondTag && <Tag style={{ backgroundColor, color }}>{secondTag}</Tag>}
      {restTags?.length > 0 && (
        <Tooltip
          className='whiteTooltip'
          title={tooltipContent}
          overlayStyle={{ maxWidth: 300 }}
          overlayInnerStyle={{ backgroundColor: 'white' }}>
          <Tag style={{ backgroundColor, color }}>{`+${restTags.length}`}</Tag>
        </Tooltip>
      )}
    </div>
  )
}

export const renderEditField = (
  dataIndex: keyof (BusinessDataType & IndividualDataType),
  editData: any,
  setEditData: React.Dispatch<React.SetStateAction<any>>,
  personasValues: { value: string }[],
  industryValues: { value: string }[],
  sortedLocationOptions: { value: string; label: string }[],
  width: number,
  newTag?: string,
  setNewTag?: React.Dispatch<React.SetStateAction<string>>,
  tags?: string[],
  setTags?: any,
) => {
  if (!editData) return null // Return nothing if no data is being edited

  const value = editData[dataIndex]
  const selectStyle = { minWidth: '160px', marginBottom: '8px' }
  if (dataIndex === 'job_title') {
    // For job titles
    return (
      <Select
        mode='multiple'
        maxTagCount={1}
        placeholder='Select job titles'
        className='onboardingICP editICP'
        style={{ ...selectStyle, minWidth: `${width}px` }}
        tagRender={(tag: any) => <Tag className='onboardingICP'>{tag?.value?.slice(0, 10)?.trim()}...</Tag>}
        value={value as string[]}
        onChange={(newValue) => setEditData({ ...editData, job_title: newValue })}
        options={personasValues}
        onClick={(e) => e.stopPropagation()}
      />
    )
  } else if (dataIndex === 'industry') {
    // For industries
    return (
      <Select
        mode='multiple'
        maxTagCount={1}
        placeholder='Select up to 3 industries'
        className='onboardingICP editICP'
        style={{ ...selectStyle, minWidth: `${width}px` }}
        value={value as string[]}
        dropdownStyle={{ minWidth: width + 50 }}
        tagRender={(tag: any) => <Tag className='onboardingICP'>{tag?.value?.slice(0, 10)?.trim()}...</Tag>}
        onChange={(newValue: string[]) => {
          if (newValue.length > 3) {
            const latest = newValue.pop()
            newValue.pop()
            const newVal: any = [...newValue, latest]
            setEditData({ ...editData, industry: newVal })
          } else {
            setEditData({ ...editData, industry: newValue })
          }
        }}
        options={sortSelectedOptions(industryValues, value)}
        onClick={(e) => e.stopPropagation()}
      />
    )
  } else if (dataIndex === 'locations') {
    // For locations
    return (
      <Select
        mode='multiple'
        maxTagCount={1}
        placeholder='Select Location'
        className='onboardingICP editICP'
        style={{ ...selectStyle, minWidth: `${width}px` }}
        dropdownStyle={{ minWidth: '350px' }}
        value={value as string[]}
        tagRender={(tag: any) => <Tag className='onboardingICP'>{tag?.value?.slice(0, 10)?.trim()}...</Tag>}
        onChange={(newValue) => setEditData({ ...editData, locations: newValue })}
        options={sortSelectedOptions(sortedLocationOptions, value)}
        filterOption
        onClick={(e) => e.stopPropagation()}
      />
    )
  } else if (dataIndex === 'number_of_employees') {
    // For number of employees
    return (
      <Select
        mode='multiple'
        maxTagCount={1}
        placeholder='Select number of employees'
        className='onboardingICP editICP'
        style={{ ...selectStyle, minWidth: `${width}px` }}
        value={value as string[]}
        tagRender={(tag: any) => <Tag className='onboardingICP'>{tag?.value?.slice(0, 10)?.trim()}...</Tag>}
        onChange={(newValue) => setEditData({ ...editData, number_of_employees: newValue })}
        onClick={(e) => e.stopPropagation()}>
        <Select.Option value='1-10'>1-10</Select.Option>
        <Select.Option value='11-50'>11-50</Select.Option>
        <Select.Option value='51-200'>51-200</Select.Option>
        <Select.Option value='201-500'>201-500</Select.Option>
        <Select.Option value='501-2000'>501-2000</Select.Option>
        <Select.Option value='2000+'>2000+</Select.Option>
      </Select>
    )
  } else if (dataIndex === 'interests') {
    return (
      <Select
        onClick={(e) => e.stopPropagation()}
        className='onboardingICP'
        mode='multiple'
        size={'middle'}
        placeholder='Search By Tag'
        defaultValue={[]}
        value={value || undefined}
        onChange={(value: string | string[]) => {
          setEditData({ ...editData, interests: value })
        }}
        style={{ ...selectStyle, minWidth: `${width}px` }}
        searchValue={newTag}
        onSearch={(value: string) => {
          setNewTag && setNewTag(value)
        }}
        onInputKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            if (
              newTag &&
              newTag.trim() !== '' &&
              !(tags && tags.includes(newTag)) &&
              !editData.interests.includes(newTag)
            ) {
              if (Array.isArray(tags)) {
                setTags && setTags([...tags, newTag])
              }
              setEditData({ ...editData, interests: [...editData.interests, newTag] })
              setNewTag && setNewTag('')
            }
          }
        }}
        notFoundContent={
          newTag !== '' && (
            <Button
              onClick={() => {
                if (
                  newTag &&
                  newTag.trim() !== '' &&
                  !(tags && tags.includes(newTag)) &&
                  !editData.interests.includes(newTag)
                ) {
                  if (Array.isArray(tags)) {
                    setTags && setTags([...tags, newTag])
                  }
                  setEditData({ ...editData, interests: [...editData.interests, newTag] })
                  setNewTag && setNewTag('')
                }
              }}
              className='addNewTag'>
              {`Add "${newTag}"`}
            </Button>
          )
        }>
        {tags &&
          tags?.length > 0 &&
          tags?.map((tag) => <Option value={tag} label={tag} children={undefined} key={tag} />)}
      </Select>
    )
  } else {
    // For all other fields, return a generic Input component
    return (
      <Input
        placeholder={`Please type your ${dataIndex}`}
        onClick={(e) => e.stopPropagation()}
        // className={styles.input}
        style={{ maxWidth: '250px' }}
        value={value as string}
        onChange={(e) => setEditData({ ...editData, [dataIndex]: e.target.value })}
      />
    )
  }
}

export const getBusinessColumns = (
  editingKey: string | null,
  editData: BusinessDataType | null,
  startEdit: (record: BusinessDataType) => void,
  saveEdit: () => void,
  cancelEdit: () => void,
  setEditData: React.Dispatch<React.SetStateAction<BusinessDataType | IndividualDataType | null>>,
): ColumnsType<BusinessDataType> => [
  {
    title: 'Persona Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    render: (text: string, record: BusinessDataType) => {
      return record.key === editingKey ? (
        <Input
          value={editData?.name}
          onChange={(e) => setEditData(editData ? { ...editData, name: e.target.value } : null)}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <Tooltip title={text}>
          <span>{text?.length > 20 ? text.slice(0, 20) + '...' : text}</span>
        </Tooltip>
      )
    },
  },
  {
    title: 'Job title',
    dataIndex: 'job_title',
    key: 'job_title',
    width: 190,
    render: (tags: string[], record: BusinessDataType) =>
      record.key === editingKey
        ? renderEditField(
            'job_title',
            editData,
            setEditData,
            personasValues,
            industryValues,
            sortedLocationOptions,
            170,
          )
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Industries',
    dataIndex: 'industry',
    key: 'industry',
    width: 190,
    render: (tags: string[], record: BusinessDataType) =>
      record.key === editingKey
        ? renderEditField('industry', editData, setEditData, personasValues, industryValues, sortedLocationOptions, 170)
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Location',
    dataIndex: 'locations',
    key: 'locations',
    width: 200,
    render: (tags: string[], record: BusinessDataType) =>
      record.key === editingKey
        ? renderEditField(
            'locations',
            editData,
            setEditData,
            personasValues,
            industryValues,
            sortedLocationOptions,
            180,
          )
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Size',
    dataIndex: 'number_of_employees',
    key: 'number_of_employees',
    width: 150,
    render: (tags: string[], record: BusinessDataType) =>
      record.key === editingKey
        ? renderEditField(
            'number_of_employees',
            editData,
            setEditData,
            personasValues,
            industryValues,
            sortedLocationOptions,
            130,
          )
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Competitive Advantage',
    dataIndex: 'competitive_advantage',
    key: 'competitive_advantage',
    width: 180,
    render: (text: string, record: BusinessDataType) =>
      record.key === editingKey ? (
        renderEditField(
          'competitive_advantage',
          editData,
          setEditData,
          personasValues,
          industryValues,
          sortedLocationOptions,
          160,
        )
      ) : (
        <Tooltip title={text}>
          <span>{text?.length > 20 ? text.slice(0, 20) + '...' : text}</span>
        </Tooltip>
      ),
  },
  {
    title: '',
    key: 'edit',
    width: 80,
    render: (_, record: BusinessDataType) => {
      const isEditing = record.key === editingKey

      const menu = (
        <Menu>
          <Menu.Item
            onClick={(e: any) => {
              e?.domEvent.stopPropagation()
              startEdit(record)
            }}>
            Edit Persona
          </Menu.Item>
        </Menu>
      )

      return isEditing ? (
        <div className={styles.saveCancel} onClick={(e: any) => e.stopPropagation()}>
          <Button
            onClick={(e) => {
              saveEdit()
            }}
            type='link'
            style={{ color: '#7043ff', fontWeight: 600 }}>
            Save
          </Button>
          <Button
            onClick={(e) => {
              cancelEdit()
            }}
            type='link'
            style={{ color: '#7043ff' }}>
            Cancel
          </Button>
        </div>
      ) : (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button onClick={(e: any) => e.stopPropagation()} type='link' icon={<EllipsisOutlined />} />
        </Dropdown>
      )
    },
  },
]

export const getIndividualColumns = (
  editingKey: string | null,
  editData: IndividualDataType | null,
  startEdit: (record: IndividualDataType) => void,
  saveEdit: () => void,
  cancelEdit: () => void,
  setEditData: React.Dispatch<React.SetStateAction<BusinessDataType | IndividualDataType | null>>,
  newTag?: string,
  setNewTag?: React.Dispatch<React.SetStateAction<string>>,
  allTags?: string[],
  setAllTags?: any,
): ColumnsType<IndividualDataType> => [
  {
    title: 'Persona Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    render: (text: string, record: IndividualDataType) => {
      return record.persona_id === editingKey ? (
        <Input
          value={editData?.name}
          onChange={(e) => setEditData(editData ? { ...editData, name: e.target.value } : null)}
          onClick={(e) => e.stopPropagation()}
        />
      ) : (
        <Tooltip title={text}>
          <span>{text?.length > 20 ? text.slice(0, 20) + '...' : text}</span>
        </Tooltip>
      )
    },
  },
  {
    title: 'Interests',
    dataIndex: 'interests',
    key: 'interests',
    width: 190,
    render: (tags: string[], record: IndividualDataType) =>
      record.persona_id === editingKey
        ? renderEditField(
            'interests',
            editData,
            setEditData,
            personasValues,
            industryValues,
            sortedLocationOptions,
            170,
            newTag,
            setNewTag,
            allTags,
            setAllTags,
          )
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Location',
    dataIndex: 'locations',
    key: 'locations',
    width: 200,
    render: (tags: string[], record: IndividualDataType) =>
      record.persona_id === editingKey
        ? renderEditField(
            'locations',
            editData,
            setEditData,
            personasValues,
            industryValues,
            sortedLocationOptions,
            180,
          )
        : renderTags(tags, '#7043ff', '#7043FF1A'),
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 180,
    render: (text: string, record: IndividualDataType) =>
      record.persona_id === editingKey ? (
        renderEditField(
          'description',
          editData,
          setEditData,
          personasValues,
          industryValues,
          sortedLocationOptions,
          160,
        )
      ) : (
        <Tooltip title={text}>
          <span>{text?.length > 20 ? text.slice(0, 20) + '...' : text}</span>
        </Tooltip>
      ),
  },
  {
    title: 'Competitive Advantage',
    dataIndex: 'competitive_advantage',
    key: 'competitive_advantage',
    width: 180,
    render: (text: string, record: IndividualDataType) =>
      record.persona_id === editingKey ? (
        renderEditField(
          'competitive_advantage',
          editData,
          setEditData,
          personasValues,
          industryValues,
          sortedLocationOptions,
          160,
        )
      ) : (
        <Tooltip title={text}>
          <span>{text?.length > 20 ? text.slice(0, 20) + '...' : text}</span>
        </Tooltip>
      ),
  },
  {
    title: '',
    key: 'edit',
    width: 80,
    render: (_, record: IndividualDataType) => {
      const isEditing = record.persona_id === editingKey

      const menu = (
        <Menu>
          <Menu.Item
            onClick={(e: any) => {
              e?.domEvent.stopPropagation()
              startEdit(record)
            }}>
            Edit Persona
          </Menu.Item>
        </Menu>
      )

      return isEditing ? (
        <div className={styles.saveCancel} onClick={(e: any) => e.stopPropagation()}>
          <Button
            onClick={(e) => {
              saveEdit()
            }}
            type='link'
            style={{ color: '#7043ff', fontWeight: 600 }}>
            Save
          </Button>
          <Button
            onClick={(e) => {
              cancelEdit()
            }}
            type='link'
            style={{ color: '#7043ff' }}>
            Cancel
          </Button>
        </div>
      ) : (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button onClick={(e: any) => e.stopPropagation()} type='link' icon={<EllipsisOutlined />} />
        </Dropdown>
      )
    },
  },
]

export const renderExpandableEditField = (
  field: keyof (BusinessDataType & IndividualDataType),
  editData: any,
  setEditData: React.Dispatch<React.SetStateAction<any>>,
) => (
  <Input.TextArea
    value={editData ? (editData[field] as any) : ''}
    onChange={(e) => setEditData(editData ? { ...editData, [field]: e.target.value } : null)}
    autoSize={{ minRows: 2, maxRows: 6 }}
  />
)

export const areAllKeysFilled = (obj: any, checkAll: boolean) => {
  if (obj.persona_type === 'business') {
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        if (key === 'parsed_locations' || key === 'interests') continue
        if (value.length === 0 || !value.every((item) => typeof item === 'string' && item.trim().length > 0)) {
          return false
        }
      } else if (typeof value === 'string') {
        if (['problem', 'solution', 'results', 'competitive_advantage', 'description'].includes(key) && !checkAll) {
          continue
        }
        if (value.trim().length === 0 && key !== 'description') {
          return false
        }
      } else {
        return false
      }
    }
    return true
  } else {
    for (const [key, value] of Object.entries(obj)) {
      if (key === 'parsed_locations' || key === 'job_title' || key === 'industry' || key === 'number_of_employees')
        continue
      if (Array.isArray(value)) {
        if (value.length === 0 || !value.every((item) => typeof item === 'string' && item.trim().length > 0)) {
          return false
        }
      } else if (typeof value === 'string') {
        if (
          ['problem', 'solution', 'results', 'competitive_advantage', 'description', 'job_title'].includes(key) &&
          !checkAll
        ) {
          continue
        }
        if (value.trim().length === 0) {
          return false
        }
      } else {
        return false
      }
    }
  }
  return true
}
