import React, { useState } from 'react'
import styles from './SocialMediaCard.module.scss'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_logo from 'common/assets/svg/instagram_logo.svg'
import twitter_logo from 'common/assets/svg/twitter_logo.svg'
import discord_logo from 'common/assets/svg/discord_logo.svg'
import linkedin_logo from 'common/assets/svg/linkedin_logo.svg'
import telegram_logo from 'common/assets/svg/telegram_logo.svg'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import { Tooltip } from 'antd'

import premium_badge from 'common/assets/svg/crown.svg'

import { useAppSelector } from 'state'
// import Item from 'antd/es/list/Item'

interface SocialMediaCardProps {
  platform: any
  count: number
  isConnected: boolean
  socialId: string
  toggleConnection: any
  setCheckLocalStorage: any
}

const platformIcons: any = {
  facebook: facebook_icon,
  instagram: instagram_logo,
  twitter: twitter_logo,
  discord: discord_logo,
  linkedin: linkedin_logo,
  telegram: telegram_logo,
}

const SocialMediaCard: React.FC<SocialMediaCardProps> = ({
  platform,
  count,
  isConnected,
  socialId,
  toggleConnection,
  setCheckLocalStorage,
}) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)
  const { active_platforms } = useAppSelector((state) => state.outreachAICampaign)
  const [isActive, setActive] = useState(active_platforms[platform])
  // const [disableSelection, setDisableSelection] = useState(false)

  const toggleClass = () => {
    if (user?.addons[`channel_${platform}`] === true)
      if (count > 0) {
        setActive(!isActive)
        toggleConnection()
      } else if (['instagram', 'facebook', 'linkedin']?.includes(platform)) {
        setOpenSocialConnectionModal(true)
      }
  }

  const platformLogo = (platform: string) => {
    return (
      <div
        className={`${styles.platformLogo} ${
          active_platforms[platform] ? styles['connected'] : styles['disconnected']
        }`}>
        <img src={platformIcons[platform]} alt='' />
        <p>{platform === 'twitter' ? 'X (Twitter)' : platform[0].toLocaleUpperCase() + platform.slice(1)}</p>
      </div>
    )
  }

  // useEffect(() => {
  //   if (selected_leads?.length > 0 && selected_platforms_accounts[platform]?.length === 0) {
  //     setDisableSelection(true)
  //   } else {
  //     setDisableSelection(false)
  //   }
  // }, [selected_leads])

  return (
    <label htmlFor={`${active_platforms[platform] && socialId}`}>
      <div
        className={`${styles.socialMediaCard} ${
          active_platforms[platform] ? styles['connected'] : styles['disconnected']
        }`}
        onClick={toggleClass}>
        {!user?.addons[`channel_${platform}`] && (
          <Tooltip placement='top' title={'Not available in your plan'}>
            <img src={premium_badge} alt='Small' className={styles.premium_badge} />
          </Tooltip>
        )}

        {platformLogo(platform)}
        <div className={styles.accounts}>
          <span
            className={`${styles['dot']} ${active_platforms[platform] ? styles['connected'] : styles['disconnected']}`}
          />
          {isConnected && count > 0 ? (
            <p style={!active_platforms[platform] ? { color: '#ccc' } : {}}>
              {count} {count === 1 ? 'acount' : 'accounts'}
            </p>
          ) : (
            <p style={{ color: '#ccc' }}>Disconnected</p>
          )}
        </div>
      </div>
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={platform}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
    </label>
  )
}

export default SocialMediaCard
