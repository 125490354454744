import React, { useEffect, useState } from 'react'
import { Table, Card, Layout, Button, Tooltip, Modal, message, Empty, Popover } from 'antd'
import welcome_to_convrt from 'common/assets/svg/welcome_to_convrt.svg'
import styles from './ICPSettings.module.scss'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import { PlusCircleOutlined } from '@ant-design/icons'
import OnboardingICP from './Onboarding/OnboardingICP'
import {
  useLazyGetUserSettingsQuery,
  useAddIndividualPersonasMutation,
  useLazySubmitOnboardingStepQuery,
  useLazyUpdateICPQuery,
  useUpdateIndividualPersonasMutation,
  useLazyGetIndividualPersonasQuery,
} from '../state/api/SettingsApi'
import { DownOutlined, UpOutlined } from 'styles/Icons/Icons'
import { areAllKeysFilled, getBusinessColumns, getIndividualColumns, renderExpandableEditField } from './SettingsUtils'
import { useGetTagsMutation } from 'features/intent-signals/state/api/CompaniesApi'
import { post } from 'common/api/axios'

interface BusinessDataType {
  key: string
  name: string
  job_title: string[]
  locations: string[]
  industry: string[]
  number_of_employees: string[]
  competitive_advantage: string[]
  problem: string
  solution: string
  results: string
}
interface IndividualDataType {
  key: string
  persona_id: string
  name: string
  locations: string[]
  problem: string
  solution: string
  results: string
  description?: string
  competitive_advantage: string[]
  interests?: string[]
}

interface ICPSettingsProps {
  businessPersonas: BusinessDataType[]
  individualPersonas: IndividualDataType[]
}
const ICPSettings: React.FC<ICPSettingsProps> = ({ businessPersonas = [], individualPersonas = [] }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [personaType, setPersonaType] = useState<string>('')
  const [businessPersonasTableData, setBusinessPersonasTableData] = useState(businessPersonas)
  const [individualPersonasTableData, setIndividualPersonasTableData] = useState(individualPersonas)
  const [refetchGetTags] = useGetTagsMutation()
  const [editingKey, setEditingKey] = useState<string | null>(null)
  const [editData, setEditData] = useState<BusinessDataType | IndividualDataType | any>(null)
  const [highlightMissingValues, setHighlightMissingValues] = useState(false)
  // const [disableAddPersona, setDisableAddPersona] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openPopover, setOpenPopover] = useState(false)
  const [ICPData, setICPData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [emptyState, setEmptyState] = useState(false)
  const [getIndividualPersonas] = useLazyGetIndividualPersonasQuery()
  const [addIndividualPersonas] = useAddIndividualPersonasMutation()
  const [submitOnboardingStep] = useLazySubmitOnboardingStepQuery()
  const [updateICP] = useLazyUpdateICPQuery()
  const [updateIndividualPersonas] = useUpdateIndividualPersonasMutation()
  const [getUserSettings] = useLazyGetUserSettingsQuery()
  const [tags, setTags] = useState<string[]>([])
  const [newTag, setNewTag] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const result = await refetchGetTags(newTag)
      setTags(result.data || [])
    }

    fetchData().catch(console.error)
  }, [newTag])

  const startEditBusinessPersonas = (record: BusinessDataType) => {
    setEditingKey(record.key)
    setEditData({ ...record })
  }

  const startEditIndividualPersonas = (record: IndividualDataType) => {
    setEditingKey(record.persona_id)
    setEditData({ ...record })
  }

  const replaceObjectByKey = (data: any, newObject: any) => {
    return data.map((item: any) => (item.key === newObject.key ? newObject : item))
  }

  const validate = (data: any) => {
    if (!data.name) {
      return 'Persona Name'
    }
    if (!data.job_title || data.job_title?.length === 0) {
      return 'Job Title'
    }
    if (!data.industry || data.industry?.length === 0) {
      return 'Industry'
    }
    if (!data.locations || data.locations?.length === 0) {
      return 'Location'
    }
    if (!data.number_of_employees || data.number_of_employees?.length === 0) {
      return 'Number of Employees'
    }
    return 'None'
  }
  const saveEditBusinessPersonas = async () => {
    const valid = validate(editData)
    if (valid !== 'None') {
      message.error(`${valid} is missing.`)
      return
    }
    const updatedData = replaceObjectByKey(businessPersonas, editData)
    const { data: icpData } = await updateICP({ data: editData })
    if (icpData) {
      setBusinessPersonasTableData(updatedData)
      setEditingKey(null)
      setEditData(null)
      await getUserSettings()
      message.success('Persona updated successfully')
    } else {
      message.error('Something went wrong. Please try again.')
    }
  }

  const saveEditIndividualPersonas = async () => {
    if (!editData.name) {
      message.error(`Persona Name is missing.`)
      return
    }
    try {
      const updatedData = individualPersonas.map((item: any) =>
        item.persona_id === editData.persona_id ? editData : item,
      )

      await updateIndividualPersonas(editData)
      setIndividualPersonasTableData(updatedData)
      setEditingKey(null)
      setEditData(null)
      await getUserSettings()
      message.success('Persona updated successfully')
    } catch (error) {
      message.error('Something went wrong. Please try again.')
    }
  }

  const cancelEdit = () => {
    setEditingKey(null)
    setEditData(null)
  }

  const businessColumns = getBusinessColumns(
    editingKey,
    editData,
    startEditBusinessPersonas,
    saveEditBusinessPersonas,
    cancelEdit,
    setEditData,
  )

  const individualColumns = getIndividualColumns(
    editingKey,
    editData,
    startEditIndividualPersonas,
    saveEditIndividualPersonas,
    cancelEdit,
    setEditData,
    newTag,
    setNewTag,
    tags,
    setTags,
  )

  const expandableBusiness = {
    expandedRowRender: (record: BusinessDataType) => {
      const isEditing = record.key === editingKey
      return (
        <div className={styles.expandedContent}>
          <Card className={styles.section}>
            <strong>Problem</strong>
            {isEditing ? (
              renderExpandableEditField('problem', editData, setEditData)
            ) : record?.problem ? (
              <p>{record.problem}</p>
            ) : (
              <Empty description='No Problem Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Solutions</strong>
            {isEditing ? (
              renderExpandableEditField('solution', editData, setEditData)
            ) : record?.solution ? (
              <p>{record.solution}</p>
            ) : (
              <Empty description='No Solutions Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Results</strong>
            {isEditing ? (
              renderExpandableEditField('results', editData, setEditData)
            ) : record?.results ? (
              <p>{record.results}</p>
            ) : (
              <Empty description='No Results Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
        </div>
      )
    },
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }: any) =>
      expanded ? (
        <UpOutlined onClick={(e: any) => onExpand(record, e)} />
      ) : (
        <DownOutlined onClick={(e: any) => onExpand(record, e)} />
      ),
    rowExpandable: (record: BusinessDataType) => record.name !== '',
  }

  const expandableIndividual = {
    expandedRowRender: (record: IndividualDataType) => {
      const isEditing = record.persona_id === editingKey
      return (
        <div className={styles.expandedContent}>
          <Card className={styles.section}>
            <strong>Problem</strong>
            {isEditing ? (
              renderExpandableEditField('problem', editData, setEditData)
            ) : record?.problem ? (
              <p>{record.problem}</p>
            ) : (
              <Empty description='No Problem Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Solutions</strong>
            {isEditing ? (
              renderExpandableEditField('solution', editData, setEditData)
            ) : record?.solution ? (
              <p>{record.solution}</p>
            ) : (
              <Empty description='No Solutions Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
          <Card className={styles.section}>
            <strong>Results</strong>
            {isEditing ? (
              renderExpandableEditField('results', editData, setEditData)
            ) : record?.results ? (
              <p>{record.results}</p>
            ) : (
              <Empty description='No Results Data' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Card>
        </div>
      )
    },
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }: any) =>
      expanded ? (
        <UpOutlined onClick={(e: any) => onExpand(record, e)} />
      ) : (
        <DownOutlined onClick={(e: any) => onExpand(record, e)} />
      ),
    rowExpandable: (record: IndividualDataType) => record.name !== '',
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const handleCancel = () => {
    setCurrentStep(1)
    setOpenModal(false)
  }

  const handleCreatePersona = async () => {
    if (!areAllKeysFilled(ICPData, true)) {
      setOpenPopover(true)
      setHighlightMissingValues(true)
    } else {
      proceedWithCreatePersona()
    }
  }

  const proceedWithCreatePersona = async () => {
    try {
      setLoading(true)
      if (ICPData.persona_type === 'business') {
        const { data } = await submitOnboardingStep({ step: 'ICP', data: ICPData })
        if (data?.success) {
          post('/log', {
            action: `Onboarding: User created persona`,
            data: {
              persona: ICPData,
              step: 2,
            },
          })
          setOpenModal(false)
          await getUserSettings()
          message.success('Persona Created Successfully!')
        } else {
          message.error(`Can't update user's ICP. Please try again or contact support.`)
        }
      } else if (ICPData.persona_type === 'individual') {
        await addIndividualPersonas(ICPData)
        post('/log', {
          action: `Onboarding: User created persona`,
          data: {
            persona: ICPData,
            step: 2,
          },
        })
        await getIndividualPersonas()
        message.success('Persona Created Successfully!')
        setOpenModal(false)
      }
      setLoading(false)
      setCurrentStep(1)
      setPersonaType('')
    } catch (error) {
      setLoading(false)
      message.error(`Can't update user's ICP. Please try again or contact support.`)
    }
  }

  useEffect(() => {
    if (businessPersonas.length === 0 && individualPersonas.length === 0) {
      setEmptyState(true)
      // setDisableAddPersona(false)
    } else {
      setBusinessPersonasTableData(businessPersonas)
      setIndividualPersonasTableData(individualPersonas)
      setEmptyState(false)
      // setDisableAddPersona(true)
    }
  }, [businessPersonas, individualPersonas])

  const footerStyle: React.CSSProperties = {
    textAlign: 'end',
    color: '#fff',
    backgroundColor: '#F9FAFB',
    padding: 0,
  }
  const contentStyle: React.CSSProperties = {
    backgroundColor: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: 0,
  }
  const headerStyle: React.CSSProperties = {
    backgroundColor: '#F9FAFB',
    padding: 0,
    height: 'fit-content',
  }
  const layoutStyle: React.CSSProperties = {
    display: 'flex',
    padding: '32px 64px',
    flexDirection: 'column',
    background: '#F9FAFB',
    height: '100%',
    gap: '32px',
  }

  const popoverContent = (
    <div className={styles.popoverContainer}>
      <p>Some values are missing. Would you like to fill them in?</p>
      <span
        onClick={() => {
          setOpenPopover(false)
          setHighlightMissingValues(false)
          proceedWithCreatePersona()
        }}>
        Continue Anyway
      </span>
    </div>
  )

  const empty = (
    <div className={styles.emptyState}>
      <img src={welcome_to_convrt} alt='' />
      <h1>Add your first Persona</h1>
      <p>Create a Persona and help our AI model identify, match and optimize your leads and campaigns</p>
      <Button type='primary' onClick={showModal}>
        <div className={styles.buttonDiv}>
          <PlusCircleOutlined />
          <span>Create a Persona</span>
        </div>
      </Button>
    </div>
  )

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <h2 className={styles.title}>Personas</h2>
      </Header>
      <Content style={contentStyle}>
        <Card className={`${styles.container} ${styles.table} ${emptyState ? 'cardEmpty' : ''}`}>
          {emptyState ? (
            empty
          ) : (
            <div className='flex flex-column gap-4'>
              {businessPersonasTableData && businessPersonasTableData.length > 0 && (
                <div>
                  <h3 style={{ textAlign: 'start' }}>Business Personas</h3>
                  <Table
                    className='ICPTable hoverableScrollbarTable'
                    tableLayout='fixed'
                    columns={businessColumns}
                    scroll={{ y: 460, x: '100%' }}
                    expandable={expandableBusiness}
                    dataSource={businessPersonasTableData}
                    pagination={false}
                  />
                </div>
              )}
              {individualPersonasTableData && individualPersonasTableData.length > 0 && (
                <div>
                  <h3 style={{ textAlign: 'start' }}>Individual Personas</h3>
                  <Table
                    className='ICPTable hoverableScrollbarTable'
                    tableLayout='fixed'
                    columns={individualColumns}
                    scroll={{ y: 460, x: '100%' }}
                    expandable={expandableIndividual}
                    dataSource={individualPersonasTableData}
                    pagination={false}
                  />
                </div>
              )}
            </div>
          )}
        </Card>
      </Content>
      <Footer style={footerStyle}>
        {!emptyState && (
          <Tooltip>
            <Button type='primary' onClick={showModal}>
              <div className={styles.buttonDiv}>
                <PlusCircleOutlined />
                <span>Add New Persona</span>
              </div>
            </Button>
          </Tooltip>
        )}
      </Footer>
      <Modal
        width={'75%'}
        open={openModal}
        onCancel={handleCancel}
        className={styles.modalContent}
        footer={
          <div className={styles.modalFooter}>
            <Popover
              content={popoverContent}
              open={openPopover}
              onOpenChange={() => {
                setOpenPopover(false)
                setHighlightMissingValues(false)
              }}>
              {currentStep === 2 && (
                <Button
                  loading={loading}
                  disabled={!areAllKeysFilled(ICPData, false)}
                  type='primary'
                  onClick={handleCreatePersona}>
                  Create Persona
                </Button>
              )}
            </Popover>
          </div>
        }>
        <OnboardingICP
          setICPData={setICPData}
          highlightMissingValues={highlightMissingValues}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          personaType={personaType}
          setPersonaType={setPersonaType}
        />
      </Modal>
    </Layout>
  )
}

export default ICPSettings
