import React, { useEffect, useState } from 'react'
import { Card, Typography, Progress, Input } from 'antd'
import styles from './ProfileSettings.module.scss'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
import PointsTableTooltip from './PointsTableTooltip'

const { Title, Text } = Typography

const planMapping: any = {
  go_to_market: 'Go To Market',
  connect_plus: 'Connect+',
  market_dominance: 'Market Dominance',
  custom: 'Custom',
  trial: 'Trial',
}

interface ProfileSettingsProps {
  shouldHideCheckout: any
}

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ shouldHideCheckout }) => {
  const [user] = useLocalStorage<any>('user', null)
  const addons = user.addons
  const userlocalStorage = localStorage.getItem('user') || '{}'
  const [userData, setUserData] = useState(JSON.parse(userlocalStorage))
  // const userData = JSON.parse(userlocalStorage)

  const navigate = useNavigate()
  // const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false)
  const [points, setPoints] = useState(user.convrt_points)
  const [magicPoints, setMagicPoints] = useState(user.magic_points)
  const fullName = user?.name
  const nameArray = fullName.split(' ')
  const firstName = nameArray[0]
  const lastName = nameArray.slice(1).join(' ')
  const yourPlan = user?.plan
    ? user?.plan
    : user?.plan_type
    ? planMapping[user?.plan_type]
    : user?.subscription_type === 'BETA'
    ? 'Trial'
    : user?.subscription_type

  const handleNavigation = () => {
    if (shouldHideCheckout || !addons.pricing) {
      window.open('https://calendly.com/roy-convrt/demo-convrt', '_blank')
    } else {
      navigate('/upgrade')
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const saved2 = localStorage.getItem('user') || ''
      const u = JSON.parse(saved2)
      setPoints(u.convrt_points)
      setMagicPoints(u.magic_points)
      setUserData(u)
    }, 250)
    return () => clearTimeout(timer)
  }, [user])

  // useEffect(() => {
  //   const userlocalStorage = localStorage.getItem('user') || '{}'
  //   // const userData = JSON.parse(userlocalStorage)
  //   setUserData(JSON.parse(userlocalStorage))
  // }, [user])

  const finalPoints = Math.max(user.initial_convrt_points, points)
  const usedPoints = Math.max(0, finalPoints - points)

  const finalMagicPoints = Math.max(user.initial_magic_points, magicPoints)
  const usedMagicPoints = Math.max(0, finalMagicPoints - magicPoints)

  return (
    <Card className={styles.profileCard}>
      <Title level={3}>Profile Settings</Title>
      <Card className={styles.profileInputsCard}>
        <div className={styles.inputRow} style={{ marginBottom: '20px' }}>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>First Name</Text>
            <Input disabled placeholder='First Name' value={firstName} />
          </div>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Last Name</Text>
            <Input disabled placeholder='Last Name' value={lastName} />
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Title</Text>
            <Input disabled placeholder='' />
          </div>
          <div className={styles.inputContainer}>
            <Text className={styles.inputTitle}>Timezone</Text>
            <Input disabled placeholder='' />
          </div>
        </div>
      </Card>

      <div className={styles.yourPlan}>
        <p style={{ fontSize: '20px' }}>Your Plan</p>
        <p style={{ fontSize: '35px' }}>
          {yourPlan}{' '}
          {!shouldHideCheckout && (
            <span
              style={{ color: '#7043ff', fontWeight: 600, cursor: 'pointer', fontSize: '16px' }}
              onClick={handleNavigation}>
              Upgrade
            </span>
          )}
        </p>
      </div>

      <div className={styles.progressBarContainer}>
        <Text className={styles.progressText}>
          {`${usedPoints} / ${finalPoints} Convrt Points Used `}
          <PointsTableTooltip isMagic={false} />
        </Text>
        <Progress
          percent={
            user.initial_convrt_points
              ? ((Math.max(user.initial_convrt_points, points) - points) /
                  Math.max(user.initial_convrt_points, points)) *
                100
              : 0
          }
          // size={[1400, 30]}
          status='active'
          showInfo={false}
          strokeColor={'#7043ff'}
        />
        <div className={styles.buyMoreBar}>
          <Text className={styles.buyMore} onClick={handleNavigation}>
            Buy More
          </Text>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Text className={styles.progressText}>
            {`${usedMagicPoints} / ${finalMagicPoints} Magic Points Used `}
            <PointsTableTooltip isMagic={true} />
          </Text>
          <Progress
            percent={
              user.initial_magic_points
                ? ((Math.max(user.initial_magic_points, magicPoints) - magicPoints) /
                    Math.max(user.initial_magic_points, magicPoints)) *
                  100
                : 0
            }
            // size={[400, 30]}
            status='active'
            showInfo={false}
            strokeColor={'#7043ff'}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <Text className={styles.progressText}>
            {`${
              userData?.initial_credits?.contacts_outreached_left - userData?.credits?.contacts_outreached_left || 0
            } / ${userData?.initial_credits?.contacts_outreached_left || 0} Contacts Outreached`}
          </Text>
          <Progress
            percent={
              userData?.initial_credits
                ? ((userData?.initial_credits?.contacts_outreached_left - userData?.credits?.contacts_outreached_left) /
                    userData?.initial_credits?.contacts_outreached_left) *
                  100
                : 0
            }
            // size={[400, 30]}
            status='active'
            showInfo={false}
            strokeColor={'#7043ff'}
          />
        </div>
        {/* <div className={styles.buyMoreBar}>
          <Text
            className={styles.buyMore}
            onClick={() => {
              navigate('/checkout')
            }}>
            Upgrade Plan
          </Text>
        </div> */}
      </div>
      {/* {openBuyCreditsModal && (
        <BuyCreditsModal
          open={openBuyCreditsModal}
          onClose={() => {
            setOpenBuyCreditsModal(false)
          }}
        />
      )} */}
    </Card>
  )
}

export default ProfileSettings
