import { jwtDecode } from 'jwt-decode'
export const isJWTVaild = (token: string) => {
  try {
    const decodedToken: any = jwtDecode(token)
    if (decodedToken?.exp * 1000 < Date.now()) {
      return undefined
    }
  } catch (error) {
    console.log('error', error)
    return undefined
  }
  return jwtDecode(token)
}
