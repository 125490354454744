import React, { useEffect, useState } from 'react'
import styles from './BuilderHeader.module.scss'
import { Breadcrumb, Button, Modal, Select, Form, Input, message, Tooltip } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import {
  RESET_BUILDER_SETUP_DATA,
  SET_BUILDER_DATA,
  SET_BUILDER_EDGES,
  SET_BUILDER_INCOMPLETE_NODES,
  SET_BUILDER_NODES,
  SET_CUSTOM_FLOW_ID,
  SET_SELECTED_BUILDER_NODE,
  SET_UPDATED_BUILDER_DATA,
  SET_UPDATED_BUILDER_EDGES,
  SET_UPDATED_BUILDER_NODES,
  SET_USE_CUSTOM_FLOW,
} from './Slice/CampaignBuilderSlice'
import { useAppDispatch, useAppSelector } from 'state'
import {
  useLazySaveCustomTemplateQuery,
  useLazySaveTemplateToMarketQuery,
  useLazyUpdateCampaignAiQuery,
} from '../state/api/OutreachAI-API'
import left_arrow from 'common/assets/svg/left_arrow.svg'
import { getShouldContainMessages } from 'common/utils/campaignHelpers'
import AreYouSureModal from 'common/components/General/AreYouSureModal'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import { getLayoutedElements, getParentNodeId } from './Utils/BuilderUtils'
import { transformToNewStructure } from '../CreateCampaign/transformStepsUtil'
import {
  INCREMENT_COLLAPSE_KEY,
  SET_NODES_DATA,
  SET_PROMPT,
  SET_SELECTED_NODE,
  SET_TRIGGER,
} from '../state/outreachAICampaignSlice'
import useCampaignNavigation from '../Helpers/useCampaignNavigation'
import { useLocation } from 'react-router-dom'

const { Option } = Select

const platformOptions = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'linkedin', label: 'LinkedIn' },
  // Add other platforms as needed
]

const BuilderHeader: React.FC = () => {
  const { navigateWithCampaignId } = useCampaignNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [form] = Form.useForm()
  const [updateCampaign] = useLazyUpdateCampaignAiQuery()
  const [saveCustomTemplate] = useLazySaveCustomTemplateQuery()
  const [saveTemplateToMarket] = useLazySaveTemplateToMarketQuery()
  const {
    updated_builder_data,
    builder_data,
    builder_edges,
    builder_nodes,
    updated_builder_nodes,
    updated_builder_edges,
    use_custom_flow,
  } = useAppSelector((state) => state.CampaignBuilder)
  const { active_platforms, flow_data, nodes_data, is_edit_campaign, campaign_id, ai_campaign_name, campaign_status } =
    useAppSelector((state) => state.outreachAICampaign)

  const [openModal, setOpenModal] = useState(false)
  const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false)
  const [navPage, setNavPage] = useState('')
  const [loading, setLoading] = useState(false)
  const [loadingSaveToMarket, setLoadingSaveToMarket] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [templateTags, setTemplateTags] = useState<string[]>([])
  const [getUserDetails, { data: userData }] = useLazyGetUserDetailsQuery()
  const save_to_market = userData?.addons?.save_to_market
  const isCustom = !(flow_data?.nodes?.length > 0)
  const truePlatforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  const platforms = Object.keys(active_platforms).filter((platform) => active_platforms[platform])

  const handleSaveAndUse = async (only_use?: boolean) => {
    try {
      setLoading(true)
      let values = form.getFieldsValue()
      let { name, description } = values

      // Set default name and description if only_use is true
      if (only_use) {
        name = name || `Default ${Date.now()}`
        description = description || 'Default'
      }

      if (!name) {
        setLoading(false)
        return message.error('Please enter a name.')
      }

      // Trim messages:
      const nodes = updated_builder_data.nodes.map((node: any) => {
        const { selected, ...restNode } = node
        if (node.data && node.data.messages) {
          // Remove empty strings from messages
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')
          // const newMessageIds = node.data.message_id.map((id: string) => 'uuid')
          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
              // message_id: newMessageIds,
              // sender: [],
            },
          }
        }
        return restNode
      })
      const edges = updated_builder_data.edges
      dispatch(SET_BUILDER_DATA({ nodes: nodes, edges: edges }))

      const updatedNodes = updated_builder_nodes.map((node: any) => {
        const { selected, ...restNode } = node
        if (node.data && node.data.messages) {
          // Remove empty strings from messages
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')

          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
            },
          }
        }
        return restNode
      })

      dispatch(SET_BUILDER_NODES(updatedNodes))
      dispatch(SET_BUILDER_EDGES(updated_builder_edges))
      dispatch(SET_SELECTED_BUILDER_NODE({}))
      dispatch(SET_SELECTED_NODE({}))
      // const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      //   updatedNodes,
      //   updated_builder_edges,
      //   true,
      // )

      const nodesToSave = updated_builder_nodes.map((node: any) => {
        const { selected, ...restNode } = node

        if (node.data && node.data.messages) {
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')
          const newMessageIds = node.data.message_id.map((m: string) => uuidv4())
          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
              message_id: newMessageIds,
              sender: [],
            },
          }
        } else {
          if (node.type !== 'initialNode' && node.type !== 'placeholderNode') {
            const newMessageIds = node.data.message_id.map((m: string) => uuidv4())
            return {
              ...restNode,
              data: {
                ...node.data,
                message_id: newMessageIds,
                sender: [],
              },
            }
          } else {
            return restNode
          }
        }
      })
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodesToSave,
        updated_builder_edges,
        true,
      )

      const template = JSON.stringify({ nodes: layoutedNodes, edges: layoutedEdges })
      const id = uuidv4()
      dispatch(SET_CUSTOM_FLOW_ID(id))

      const { data } = await saveCustomTemplate({
        id,
        name,
        description,
        template,
        platforms,
        is_visible: !only_use, // Set is_visible to false if only_use is true
      })

      if (data.success) {
        // const newNodes = nodes_data?.map((node: any) => {
        //   const updated = updated_builder_data?.nodes.filter((up: any) => up.id === node.step_id)
        //   if (node.data && updated?.length > 0) {
        //     const nd = updated[0]
        //     const data = nd.data
        //     const { amount, unit, sender, messages } = data
        //     const newData = { ...node.data }

        //     // Only update properties if they exist in both node.data and updated data
        //     if ('amount' in node.data && amount !== undefined) newData.amount = amount
        //     if ('unit' in node.data && unit !== undefined) newData.unit = unit
        //     if ('sender' in node.data && sender !== undefined) newData.sender = sender
        //     if ('messages' in node.data && messages !== undefined) {
        //       newData.messages = messages.filter((message: string) => message.trim() !== '')
        //     }
        //     return { ...node, data: newData }
        //   }
        //   return node
        // })

        const newNodes = updated_builder_data?.nodes?.map((node: any) => {
          const step_id = node.id
          const previous_step_id = getParentNodeId(step_id, updated_builder_data?.edges)
          const type = node.data.type
          const message_id = node.data.message_id
          const data = getShouldContainMessages(node.data.type, truePlatforms)
            ? {
                messages: node?.data?.messages?.filter((message: string) => message?.trim() !== ''),
                sender: node?.data?.sender,
              }
            : node.data.type === 'ai_responder' && Array.isArray(node.data?.responders)
            ? { responders: node.data?.responders, configuration: node.data?.configuration }
            : null

          return {
            step_id,
            previous_step_id,
            type,
            message_id,
            ...(data && { data: data }),
          }
        })

        dispatch(SET_NODES_DATA(newNodes))
        dispatch(SET_USE_CUSTOM_FLOW(true))
        dispatch(INCREMENT_COLLAPSE_KEY(''))
        navigateWithCampaignId('/outreach/create-new-campaign', { state: { prevPath: true } })
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const handleSaveToMarket = async () => {
    try {
      setLoadingSaveToMarket(true)
      const values = form.getFieldsValue()
      const { name, description } = values
      if (!name) {
        setLoadingSaveToMarket(false)
        return message.error('Please enter a name.')
      }
      // Trim message_id to be the same length of messages:
      const nodes = updated_builder_data.nodes.map((node: any) => {
        const { selected, ...restNode } = node
        if (node.data && node.data.messages) {
          // Remove empty strings from messages
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')
          // const newMessageIds = node.data.message_id.map((id: string) => 'uuid')
          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
              // message_id: newMessageIds,
              // sender: [],
            },
          }
        }
        return restNode
      })
      const edges = updated_builder_data.edges
      dispatch(SET_BUILDER_DATA({ nodes: nodes, edges: edges }))
      dispatch(SET_BUILDER_NODES(updated_builder_nodes))
      dispatch(SET_BUILDER_EDGES(updated_builder_edges))

      const nodesToSave = updated_builder_nodes.map((node: any) => {
        const { selected, ...restNode } = node

        if (node.data && node.data.messages) {
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')
          const newMessageIds = node.data.message_id.map((m: string) => 'uuid1')
          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
              message_id: newMessageIds,
              sender: [],
            },
          }
        } else {
          if (node.type !== 'initialNode' && node.type !== 'placeholderNode') {
            const newMessageIds = node.data.message_id.map((m: string) => 'uuid1')
            return {
              ...restNode,
              data: {
                ...node.data,
                message_id: newMessageIds,
                sender: [],
              },
            }
          } else {
            return restNode
          }
        }
      })
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodesToSave,
        updated_builder_edges,
        true,
      )

      const template = JSON.stringify({ nodes: layoutedNodes, edges: layoutedEdges })
      const id = uuidv4()
      dispatch(SET_CUSTOM_FLOW_ID(id))
      const { data } = await saveTemplateToMarket({
        id,
        name,
        description,
        template,
        platforms,
        tags: JSON.stringify(templateTags),
      })
      if (data) {
        dispatch(SET_USE_CUSTOM_FLOW(true))
        dispatch(INCREMENT_COLLAPSE_KEY(''))

        navigateWithCampaignId('/outreach/create-new-campaign', { state: { prevPath: true } })
      }
      setLoadingSaveToMarket(false)
    } catch (e) {
      setLoadingSaveToMarket(false)
      console.log(e)
    }
  }

  const handleCancel = () => {
    setOpenModal(false)
  }

  const handleOk = () => {
    setOpenModal(true)
  }

  const handleConfirmLeave = () => {
    console.log('Discard Changes')
    dispatch(RESET_BUILDER_SETUP_DATA())
    dispatch(SET_BUILDER_INCOMPLETE_NODES([]))
    if (nodes_data?.length === 0) {
      dispatch(SET_PROMPT(0))
    }
    dispatch(SET_SELECTED_BUILDER_NODE({}))
    dispatch(SET_UPDATED_BUILDER_DATA(builder_data))
    dispatch(SET_BUILDER_DATA(builder_data))
    dispatch(SET_UPDATED_BUILDER_NODES(builder_nodes))
    dispatch(SET_UPDATED_BUILDER_EDGES(builder_edges))
    navigateWithCampaignId(navPage, { useCampaignId: navPage !== '/outreach', state: { prevPath: true } })
  }

  const handleExit = () => {
    dispatch(SET_TRIGGER(0))
    dispatch(RESET_BUILDER_SETUP_DATA())
    dispatch(SET_SELECTED_BUILDER_NODE({}))

    if (location.state?.path) {
      navigateWithCampaignId(location.state.path, {
        useCampaignId: true,
        state: { prevPath: true },
      })
    } else if (is_edit_campaign && campaign_status !== 'draft') {
      // TO DO: Should navigate to summary or create-new-campaign
      navigateWithCampaignId(`/outreach/create-new-campaign/summary`, {
        useCampaignId: true,
        state: { prevPath: true },
      })
    } else {
      dispatch(INCREMENT_COLLAPSE_KEY(''))
      navigateWithCampaignId('/outreach/create-new-campaign', { state: { prevPath: true } })
    }
  }
  const handleTagSelect = (value: string) => {
    setTemplateTags((prev) => [...prev, value] as string[])
  }
  const handleTagDeselect = (value: string) => {
    setTemplateTags((prev) => prev.filter((tag) => tag !== value))
  }
  const handleSaveChanges = async (updateFromNodesData?: boolean) => {
    // Update Campaign
    setLoadingSave(true)

    const newNodes = updateFromNodesData
      ? nodes_data
      : // : nodes_data?.map((node: any) => {
        //   const updated = updated_builder_data?.nodes.filter((up: any) => up.id === node.step_id)
        //   if (node.data && updated?.length > 0) {
        //     const nd = updated[0]
        //     const data = nd.data
        //     const { amount, unit, sender, messages, responders, configuration } = data
        //     const newData = { ...node.data }

        //     // Only update properties if they exist in both node.data and updated data
        //     if ('amount' in node.data && amount !== undefined) newData.amount = amount
        //     if ('unit' in node.data && unit !== undefined) newData.unit = unit
        //     if ('sender' in node.data && sender !== undefined) newData.sender = sender
        //     if ('responders' in node.data && responders !== undefined) newData.responders = responders
        //     if ('configuration' in node.data && configuration !== undefined) newData.configuration = configuration
        //     if ('messages' in node.data && messages !== undefined) {
        //       newData.messages = messages.filter((message: string) => message.trim() !== '')
        //     }
        //     return { ...node, data: newData }
        //   }
        //   return node
        updated_builder_data?.nodes?.map((node: any) => {
          const step_id = node.id
          const previous_step_id = getParentNodeId(step_id, updated_builder_data?.edges)
          const type = node.data.type
          const message_id = node.data.message_id
          const data = getShouldContainMessages(node.data.type, truePlatforms)
            ? {
                messages: node?.data?.messages?.filter((message: string) => message?.trim() !== ''),
                sender: node?.data?.sender,
              }
            : node.data.type === 'ai_responder' && Array.isArray(node.data?.responders)
            ? { responders: node.data?.responders, configuration: node.data?.configuration }
            : null

          return {
            step_id,
            previous_step_id,
            type,
            message_id,
            ...(data && { data: data }),
          }
        })

    const newSteps = transformToNewStructure(newNodes, platforms[0])

    if (use_custom_flow) {
      const id = uuidv4()
      dispatch(SET_CUSTOM_FLOW_ID(id))

      await updateCampaign({
        campaign_id,
        campaign_name: ai_campaign_name,
        ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
        steps: JSON.stringify(newNodes),
        custom_flow_id: id,
      })

      const nodesToSave = updated_builder_nodes.map((node: any) => {
        const { selected, ...restNode } = node

        if (node.data && node.data.messages) {
          const newMessages = node.data.messages.filter((message: string) => message.trim() !== '')
          const newMessageIds = node.data.message_id.map((m: string) => uuidv4())
          return {
            ...restNode,
            data: {
              ...node.data,
              messages: newMessages,
              message_id: newMessageIds,
              sender: [],
            },
          }
        } else {
          if (node.type !== 'initialNode' && node.type !== 'placeholderNode') {
            const newMessageIds = node.data.message_id.map((m: string) => uuidv4())
            return {
              ...restNode,
              data: {
                ...node.data,
                message_id: newMessageIds,
                sender: [],
              },
            }
          } else {
            return restNode
          }
        }
      })
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodesToSave,
        updated_builder_edges,
        true,
      )

      const template = JSON.stringify({ nodes: layoutedNodes, edges: layoutedEdges })

      await saveCustomTemplate({
        id,
        name: `Default ${Date.now()}`,
        description: 'Default',
        template,
        platforms,
        is_visible: false,
      })
    } else {
      await updateCampaign({
        campaign_id,
        campaign_name: ai_campaign_name,
        ...(newSteps !== undefined && newSteps.length > 0 && { messages_steps: JSON.stringify(newSteps) }),
        steps: JSON.stringify(newNodes),
      })
    }

    dispatch(SET_TRIGGER(0))
    dispatch(RESET_BUILDER_SETUP_DATA())
    dispatch(SET_SELECTED_BUILDER_NODE({}))

    if (is_edit_campaign && campaign_status !== 'draft') {
      navigateWithCampaignId(`/outreach/create-new-campaign/summary`, {
        useCampaignId: true,
      })
    } else {
      dispatch(INCREMENT_COLLAPSE_KEY(''))

      navigateWithCampaignId('/outreach/create-new-campaign', { state: { prevPath: true } })
    }
    setLoadingSave(false)
  }

  useEffect(() => {
    if (updated_builder_data.nodes) {
      const incompleteSendMessageNodes = updated_builder_data?.nodes
        ?.filter((node: any) => getShouldContainMessages(node.data.type, platforms))
        ?.filter(
          (node: any) =>
            !Array.isArray(node.data.messages) ||
            node.data.messages.filter((msg: string) => msg.trim() !== '').length < 1,
        )
        .map((node: any) => node.id)

      const incompleteAIResponders = updated_builder_data?.nodes
        ?.filter((node: any) => node.data.type === 'ai_responder')
        ?.filter((node: any) => !Array.isArray(node.data.responders) || node.data.responders.length < 1)
        .map((node: any) => node.id)

      dispatch(SET_BUILDER_INCOMPLETE_NODES([...incompleteSendMessageNodes, ...incompleteAIResponders]))

      const hasValidSendMessageNode = incompleteSendMessageNodes.length === 0
      const hasValidAIResponderNode = incompleteAIResponders.length === 0
      const isNodesNotEmpty = updated_builder_data?.nodes?.length > 0
      setIsSaveButtonDisabled(!isNodesNotEmpty || !hasValidSendMessageNode || !hasValidAIResponderNode)
    }
  }, [updated_builder_data.nodes])

  useEffect(() => {
    if (!isCustom && nodes_data) {
      const incompleteSendMessageNodes = nodes_data
        ?.filter((node: any) => getShouldContainMessages(node.type, platforms))
        ?.filter(
          (node: any) =>
            !Array.isArray(node.data.messages) ||
            node.data.messages.filter((msg: string) => msg.trim() !== '').length < 1,
        )
        .map((node: any) => node.id)

      const hasValidSendMessageNode = incompleteSendMessageNodes.length === 0
      setIsSaveButtonDisabled(!hasValidSendMessageNode)
    }
  }, [nodes_data, isCustom])

  useEffect(() => {
    const userDetails = async () => {
      await getUserDetails()
    }
    userDetails()
  }, [])

  const handleBackClick = () => {
    if (isCustom) {
      dispatch(INCREMENT_COLLAPSE_KEY(''))
      setNavPage('/outreach/create-new-campaign')
      setOpenAreYouSureModal(true)
    } else {
      dispatch(INCREMENT_COLLAPSE_KEY(''))
      navigateWithCampaignId('/outreach/create-new-campaign', { state: { prevPath: true } })
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <Button type='text' size='large' onClick={handleBackClick}>
          <img src={left_arrow} alt='' />
        </Button>
        <div>
          <Breadcrumb
            className='breadcrumb'
            separator='>'
            items={[
              {
                title: 'Campaigns',
                onClick: () => {
                  if (isCustom) {
                    setNavPage('/outreach')
                    setOpenAreYouSureModal(true)
                  }
                },
              },
              {
                title: 'Create a New Campaign',
                onClick: handleBackClick,
              },
              {
                title: 'Campaign Builder',
              },
            ]}
          />
          <p style={{ marginTop: '3px' }}>{isCustom ? 'Custom ' : ''}Campaign Flow</p>
        </div>
      </div>
      {isCustom ? (
        is_edit_campaign && campaign_status !== 'draft' ? (
          <div className={styles.rightSideEdit}>
            <Button onClick={handleExit} disabled={isSaveButtonDisabled}>
              Exit
            </Button>
            <Button
              loading={loadingSave}
              type='primary'
              onClick={() => handleSaveChanges()}
              disabled={isSaveButtonDisabled}>
              Save
            </Button>
          </div>
        ) : (
          <div className={styles.rightSide}>
            <span
              className={styles.discard}
              onClick={() => {
                setOpenAreYouSureModal(true)
                dispatch(SET_BUILDER_INCOMPLETE_NODES([]))
                setNavPage(
                  `/outreach/create-new-campaign${
                    is_edit_campaign && campaign_status !== 'draft' ? `/summary?campaign_id=${campaign_id}` : ''
                  }`,
                )
              }}>
              Discard
            </span>
            <Tooltip
              color='white'
              title={
                isSaveButtonDisabled ? (
                  <span style={{ color: 'black' }}>Please fill all messages to continue.</span>
                ) : (
                  ''
                )
              }>
              <Button type='primary' onClick={handleOk} disabled={isSaveButtonDisabled}>
                Save as Template
              </Button>
            </Tooltip>
            <Tooltip
              color='white'
              title={
                isSaveButtonDisabled ? (
                  <span style={{ color: 'black' }}> Please fill all messages to continue.</span>
                ) : (
                  ''
                )
              }>
              <Button
                loading={loading}
                type='primary'
                onClick={() => {
                  handleSaveAndUse(true)
                }}
                disabled={isSaveButtonDisabled}>
                {use_custom_flow ? 'Update' : 'Complete'}
              </Button>
            </Tooltip>
          </div>
        )
      ) : (
        <div className={styles.rightSideEdit}>
          {is_edit_campaign && campaign_status !== 'draft' ? (
            <>
              <Button onClick={handleExit} disabled={isSaveButtonDisabled}>
                Exit
              </Button>
              <Button
                loading={loadingSave}
                type='primary'
                onClick={() => handleSaveChanges(true)}
                disabled={isSaveButtonDisabled}>
                Save
              </Button>
            </>
          ) : (
            <>
              <span style={{ fontWeight: 600, color: '#7043ff' }}>Auto-Save is On</span>
              <Button type='primary' onClick={handleExit} disabled={isSaveButtonDisabled}>
                Complete
              </Button>
            </>
          )}
        </div>
      )}

      <Modal centered title='Save Template' open={openModal} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <Form form={form} className={styles.form}>
          <span>Template's Name</span>
          <Form.Item name='name' rules={[{ required: true, message: 'Please input the template name!' }]}>
            <Input />
          </Form.Item>

          <span>Template's Description</span>
          <Form.Item name='description'>
            <Input.TextArea rows={4} />
          </Form.Item>
          {save_to_market === true && (
            <>
              <span>Template's Tags</span>
              <Form.Item name='tags'>
                <Select
                  mode='tags'
                  style={{ width: '100%' }}
                  onSelect={handleTagSelect}
                  onDeselect={handleTagDeselect}
                />
              </Form.Item>
            </>
          )}
          <span>Platforms</span>
          <Form.Item
            initialValue={platforms}
            name='platforms'
            rules={[{ required: true, message: 'Please select a platform!' }]}>
            <Select disabled value={platforms} defaultValue={platforms} mode='multiple' placeholder='Select platforms'>
              {platformOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <div className={styles.footer}>
            <Button onClick={handleCancel} disabled={loading || loadingSaveToMarket}>
              Cancel
            </Button>
            <Button onClick={() => handleSaveAndUse()} type='primary' loading={loading} disabled={isSaveButtonDisabled}>
              Save & Use
            </Button>
            {save_to_market === true && (
              <Button
                onClick={handleSaveToMarket}
                type='primary'
                loading={loadingSaveToMarket}
                disabled={isSaveButtonDisabled}>
                Save to Market
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      <AreYouSureModal
        open={openAreYouSureModal}
        title='Leave Without Saving?'
        message='You have unsaved changes that will be lost if you leave this page now. Are you sure you want to proceed without saving?'
        onClose={() => {
          setOpenAreYouSureModal(false)
        }}
        onConfirm={handleConfirmLeave}
      />
    </div>
  )
}

export default BuilderHeader
