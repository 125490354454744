import { HTMLAttributes, useEffect } from 'react'
import styles from './MobileLayout.module.scss'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_IS_MOBILE } from 'GeneralSlice'
import convrtLogo from 'common/assets/svg/convrt_logo_login.svg'
import { Card } from 'antd'
import empty from 'common/assets/svg/empty_state_messages.svg'
const MobileProvider = (props: HTMLAttributes<HTMLDivElement>) => {
  const { isMobile, justSignedUp } = useAppSelector((state) => state.General)
  const dispatch = useAppDispatch()
  const path = window.location.pathname.split('/')[1]
  const allowedForMobile = ['register', 'signup', 'login', 'upgrade']
  useEffect(() => {
    // Helper function to update the mobile state
    const handleResize = () => {
      dispatch(SET_IS_MOBILE(window.innerWidth < 768))
    }

    // Initial check on mount
    handleResize()

    // Add the resize event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      {isMobile && !allowedForMobile.includes(path) ? (
        <div className={styles.mobileLayout}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.75rem',
              marginTop: '1.25rem',
              marginBottom: '1.25rem',
              position: 'absolute',
              top: '0px',
            }}
            className='flex gap-3 align-items-center my-5 absolute top-0'>
            <img src={convrtLogo} alt='logo' />
          </div>
          <Card
            className={styles.card}
            bodyStyle={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              flexDirection: 'column',
              gap: '0.5rem',
            }}>
            <span>{justSignedUp ? 'Thanks for signing up!' : 'Mobile is coming soon!'}</span>

            <p style={{ marginTop: '20px', width: '300px', gap: '50px', fontWeight: '600', textAlign: 'center' }}>
              Convrt is currently only available on desktop. <br />
              Grab your computer, we can't wait to see you there!
            </p>
            <img src={empty} alt='Empty State' style={{ width: '200px', height: '200px' }} />
          </Card>
        </div>
      ) : (
        <>
          {/* RENDER CHILDREN */}
          {props.children}
        </>
      )}
    </>
  )
}

export default MobileProvider
