import React, { useEffect, useState } from 'react'
import { Card, Radio } from 'antd'
import styles from './SelectableCards.module.scss'
import { CheckOutlined } from '@ant-design/icons'
import build_manually from 'common/assets/svg/build_manually.svg'
import build_with_ai from 'common/assets/svg/build_with_ai.svg'
import { useAppSelector } from 'state'
import { useDispatch } from 'react-redux'
import { SET_SETUP_METHOD } from '../../state/outreachAICampaignSlice'

const options = [
  {
    value: 'manually',
    title: 'Build Manually',
    description: 'I want to select the leads myself',
    image: build_manually,
  },
  { value: 'ai', title: 'Build with AI', description: 'I want AI to find leads for me', image: build_with_ai },
]
interface SelectableCardsProps {
  onSetupWithAI: () => void
  onSetupManually: () => void
}

const SelectableCards: React.FC<SelectableCardsProps> = ({ onSetupWithAI, onSetupManually }) => {
  const { setup_method, current_collapse_key } = useAppSelector((state) => state.outreachAICampaign)
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState<string>(setup_method)

  const handleChange = (e: any) => {
    const selectedValue = e
    setSelectedOption(selectedValue)

    if (selectedValue === 'ai') {
      onSetupWithAI()
    } else if (selectedValue === 'manually') {
      onSetupManually()
    }
  }

  useEffect(() => {
    if (setup_method === '' && current_collapse_key === '3') {
      setSelectedOption('manually')
      onSetupManually()
      dispatch(SET_SETUP_METHOD('manually'))
    }
  }, [setup_method, current_collapse_key])

  return (
    <Radio.Group onChange={handleChange} value={selectedOption} style={{ width: '100%' }}>
      <div className={styles.cards}>
        {options.map((option) => (
          <Card
            onClick={() => handleChange(option.value)}
            className={`selectableCards ${selectedOption === option.value ? 'selectableCardsSelected' : ''}`}
            style={{
              cursor: 'pointer',
              minWidth: '300px',
              borderColor: selectedOption === option.value ? '#1890ff' : '#f0f0f0',
            }}
            hoverable>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flexGrow: 1 }}>
                <img src={option.image} alt='' />
                <h2 style={{ margin: 0, fontWeight: 'bold' }}>{option.title}</h2>
                <div>{option.description}</div>
              </div>
              <div className={styles.radio}>
                {selectedOption === option.value ? (
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      borderRadius: '50%',
                      backgroundColor: '#c447ff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <CheckOutlined style={{ color: 'white', fontSize: '12px' }} />
                  </div>
                ) : (
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      borderRadius: '50%',
                      backgroundColor: 'white',
                      border: '1px solid #97A0AF',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></div>
                )}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Radio.Group>
  )
}

export default SelectableCards
