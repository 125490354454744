import { SET_INTERCOM_OPEN } from 'common/components/OutreachAI/HomePage/LearningCenter/state/slice/learningSlice'
import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import { useAppDispatch } from 'state'

export default function CustomIntercomProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch()
  const onIntercomShow = () => {
    dispatch(SET_INTERCOM_OPEN(true))
  }
  const onIntercomHide = () => {
    dispatch(SET_INTERCOM_OPEN(false))
  }
  return (
    <IntercomProvider appId='g5gi0lu5' onShow={onIntercomShow} onHide={onIntercomHide}>
      {children}
    </IntercomProvider>
  )
}
