import { logEvent } from 'eventLogger'

export const EVENTS = {
  ///////////////////////////
  //  OUSTIDE ONBOARDING  //
  /////////////////////////
  SIGNUP_EMAIL_VERIFIED: {
    name: 'Outside Signup: Second step (Email Verified)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the email verification',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Email verification',
      key_data_points: [],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_1: {
    name: 'Outside Signup: Get to know 1 (Company)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (1)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 1',
      key_data_points: ['Company name', 'Company domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event means that the user completed the step and not started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_2: {
    name: 'Outside Signup: Get to know 2 (Where)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the “where did you find us” step (2)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 2',
      key_data_points: ['Where did you find us'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_3: {
    name: 'Outside Signup: Get to know 3 (Company Domain)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (3)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 3',
      key_data_points: ['Company Domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_GET_TO_KNOW_4: {
    name: 'Outside Signup: Get to know 4 (Goal)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the goal step (4)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 4',
      key_data_points: ['Goal'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: 'old',
    },
  },

  SIGNUP_COMPLETION: {
    name: 'Outside Signup: User finished signup process',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track completion of the onboarding process.',
      trigger: 'User clicked continue on the final step.',
      related_screen: 'Onboarding - Final Step',
      key_data_points: [],
      expected_outcome: 'User navigates to the /upgrade page.',
      notes: 'Indicates that the user has finished onboarding and will proceed to the upgrade page.',
      date_started: 'old',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_1: {
    name: 'Arrive: Outside Signup: Get to know 1 (Company)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (1)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 1',
      key_data_points: ['Company name', 'Company domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event means that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_2: {
    name: 'Arrive: Outside Signup: Get to know 2 (Where)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the “where did you find us” step (2)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 2',
      key_data_points: ['Where did you find us'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_3: {
    name: 'Arrive: Outside Signup: Get to know 3 (Company Domain)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the company domain step (3)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 3',
      key_data_points: ['Company Domain'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ARRIVE_SIGNUP_GET_TO_KNOW_4: {
    name: 'Arrive: Outside Signup: Get to know 4 (Goal)',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user completed the goal step (4)',
      trigger: 'User clicked on continue',
      related_screen: 'Onboarding - Get to Know You 4',
      key_data_points: ['Goal'],
      expected_outcome: 'Check if user completed this step',
      notes: 'This event indicates that the user completed the step and not just started.',
      date_started: '31.10.2024',
    },
  },

  ///////////////////////////
  //       CAMPAIGNS      //
  /////////////////////////
  CAMPAIGN_REVIEW_LAUNCH: {
    name: 'Review & Launch Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks Review & Launch to start a campaign',
      trigger: 'User clicked on Review & Launch',
      related_screen: 'Campaign - Review & Launch',
      key_data_points: [],
      expected_outcome: 'User starts the campaign review process.',
      notes: 'This event indicates that the user has initiated the campaign process.',
      date_started: '31.10.2024',
    },
  },

  CAMPAIGN_RUN: {
    name: 'Run Campaign',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user clicks Run Campaign to start running a campaign',
      trigger: 'User clicked on Run Campaign',
      related_screen: 'Campaign - Run Campaign',
      key_data_points: [],
      expected_outcome: 'User runs the campaign successfully.',
      notes: 'This event indicates that the user has officially launched the campaign.',
      date_started: '31.10.2024',
    },
  },

  COLLECT_LEADS: {
    name: 'Collect Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user collects leads',
      trigger: 'User clicked on Collect Leads',
      related_screen: 'Leads - Collect Leads',
      key_data_points: [],
      expected_outcome: 'User collects leads successfully.',
      notes: 'This event indicates that the user has initiated the lead collection process.',
      date_started: '31.10.2024',
    },
  },

  IMPORT_CUSTOM_LEADS: {
    name: 'Import Custom Leads',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user imports custom leads',
      trigger: 'User clicked on Import Custom Leads',
      related_screen: 'Leads - Import Custom Leads',
      key_data_points: ['Platform: Facebook, LinkedIn Search, LinkedIn Sales Navigator, Instagram'],
      expected_outcome: 'User imports custom leads successfully.',
      notes: 'This event indicates that the user has chosen to import custom leads from specified platforms.',
      date_started: '31.10.2024',
    },
  },

  ///////////////////////////
  //        SOCIAL        //
  /////////////////////////
  SOCIAL_ACCOUNT_LOGIN_STARTED: {
    name: 'Social Account Login Started',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user starts the social account login process',
      trigger: 'User clicked on Social Account Login',
      related_screen: 'Login - Social Account',
      key_data_points: [],
      expected_outcome: 'User initiates the social account login process.',
      notes: 'This event indicates that the user has begun logging in via a social account.',
      date_started: '31.10.2024',
    },
  },

  SOCIAL_ACCOUNT_2FA: {
    name: 'Social Account 2FA Step',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user is at the 2FA step during social account login',
      trigger: 'User reached 2FA step',
      related_screen: 'Login - Social Account 2FA',
      key_data_points: [],
      expected_outcome: 'User completes the 2FA step successfully.',
      notes: 'This event indicates that the user is in the 2FA process for social account login.',
      date_started: '31.10.2024',
    },
  },

  SOCIAL_ACCOUNT_LOGIN_FAILED: {
    name: 'Social Account Login Failed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user fails to log in to their social account',
      trigger: 'User login failed',
      related_screen: 'Login - Social Account',
      key_data_points: [],
      expected_outcome: 'User fails to log in to their social account.',
      notes: 'This event indicates that the user encountered an error during social account login.',
      date_started: '31.10.2024',
    },
  },

  ACCOUNT_DETAILS_SCREEN_VIEWED: {
    name: 'Account Details Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Account Details screen',
      trigger: 'User navigates to the screen to input account credentials',
      related_screen: 'Login - Account Details',
      key_data_points: [],
      expected_outcome: 'User is presented with the screen to enter login credentials.',
      notes: 'This event indicates that the user is on the screen to input their account details.',
      date_started: '6.11.2024',
    },
  },

  ACCOUNT_DETAILS_SCREEN_EXITED: {
    name: 'Account Details Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Account Details screen',
      trigger: 'User submits account credentials or navigates away',
      related_screen: 'Login - Account Details',
      key_data_points: [],
      expected_outcome: 'User completes the login credential input process.',
      notes: 'This event is triggered when the user leaves the screen after entering credential input.',
      date_started: '6.11.2024',
    },
  },

  LOADING_SCREEN_VIEWED: {
    name: 'Loading Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user sees the Loading screen',
      trigger: 'User submitted account details or waits for verification',
      related_screen: 'Login - Loading',
      key_data_points: [],
      expected_outcome: 'User waits while the system verifies account details.',
      notes: 'This event indicates that the user is in a loading state waiting for further actions.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Authentication Code screen',
      trigger: 'System requests authentication code',
      related_screen: 'Login - Require Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the authentication code received.',
      notes: 'This event is triggered when the user needs to enter an authentication code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Authentication Code screen',
      trigger: 'User submits authentication code',
      related_screen: 'Login - Require Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering the code.',
      notes: 'This event is triggered when the user submits the authentication code process.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_ANOTHER_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require Another Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Another Authentication Code screen',
      trigger: 'System requests a new authentication code',
      related_screen: 'Login - Require Another Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter a new authentication code received.',
      notes: 'This event is triggered when the user is asked to enter another authentication code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_ANOTHER_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require Another Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Another Authentication Code screen',
      trigger: 'User submits new authentication code',
      related_screen: 'Login - Require Another Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering the new code.',
      notes: 'This event is triggered when the user submits a new code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_2FA_AUTH_CODE_SCREEN_VIEWED: {
    name: 'Require 2FA Authentication Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require 2FA Authentication Code screen',
      trigger: 'System requests the 2FA authentication code',
      related_screen: 'Login - Require 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the 2FA code received via their authentication method.',
      notes: 'This event is triggered when the user is asked to provide a two-factor authentication (2FA) code.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_2FA_AUTH_CODE_SCREEN_EXITED: {
    name: 'Require 2FA Authentication Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require 2FA Authentication Code screen',
      trigger: 'User submits the 2FA authentication code or navigates away from the screen',
      related_screen: 'Login - Require 2FA Authentication Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after successfully entering the 2FA code or cancels the process.',
      notes:
        'This event is triggered when the user submits the 2FA code or exits the screen before completing the process.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_REGISTER_PHONE_SCREEN_VIEWED: {
    name: 'Require Register Phone Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Register Phone screen',
      trigger: 'System requests user to enter phone number',
      related_screen: 'Login - Require Register Phone',
      key_data_points: [],
      expected_outcome: 'User is prompted to register their phone number.',
      notes: 'This event is triggered when the user is required to register a phone number.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_REGISTER_PHONE_SCREEN_EXITED: {
    name: 'Require Register Phone Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Register Phone screen',
      trigger: 'User submits phone number',
      related_screen: 'Login - Require Register Phone',
      key_data_points: [],
      expected_outcome: 'User proceeds after entering phone number.',
      notes: 'This event is triggered when the user submits the phone registration.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_PIN_CODE_SCREEN_VIEWED: {
    name: 'Require Pin Code Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Require Pin Code screen',
      trigger: 'System requests the pin code for authentication',
      related_screen: 'Login - Require Pin Code',
      key_data_points: [],
      expected_outcome: 'User is prompted to enter the pin code received or set for authentication.',
      notes: 'This event is triggered when the user is asked to provide a pin code for authentication.',
      date_started: '6.11.2024',
    },
  },

  REQUIRE_PIN_CODE_SCREEN_EXITED: {
    name: 'Require Pin Code Screen Exited',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user leaves the Require Pin Code screen',
      trigger: 'User submits the pin code or navigates away from the screen',
      related_screen: 'Login - Require Pin Code',
      key_data_points: [],
      expected_outcome: 'User proceeds after successfully entering the pin code or cancels the process.',
      notes:
        'This event is triggered when the user submits the pin code or exits the screen before completing the process.',
      date_started: '6.11.2024',
    },
  },

  VERIFY_ON_APP_SCREEN_VIEWED: {
    name: 'Verify on App Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Verify on App screen',
      trigger: 'System requests user to approve connection on app',
      related_screen: 'Login - Verify on App',
      key_data_points: [],
      expected_outcome: 'User is prompted to confirm the connection within the app.',
      notes: 'This event is triggered when the user needs to approve the connection on their app.',
      date_started: '6.11.2024',
    },
  },

  ACTIVE_SCREEN_VIEWED: {
    name: 'Active Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Active screen',
      trigger: 'User successfully connects account',
      related_screen: 'Login - Active',
      key_data_points: [],
      expected_outcome: 'User sees confirmation that their account is connected.',
      notes: 'This event is triggered upon successful account connection.',
      date_started: '6.11.2024',
    },
  },

  ERROR_SCREEN_VIEWED: {
    name: 'Error Screen Viewed',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user views the Error screen',
      trigger: 'An error occurs during the login process',
      related_screen: 'Login - Error',
      key_data_points: [],
      expected_outcome: 'User is informed about the error encountered during login.',
      notes: 'This event is triggered when the system displays an error screen due to login failure.',
      date_started: '6.11.2024',
    },
  },

  ///////////////////////////
  //        GENERAL       //
  /////////////////////////
  POINTS_USED: {
    name: 'Points Used',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user uses points',
      trigger: 'User used points',
      related_screen: 'Rewards - Points Used',
      key_data_points: [],
      expected_outcome: 'User successfully uses points.',
      notes: 'This event indicates that the user has redeemed or used points in the system.',
      date_started: '31.10.2024',
    },
  },

  MAGIC_POINTS_USED: {
    name: 'Magic Points Used',
    event_source: 'frontend',
    convrt_internal_description: {
      event_purpose: 'Track when a user uses magic points',
      trigger: 'User used magic points',
      related_screen: 'Rewards - Magic Points Used',
      key_data_points: [],
      expected_outcome: 'User successfully uses magic points.',
      notes: 'This event indicates that the user has redeemed or used magic points in the system.',
      date_started: '31.10.2024',
    },
  },
}

export const SOCIAL_MAPPER: any = {
  incoming: 'ACCOUNT_DETAILS_SCREEN_VIEWED',
  require_check_signedin_app: 'VERIFY_ON_APP_SCREEN_VIEWED',
  verify_on_app: 'VERIFY_ON_APP_SCREEN_VIEWED',
  active: 'ACTIVE_SCREEN_VIEWED',
  pin_code_required: 'REQUIRE_PIN_CODE_SCREEN_VIEWED',
  require_register_phone: 'REQUIRE_REGISTER_PHONE_SCREEN_VIEWED',
  require_authentication_code: 'REQUIRE_AUTH_CODE_SCREEN_VIEWED',
  require_another_authentication_code: 'REQUIRE_ANOTHER_AUTH_CODE_SCREEN_VIEWED',
  require_2fa_code: 'REQUIRE_2FA_AUTH_CODE_SCREEN_VIEWED',
  error: 'ERROR_SCREEN_VIEWED',
}

// Function to log an event
export const trackEvent = (eventKey: keyof typeof EVENTS, additionalData?: any) => {
  const event = EVENTS[eventKey]
  if (!event) {
    throw new Error(`Event with key "${eventKey}" is not defined.`)
  }

  logEvent(event.name, event.event_source, event.convrt_internal_description, additionalData)
}
