import React, { useRef, useState } from 'react'
import { Select } from 'antd'
import styles from './CountrySelect.module.scss'
import { countriesWithPhonePrefix } from 'common/static-data/countires'

interface CountrySelectProps {
  onChange: (countryCode: string) => void
  defaultValue: any
}

const CountrySelect: React.FC<CountrySelectProps> = ({ onChange, defaultValue }) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<any>(null)
  const handleFocus = () => setOpen(true)

  return (
    <Select
      ref={selectRef}
      showSearch
      open={open}
      placeholder='Select a country'
      optionFilterProp='children'
      defaultValue={defaultValue}
      virtual={false}
      onChange={(value: string) => {
        const selectedCountry = countriesWithPhonePrefix.find((country) => country.countryCode === value)
        if (selectedCountry) {
          onChange(selectedCountry.countryCode)
          setOpen(false)
          selectRef.current?.blur()
        }
      }}
      style={{ width: 250 }}
      onFocus={handleFocus}
      onBlur={(e) => {
        e.currentTarget.blur()
        setOpen(false)
      }}
      dropdownRender={(menu) => <div onMouseDown={(e) => e.preventDefault()}>{menu}</div>}
      filterOption={(input, option: any) => {
        return (
          option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )
      }}>
      {countriesWithPhonePrefix.map((country) => (
        <Select.Option key={country.label} value={country.countryCode}>
          <div className={styles.flag}>
            <img
              style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                marginRight: '6px',
              }}
              alt={''}
              src={`https://convrt-media-store.s3.us-west-2.amazonaws.com/${country?.countryCode?.toLowerCase()}.png`}
            />
            <span>
              {country.label} ({country.countryCode})
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

export default CountrySelect
