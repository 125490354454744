import React from 'react'
import { Divider, Tooltip } from 'antd'

import styles from './Category.module.scss'

interface categorizer {
  index: number
  category_name: string
  is_active: boolean
  category_description: string
  leads: number
  personas: any
}
interface CategoryCardProps {
  data: categorizer
}

const propsTooltip = {
  color: '#fff',
  overlayStyle: {
    maxWidth: '600px',
    backgroundColor: 'white',
  },
  overlayInnerStyle: { backgroundColor: 'white' },
}

const CategorizerCard: React.FC<CategoryCardProps> = ({ data }) => {
  const { category_name, category_description, personas } = data

  return (
    <div className={styles.groupCard}>
      <div className={styles.cardHeader}>
        <div className={styles.groupName}>{category_name}</div>
      </div>
      <div className={styles.leads}>
        {personas?.length > 0 &&
          personas.map((persona: any, index: number) => {
            return (
              <>
                <div key={index} className={styles.platform}>
                  <span>{persona.name}</span>
                </div>
                <Divider type='vertical' />
              </>
            )
          })}
      </div>
      <div className={styles.cardBody}>
        <div className={styles.groupDescription}>
          <Tooltip {...propsTooltip} color='#222' title={category_description}>
            {category_description}
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default CategorizerCard
