import React, { useCallback, useEffect, useState } from 'react'
import { Table, Switch, Dropdown, MenuProps, Spin, Tooltip, Statistic, Popover, Skeleton, message, Button } from 'antd'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import facebook_icon from 'common/assets/svg/logo_facebook.svg'
import telegram_icon from 'common/assets/svg/logo_telegram.svg'
import discord_icon from 'common/assets/svg/discord_icon.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import twitter_icon_gray from 'common/assets/svg/twitter_logo_gray.svg'
import warning_red_icon from 'common/assets/svg/warning_red_icon.svg'
import linkedin_icon from 'common/assets/svg/logo_linkedin.svg'
import three_dots from 'common/assets/svg/three_dots.svg'
import styles from './CampaignsTable.module.scss'
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import ProgressBar from './ProgressBar'
import CustomStatus from './CustomStatus'
import { useNavigate } from 'react-router-dom'
import { useCampaigns } from './Helpers/useCampaigns'
import ArchiveCampaignModal from 'common/components/OutreachCampaign/MainCampaign/Campaigns/UI/ArchiveCampaignModal'
import DisableCampaignModal from 'common/components/OutreachCampaign/MainCampaign/Campaigns/UI/DisableCampaignModal'
import { useAppSelector } from 'state'
import { debounce } from 'lodash'
import CustomPopover from './CustomPopover'
import CampaignActions from './CampaignActions'
import { post } from 'common/api/axios'

const { Countdown } = Statistic

interface Campaign {
  key: string
  is_active: boolean
  campaign_id: any
  campaign_name: string
  created_at: string
  channels?: string
  accounts: number
  cooldown: number
  recovery: number
  status: string
  leads: number
  sent: number
  replied: number
  blocked: number
  progress: number
  is_archived: boolean
  acceptance_rate?: string
  follow?: string
  is_completed?: boolean
}

interface CampaignsTableProps {
  campaigns: Campaign[] | any[]
  isFetching?: boolean
  setTriggerGetCampaigns: any
}

const CampaignsTable: React.FC<CampaignsTableProps> = ({ campaigns, isFetching, setTriggerGetCampaigns }) => {
  const [pageSize, setPageSize] = useState(5)
  const [tableSize, setTableSize] = useState<'small' | 'large'>('large')
  const [loadingNavigate, setLoadingNavigate] = useState(false)
  const [loadingAddLeads, setLoadingAddLeads] = useState(false)
  const [campaignName, setCampaignName] = useState('')
  const [campaignId, setCampaignId] = useState('')
  const [openDisableCampaignModal, setOpenDisableCampaignModal] = useState(false)
  const [openArchiveCampaignModal, setOpenArchiveCampaignModal] = useState(false)
  const [loadingSwitches, setLoadingSwitches] = useState<{ [key: string]: boolean }>({})
  const [campaignStates, setCampaignStates] = useState<{ [key: string]: boolean }>({})
  const [campaignStatuses, setCampaignStatuses] = useState<{ [key: string]: string }>({})
  const [hoveredRowKey, setHoveredRowKey] = useState<string | null>(null)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [loadingConfirm, setLoadingConfirm] = useState(false)
  const {
    handleArchiveCampaignModal,
    handleArchiveCampaign,
    // handleUnarchiveCampaign,
    handleCampaignActiveStatus,
    handleConfirmation,
    handleBulkArchiveCampaign,
    handleBulkCampaignActiveStatus,
    handleBulkConfirmation,
    getNextActiveTime,
    handleBulkUnarchiveCampaign,
    getCampaignSummaryView,
  } = useCampaigns(campaigns)
  const navigate = useNavigate()
  const { showPercentage, campaign_filters, campaign_date_filters } = useAppSelector((state) => state.outreachCampaign)
  const { outOfOutreach } = useAppSelector((state) => state.General)

  const handleEditCampaign = async (values: any) => {
    const path = `/outreach/create-new-campaign/summary?campaign_id=${values.campaign_id}`
    if (values.status === 'draft') {
      setLoadingNavigate(true)
      await getCampaignSummaryView(values.campaign_id, values.status)
      setLoadingNavigate(false)
      navigate(`/outreach/create-new-campaign?campaign_id=${values.campaign_id}`, { state: { ...values } })
    } else {
      navigate(path, { state: { ...values } })
    }
  }

  const handleAddLeads = async (values: any) => {
    setLoadingAddLeads(true)
    await getCampaignSummaryView(values.campaign_id, values.status, true)
    navigate(`/outreach/create-new-campaign?campaign_id=${values.campaign_id}`, { state: { ...values, outside: true } })
    setLoadingAddLeads(false)
  }

  const handleSwitchChange = async (checked: boolean, data: any) => {
    if (data?.status === 'draft' || +data?.prospects === 0) {
      if (data?.status === 'draft') {
        message.warning('This campaign is not fully configured. Please finish setting up your campaign.')
      } else if (+data?.prospects === 0) {
        message.warning('This campaign contains 0 leads. Please add leads and launch your campaign.')
      }
      return handleEditCampaign(data)
    }
    setLoadingSwitches((prev) => ({ ...prev, [data.campaign_id]: true }))
    await handleCampaignActiveStatus(
      checked,
      data.campaign_name,
      data.campaign_id,
      setCampaignName,
      setCampaignId,
      setOpenDisableCampaignModal,
      setLoadingSwitches,
      setCampaignStatuses,
    )
    if (checked) {
      setCampaignStates((prev) => ({ ...prev, [data.campaign_id]: checked }))
      setCampaignStatuses((prev) => ({ ...prev, [data.campaign_id]: 'running' }))
      post('/log', {
        action: `Set Active Campaign`,
        // data: { bookmark: value },
        data: {
          campaign_name: data.campaign_name,
          campaign_id: data.campaign_id,
          date: data.created_at,
        },
      })
    }
    setLoadingSwitches((prev) => ({ ...prev, [data.campaign_id]: false }))
  }

  const handleMouseEnter = useCallback(
    debounce((key: string) => {
      setHoveredRowKey(key)
    }, 0),
    [],
  )

  const handleMouseLeave = () => {
    setHoveredRowKey(null)
  }

  const platformNames: { [key: string]: string } = {
    discord: 'Discord',
    twitter: 'Twitter',
    facebook: 'Facebook',
    linkedin: 'LinkedIn',
    telegram: 'Telegram',
    instagram: 'Instagram',
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <div>
          <span>Edit Campaign</span>
          {loadingNavigate && (
            <Spin
              style={{ marginLeft: '6px' }}
              indicator={<LoadingOutlined style={{ fontSize: 16, color: 'black' }} spin />}
            />
          )}
        </div>
      ),
      key: '0',
    },
    {
      label: <span>Archive Campaign</span>,
      key: '1',
    },
  ]

  const itemsArchive: MenuProps['items'] = [
    {
      label: <span>Unarchive Campaign</span>,
      key: '2',
    },
  ]
  const columns = [
    {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 75,
      filters: [
        {
          text: 'Active',
          value: true,
        },
      ],
      onFilter: (value: any, record: any) => record.is_active === value,
      sorter: {
        compare: (a: Campaign, b: Campaign) => (a.status || '').localeCompare(b.status || ''),
        multiple: 2,
      },
      defaultSortOrder: 'descend' as 'descend',
      render: (_: any, campaign: any) => {
        const nextActiveTime = getNextActiveTime(campaign)
        const deadline = nextActiveTime?.toDate()?.toISOString()
        const showWarning = outOfOutreach || nextActiveTime
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={campaignStates[campaign.campaign_id]}
              onChange={(checked) => handleSwitchChange(checked, campaign)}
              disabled={campaign.is_archived}
              className='campaignsSwitch'
              loading={loadingSwitches[campaign.campaign_id]}
            />
            {showWarning ? (
              <Popover
                overlayStyle={{ zIndex: 2000, maxWidth: '250px' }}
                content={
                  <div className={styles.warningPopoverContent}>
                    {nextActiveTime && (
                      <div className={styles.outOfSchdule}>
                        <p>Out of Schedule</p>
                        <span>This campaign is currently out of schedule.</span>
                        <span className={styles.countdown}>
                          It will resume in:
                          <Countdown
                            valueStyle={{ fontSize: '14px', fontWeight: 600 }}
                            value={deadline}
                            format='D[d] HH[h] mm[m] ss[s]'
                          />
                        </span>
                        <span>You can edit the campaign to adjust the timing.</span>
                      </div>
                    )}
                    {outOfOutreach && (
                      <div className={styles.outOfSchdule}>
                        <p>Out of Outreach Credits</p>
                        <span>
                          Your campaigns have stopped sending to new contacts because you've reached the send rate limit
                          for this billing cycle. Upgrade now to resume sending.
                        </span>
                        <div className={styles.footer}>
                          <Button
                            type='primary'
                            style={{ width: '100px', margin: '0px' }}
                            onClick={() => {
                              navigate('/upgrade')
                            }}>
                            Upgrade
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                }>
                <img src={warning_red_icon} alt='' style={{ color: 'red', marginLeft: '8px', cursor: 'pointer' }} />
              </Popover>
            ) : null}
            {/* {nextActiveTime ? (
              <Tooltip
                overlayInnerStyle={{ backgroundColor: 'white', minWidth: '283px' }}
                title={
                  <div className={styles.outOfSchdule}>
                    <span>This campaign is currently out of schedule.</span>
                    <span className={styles.countdown}>
                      It will resume in:
                      <Countdown
                        valueStyle={{ fontSize: '14px', fontWeight: 600 }}
                        value={deadline}
                        format='D[d] HH[h] mm[m] ss[s]'
                      />
                    </span>
                    <span>You can edit the campaign to adjust the timing.</span>
                  </div>
                }>
                <img src={warning_red_icon} alt='' style={{ color: 'red', marginLeft: '8px' }} />
              </Tooltip>
            ) : null} */}
          </div>
        )
      },
    },
    {
      title: 'Campaign Name',
      dataIndex: 'campaign_name',
      key: 'campaign_name',
      width: tableSize === 'small' ? 250 : 325,
      sorter: (a: Campaign, b: Campaign) => a.campaign_name.localeCompare(b.campaign_name),
      render: (campaign_name: string, data: any) => {
        const archived = <span style={{ color: 'lightgray' }}>Archived</span>
        // const truncatedName = campaign_name?.length > 17 && tableSize === 'small'
        return (
          <>
            <Tooltip title={campaign_name}>
              <div className={styles.truncatedText} style={{ maxWidth: tableSize === 'small' ? '250px' : '325px' }}>
                {campaign_name}
              </div>
            </Tooltip>
            {data.is_archived && archived}
          </>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 116,
      // sorter: (a: Campaign, b: Campaign) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      sorter: {
        compare: (a: Campaign, b: Campaign) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        multiple: 1,
      },
      defaultSortOrder: 'descend' as 'descend',
      showSorterTooltip: { target: 'full-header' } as {},
      render: (date: any) => {
        const dateString = new Date(date)?.toLocaleDateString()
        return <span>{dateString}</span>
      },
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      key: 'channels',
      width: 120,
      render: (channels: any) => {
        const getIconStyle = (platform: string) => ({
          filter: channels?.[platform] ? 'none' : 'grayscale(100%)',
          opacity: channels?.[platform] ? '1' : '0.2',
        })
        return (
          <div className={styles.dummyChannels}>
            <img src={facebook_icon} alt='facebook' style={getIconStyle('facebook')} />
            <img src={instagram_icon} alt='instagram' style={getIconStyle('instagram')} />
            <img src={telegram_icon} alt='telegram' style={getIconStyle('telegram')} />
            <img src={linkedin_icon} alt='linkedin' style={getIconStyle('linkedin')} />
            <img src={discord_icon} alt='discord' style={getIconStyle('discord')} />
            <img src={channels?.['twitter'] ? twitter_icon : twitter_icon_gray} alt='twitter' />
          </div>
        )
      },
    },

    // {
    //   title: 'Recovery',
    //   dataIndex: 'recovery',
    //   key: 'recovery',
    //   width: 100,
    //   sorter: (a: Campaign, b: Campaign) => a.recovery - b.recovery,
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as 'center',
      width: 100,
      sorter: (a: Campaign, b: Campaign) => (a.status || '').localeCompare(b.status || ''),
      render: (status: string, data: any) => (
        <CustomStatus status={campaignStatuses[data.campaign_id] ? campaignStatuses[data.campaign_id] : status} />
      ),
    },
    {
      title: 'Leads',
      dataIndex: 'prospects',
      key: 'prospects',
      width: 100,
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.prospects - b.prospects,
    },
    {
      title: 'Sent',
      dataIndex: 'total_sent',
      key: 'total_sent',
      width: 100,
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.total_sent - b.total_sent,
      render: (total_sent: any, data: any) => {
        if (showPercentage) {
          return <span>{data.prospects > 0 ? Math.ceil((total_sent / data.prospects) * 100) : 0}%</span>
        } else {
          return <span>{total_sent}</span>
        }
      },
    },
    {
      title: 'Replied',
      dataIndex: 'total_replied',
      key: 'total_replied',
      width: 100,
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.total_replied - b.total_replied,
      render: (total_replied: any, data: any) => {
        if (showPercentage) {
          return <span>{Math.ceil(data?.percentage_responsive_leads)}%</span>
        } else {
          return <span>{total_replied}</span>
        }
      },
    },
    // {
    //   title: 'Blocked',
    //   dataIndex: 'total_blocked_or_deleted',
    //   key: 'total_blocked_or_deleted',
    //   width: 100,
    //   align: 'center' as 'center',
    //   sorter: (a: any, b: any) => a.total_blocked_or_deleted - b.total_blocked_or_deleted,
    //   render: (total_blocked_or_deleted: any, data: any) => {
    //     if (showPercentage) {
    //       return <span>{Math.ceil(data?.percentage_block_or_deleted)}%</span>
    //     } else {
    //       return <span>{total_blocked_or_deleted}</span>
    //     }
    //   },
    // },
    {
      title: 'Accounts',
      dataIndex: 'accounts',
      key: 'accounts',
      align: 'center' as 'center',
      width: 100,
      sorter: (a: any, b: any) => a?.senders?.length - b?.senders?.length,
      render: (_: any, data: any) => {
        return <span>{data?.senders?.length || data?.numberOfAccounts}</span>
      },
    },
    {
      title: 'Cooldown',
      dataIndex: 'cooldown',
      key: 'cooldown',
      align: 'center' as 'center',
      width: 100,
      sorter: (a: any, b: any) => a.cooldown.length - b.cooldown.length,
      render: (cooldown: any, data: any) => {
        // Extract the platforms with true values and map to their capitalized names
        const activePlatforms = data?.channels
          ? Object.keys(data?.channels)
              ?.filter((platform) => data.channels[platform])
              ?.map((platform) => platformNames[platform])
          : []

        // Construct the message based on active platforms
        let platformMessage = 'by the specified platforms.'
        if (activePlatforms.length > 0) {
          platformMessage = `by ${activePlatforms.join(', ')}`
        } else {
          platformMessage = 'by any platform'
        }

        const content = (
          <>
            <span>
              This occurs when our model identifies <br />a risk of your account being blocked
              <br /> or deleted by {platformMessage}.
              <br />
              Once the cooldown time expires,
              <br /> you will be able to resend automatically.
            </span>
            <div className={styles.cooldownTable}>
              <table>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th style={{ textAlign: 'center' }}>Time remaining</th>
                  </tr>
                </thead>
                <tbody>
                  {cooldown.map((item: any, index: number) => {
                    const addTime = item.send_type === 'web' ? 8 : 24
                    const deadline = new Date(item.created_at)?.getTime() + 1000 * 60 * 60 * addTime

                    return (
                      <tr key={index}>
                        <td>{item.user_telegram_id || item.outreach_sender_id}</td>
                        <Countdown
                          value={deadline}
                          valueStyle={{ fontSize: '14px', padding: '0px', textAlign: 'center', fontWeight: 600 }}
                        />
                        {/* <td>{item.timeRemaining}</td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )

        return (
          <div className={styles.cooldowns}>
            <span>{cooldown.length}</span>
            <Popover content={content} title={data?.campaign_name} trigger='click' placement='right'>
              <InfoCircleOutlined style={{ cursor: 'pointer', color: '#5E6C84', marginLeft: '3px', padding: 0 }} />
            </Popover>
          </div>
        )
      },
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      width: isFetching && tableSize === 'large' ? 230 : 200,
      render: (progress: number, data: any) => {
        const popoverContent = (
          <div>
            <CustomPopover
              paused={Number(data?.paused_leads)}
              continuing={Number(data?.continuing_leads)}
              completed={Number(data?.completed_leads)}
              positive={Number(data?.positive_replies)}
              neutral={Number(data?.neutral_replies)}
              negative={Number(data?.negative_replies)}
            />
          </div>
        )
        return (
          <Popover
            content={popoverContent}
            placement='right'
            open={data.campaign_id === hoveredRowKey}
            overlayStyle={{ width: '400px' }}
            // open={hoveredRowKey === data.campaign_id}
            // overlayStyle={{ position: 'absolute', left: popoverPosition.left, top: popoverPosition.top }}
          >
            <span onMouseEnter={() => handleMouseEnter(data.campaign_id)} onMouseLeave={handleMouseLeave}>
              <ProgressBar
                percentage={progress}
                status={campaignStatuses?.[data?.campaign_id] || data?.status}
                size={tableSize}
              />
            </span>
          </Popover>

          // <ProgressBar
          //   percentage={progress}
          //   status={campaignStatuses?.[data?.campaign_id] || data?.status}
          //   size={tableSize}
          // />
        )
      },
      // render: (progress: number) => <Progress style={{ minWidth: '194px', maxWidth: '194px' }} percent={progress} />,
      sorter: (a: Campaign, b: Campaign) => a.progress - b.progress,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      render: (actions: number, item: any) => {
        return (
          <Dropdown
            menu={{
              items: item.is_archived
                ? itemsArchive
                : [
                    ...items,
                    {
                      label: (
                        <Tooltip
                          title={
                            !item.canAddLeads
                              ? `To add leads, you first need to create a sequence. You can do this by clicking "Edit Campaign".`
                              : ''
                          }>
                          <div>
                            <span>Add Leads</span>
                            {loadingAddLeads && (
                              <Spin
                                style={{ marginLeft: '6px' }}
                                indicator={<LoadingOutlined style={{ fontSize: 16, color: 'black' }} spin />}
                              />
                            )}
                          </div>
                        </Tooltip>
                      ),
                      key: '2',
                      disabled: !item.canAddLeads,
                    },
                  ],
              onClick: (e) =>
                e.key === '0'
                  ? handleEditCampaign(item)
                  : e.key === '1'
                  ? handleArchiveCampaignModal(item, setCampaignName, setCampaignId, setOpenArchiveCampaignModal)
                  : e.key === '2'
                  ? handleAddLeads(item)
                  : e.key === '3',
              // handleUnarchiveCampaign(item, setTriggerGetCampaigns)
              // : null,
            }}
            placement='bottomLeft'
            arrow={false}
            trigger={['click']}
            className={styles.dropDown}>
            <div className={styles.moreOptions}>
              <img src={three_dots} alt='' />
            </div>
          </Dropdown>
        )
      },
    },
  ]

  // Bulk ops
  const handleBulkArchive = async () => {
    setLoadingConfirm(true)
    const data = await handleBulkArchiveCampaign(selectedRows, setTriggerGetCampaigns)
    if (data) {
      setOpenArchiveCampaignModal(false)
      setSelectedRows([])
    }
    setLoadingConfirm(false)
  }

  const handleBulkUnarchive = async () => {
    await handleBulkUnarchiveCampaign(selectedRows, setTriggerGetCampaigns)
    setSelectedRows([])
  }

  const handleBulkActivate = async () => {
    await handleBulkCampaignActiveStatus(selectedRows, true, 'running', setLoadingSwitches, setTriggerGetCampaigns)
    // setSelectedRows([])
  }

  const handleBulkConfirm = async () => {
    setLoadingConfirm(true)
    await handleBulkConfirmation(selectedRows, setLoadingSwitches, setCampaignStates, setCampaignStatuses)
    setOpenDisableCampaignModal(false)
    setLoadingConfirm(false)
    // setSelectedRows([])
  }

  const handleDismissSelection = () => {
    setSelectedRows([])
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Campaign[]) => {
      setSelectedRows(selectedRows.map((row) => row.campaign_id))
    },
  }

  useEffect(() => {
    const initialStates: any = {}
    campaigns?.forEach((item) => {
      initialStates[item.campaign_id] = item.is_active
    })
    setCampaignStates(initialStates)
  }, [campaigns])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1700) {
        setPageSize(5)
        setTableSize('small')
      } else {
        setPageSize(selectedRows.length > 0 ? 7 : 8)
        setTableSize('large')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [window.innerHeight, window.innerWidth, selectedRows])

  useEffect(() => {
    setSelectedRows([])
  }, [campaign_filters, campaign_date_filters])

  return (
    <div className={styles.campaignsTable}>
      {selectedRows.length > 0 && (
        <CampaignActions
          selectedRows={selectedRows}
          campaigns={campaigns}
          setOpenArchiveCampaignModal={setOpenArchiveCampaignModal}
          setCampaignName={setCampaignName}
          handleBulkActivate={handleBulkActivate}
          handleBulkUnarchive={handleBulkUnarchive}
          setOpenDisableCampaignModal={setOpenDisableCampaignModal}
          handleDismissSelection={handleDismissSelection}
        />
      )}
      <Table
        className='campaignsPageTable'
        rowKey={(record) => record.campaign_id}
        dataSource={
          isFetching
            ? [...Array(pageSize)].map((_, index) => ({
                key: `key${index}`,
              }))
            : campaigns
        }
        columns={
          isFetching
            ? columns.map((column, index) => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <Skeleton
                        key={index}
                        className='campaignsPageTable'
                        // key={column.dataIndex}
                        title={true}
                        paragraph={false}
                        active
                      />
                    )
                  },
                }
              })
            : columns
        }
        rowSelection={{ type: 'checkbox', ...rowSelection, selectedRowKeys: selectedRows }}
        scroll={{ x: 600 }}
        pagination={{ pageSize: pageSize }}
      />

      {openArchiveCampaignModal && (
        <ArchiveCampaignModal
          open={openArchiveCampaignModal}
          onClose={() => {
            setOpenArchiveCampaignModal(false)
          }}
          loading={loadingConfirm}
          campaignName={campaignName}
          onConfirm={() =>
            selectedRows?.length > 0
              ? handleBulkArchive()
              : handleArchiveCampaign(campaignId, campaignName, setOpenArchiveCampaignModal, setTriggerGetCampaigns)
          }
        />
      )}
      {openDisableCampaignModal && (
        <DisableCampaignModal
          open={openDisableCampaignModal}
          onClose={() => {
            setOpenDisableCampaignModal(false)
          }}
          loading={loadingConfirm}
          campaignName={campaignName}
          onConfirm={() =>
            selectedRows?.length > 0
              ? handleBulkConfirm()
              : handleConfirmation(
                  campaignId,
                  setOpenDisableCampaignModal,
                  setCampaignStates,
                  setLoadingSwitches,
                  setCampaignStatuses,
                )
          }
        />
      )}
    </div>
  )
}

export default CampaignsTable
