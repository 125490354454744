export const STATUSES: any = [
  'require_authentication_code',
  'require_register_phone',
  'require_another_authentication_code',
  'verify_on_app',
  'pin_code_required',
  'require_check_signedin_app',
  'authenticate_another_case',
  'active',
  'require_2fa_code',
]

export const ERRORS: any = {
  instagram: {
    '2fa_code_expired': {
      title: 'Verification code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    authentication_code_expired: {
      title: 'Authentication code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    no_available_proxy: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    unknown: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    general_error: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    login_failed: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    solve_recaptcha_required: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    associated_with_another_client: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    could_not_load: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    incorrect_password: {
      title: 'Incorrect Login Details',
      reason: 'The credentials provided for the Instagram account are incorrect.',
      what_can_i_do: 'Please ensure you remember your username and password and verify them carefully.',
      action_button_text: 'try_again',
    },
    already_signed_in: {
      title: 'Account Already Signed-in',
      reason: 'Your account is already signed in to Convrt.',
      what_can_i_do: 'Please try again with a different account.',
      action_button_text: 'try_again',
    },
  },
  facebook: {
    '2fa_code_expired': {
      title: 'Verification code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    authentication_code_expired: {
      title: 'Authentication code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    no_available_proxy: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    solve_recaptcha_required: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    unknown: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    general_error: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    account_recover: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    account_suspended: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    login_failed: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'like today on this error',
      action_button_text: 'try_again',
    },
    associated_with_another_client: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    incorrect_password: {
      title: 'Incorrect Login Details',
      reason: 'The credentials provided for the Facebook account are incorrect.',
      what_can_i_do: 'Please ensure you remember your username and password and verify them carefully.',
      action_button_text: 'try_again',
    },
    verification_failed: {
      title: 'Login Unsuccessful',
      reason: 'Verification failed.',
      what_can_i_do: 'Please ensure your verification method is valid and hasn’t expired.',
      action_button_text: 'try_again',
    },
    already_signed_in: {
      title: 'Account Already Signed-in',
      reason: 'Your account is already signed in to Convrt.',
      what_can_i_do: 'Please try again with a different account.',
      action_button_text: 'try_again',
    },
  },
  linkedin: {
    '2fa_code_expired': {
      title: 'Verification code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    authentication_code_expired: {
      title: 'Authentication code expired',
      reason: 'The code was expired and no longer valid.',
      what_can_i_do:
        'Please reconnect and ensure you have your verification device ready to receive the code promptly.',
      information_icon: null,
      action_button_text: 'try_again',
    },
    no_available_proxy: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    unknown: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    general_error: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    login_failed: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    solve_recaptcha_required: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    associated_with_another_client: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Request support from the Convrt team.',
      information_icon: null,
      action_button_text: 'request_support',
    },
    could_not_load: {
      title: 'Login was unsuccessful',
      reason: 'General Error.',
      what_can_i_do: 'Please ensure your account language is set to English. How can I change the language?',
      information_icon: 'language_change',
      action_button_text: 'try_again',
    },
    incorrect_password: {
      title: 'Incorrect Login Details',
      reason: 'The credentials provided for the Instagram account are incorrect.',
      what_can_i_do: 'Please ensure you remember your username and password and verify them carefully.',
      action_button_text: 'try_again',
    },
    already_signed_in: {
      title: 'Account Already Signed-in',
      reason: 'Your account is already signed in to Convrt.',
      what_can_i_do: 'Please try again with a different account.',
      action_button_text: 'try_again',
    },
  },
}
