import { Typography } from 'antd'
import styles from './SocialBox.module.scss'

import telegram_icon from 'common/assets/svg/telegram_logo.svg'
import linkedin_icon from 'common/assets/svg/linkedin_logo.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_logo.svg'

interface SocialBoxProps {
  name?: string
  imageSocialKey: 'telegram' | 'linkedin' | 'instagram' | 'facebook'
  isCommingSoon?: boolean
  isAddAccount?: boolean
  accountCount?: number
  onAddAccount?: () => void
  onClickBox?: () => void
}

const SocialBox = (props: SocialBoxProps) => {
  const { name, imageSocialKey, isCommingSoon, accountCount, isAddAccount, onAddAccount, onClickBox } = props

  const imageMapping: Record<SocialBoxProps['imageSocialKey'], string> = {
    telegram: telegram_icon,
    linkedin: linkedin_icon,
    instagram: instagram_icon,
    facebook: facebook_icon,
  }

  const handleClickButton = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    onAddAccount && name !== 'telegram' && onAddAccount()
  }

  const handleClickBox = () => {
    onClickBox && onClickBox()
  }

  return (
    <div className={styles.socialBox} onClick={handleClickBox} style={isAddAccount ? {} : { cursor: 'pointer' }}>
      <img className={styles.socialBox__logo} src={imageMapping[imageSocialKey]} alt='' />

      <Typography className={styles.socialBox__name}>{name}</Typography>

      <div
        className={styles.socialBox__button}
        onClick={handleClickButton}
        style={name === 'telegram' ? { cursor: 'not-allowed' } : {}}>
        {isCommingSoon && <Typography className={styles.socialBox__button__commingSoon}>{'Coming Soon'}</Typography>}
        {isAddAccount && (
          <Typography
            className={styles.socialBox__button__addAccount}
            style={name === 'telegram' ? { color: 'gray', cursor: 'not-allowed' } : {}}>
            {'Add Account'}
          </Typography>
        )}

        {!isCommingSoon && !isAddAccount && (
          <>
            <div className={styles.socialBox__button__dot} />
            <Typography className={styles.socialBox__button__text}>
              {accountCount} Account{accountCount === 1 ? '' : 's'}
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}

export default SocialBox
