import React, { CSSProperties } from 'react'
import { Tooltip } from 'antd'

interface TruncatedTooltipProps {
  text: string
  maxLength?: number
  fontSize?: string
  tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  className?: string // optional class name for the span
  style?: CSSProperties // optional inline styles for the span
}

const TruncatedTooltip: React.FC<TruncatedTooltipProps> = ({
  text,
  maxLength = 18, // default max length to truncate
  fontSize = '12px', // default font size
  tooltipPlacement = 'topLeft', // default placement
  className, // optional class
  style, // optional inline styles
}) => {
  if (!text) return <></>

  const truncatedText = text.length > maxLength ? `${text.substring(0, maxLength)?.trim()}...` : text

  return (
    <Tooltip placement={tooltipPlacement} title={text}>
      <span className={className} style={{ fontSize, ...style }}>
        {truncatedText}
      </span>
    </Tooltip>
  )
}

export default TruncatedTooltip
