import React, { useState, useRef, useEffect } from 'react'
import { Layout } from 'antd'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import BottomMenu from 'common/components/bottom-menu/bottom-menu'
import Logo from 'common/components/logo/logo'
import TopMenu from 'common/components/top-menu/top-menu'
import UserLogged from 'common/components/user-logged/user-logged'
import styles from './sidebar.module.scss'

const { Sider } = Layout

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [selectedOption, setSelectedOption] = useState('')
  const location = useLocation()
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const mousePosition = useRef({ x: 0, y: 0 })
  let openTimer: NodeJS.Timeout | null = null

  const updateMousePosition = (e: MouseEvent) => {
    mousePosition.current = { x: e.clientX, y: e.clientY }
  }

  const handleMouseEnter = () => {
    if (openTimer) clearTimeout(openTimer)
    openTimer = setTimeout(() => {
      if (isMouseOverSidebar()) {
        setCollapsed(false)
      }
    }, 200)
  }

  const handleMouseLeave = () => {
    if (openTimer) clearTimeout(openTimer)
    openTimer = setTimeout(() => setCollapsed(true), 200)
  }

  const isMouseOverSidebar = () => {
    if (sidebarRef.current) {
      const rect = sidebarRef.current.getBoundingClientRect()
      const { x, y } = mousePosition.current
      return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom
    }
    return false
  }

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition)

    return () => {
      if (openTimer) clearTimeout(openTimer)
      window.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])

  useEffect(() => {
    if (openTimer) clearTimeout(openTimer)
    setCollapsed(true)
  }, [location.pathname])

  return (
    <div className='cabmCustomSidebar' style={{ zIndex: '20000' }}>
      <Sider
        ref={sidebarRef}
        className={`mainSidebar ${classNames(
          styles.cabmSidebar,
          collapsed ? styles.sidebarCollapsed : styles.sidebarExpanded,
        )}`}
        collapsed={collapsed}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className={styles.topSection}>
          <Logo collapsed={collapsed} />
          <TopMenu
            collapsed={collapsed}
            callbackOnChange={(value) => value && setSelectedOption(value)}
            selectedKey={selectedOption}
          />
        </div>
        <div className={styles.bottomSection}>
          <hr />
          <BottomMenu
            collapsed={collapsed}
            callbackOnChange={(value) => value && setSelectedOption(value)}
            selectedKey={selectedOption}
          />
          <div className='userLoggedParent'>
            <UserLogged collapsed={collapsed} />
          </div>
        </div>
      </Sider>
    </div>
  )
}

export default Sidebar
