import { Button, Card, Checkbox, Divider, Form, Input, message, Table, Typography } from 'antd'
import { useEffect, useState } from 'react'
import styles from './GenerateRegistrationCode.module.scss'
import { useLazyGetAffiliatesTableQuery, useLazyGetRegistrationURLQuery } from '../state/api/SettingsApi'
const { Title } = Typography
const GenerateRegistrationCode: React.FC = () => {
  const [url, setUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [loadingTable, setLoadingTable] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [skipOutside, setSkipOutside] = useState<boolean>(false)
  const [generateUrl] = useLazyGetRegistrationURLQuery()
  const [getTable] = useLazyGetAffiliatesTableQuery()
  const [tableData, setTableData] = useState<any>([])
  const refinedData = tableData.map((data: any) => {
    return {
      id: data.id,
      email: data.email,
      link_name: data?.affiliate_data?.name,
      is_skip_outside_onboarding: data?.affiliate_data?.is_skip_outside_onboarding,
      subscription_type: data?.subscription_type,
    }
  })
  const handleGenerateToken = async () => {
    if (!name) {
      message.error('Please enter a name')
      return
    }
    try {
      setLoading(true)
      const response = await generateUrl({ name, is_skip_outside_onboarding: skipOutside })
      setUrl(response.data.url)
      message.success('Url generated successfully')
    } catch (error) {
      message.error('Error generating url')
      console.log('Error generating url', error)
    } finally {
      setLoading(false)
    }
    // try {
    //   setLoading(true)
    //   const response = await generateToken({ userId })
    //   // console.log(response)
    //   setToken(response.data.token)
    //   message.success('Token generated successfully')
    // } catch (error) {
    //   message.error('Error generating token')
    // } finally {
    //   setLoading(false)
    // }
  }
  const init = async () => {
    try {
      const res = await getTable()
      console.log(res)
      setTableData(res.data?.affiliates)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    setLoadingTable(true)
    init().then(() => setLoadingTable(false))
    // create init interval
    const interval = setInterval(() => {
      init()
    }, 10000)
    // clear interval
    return () => clearInterval(interval)
  }, [])
  return (
    <Card className={styles.profileCard}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ paddingInline: '10px' }}>
          <Title level={3}>Generate Registration URL</Title>
          <Form layout='vertical' className={styles.form}>
            <Form.Item label='Name'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter Name'
                style={{ width: '20%' }}
              />
            </Form.Item>
            <Checkbox checked={skipOutside} onChange={(e) => setSkipOutside(e.target.checked)}>
              Skip Outside Onboarding
            </Checkbox>
            {url && (
              <Form.Item label='Generated URL' style={{ padding: 15 }}>
                <Input.TextArea value={url} readOnly autoSize={{ minRows: 2, maxRows: 6 }} />
              </Form.Item>
            )}
            <Form.Item style={{ paddingTop: '20px' }}>
              <Button type='primary' onClick={handleGenerateToken} loading={loading}>
                Generate Registartion URL
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Divider />
        <div style={{ paddingInline: '10px' }}>
          <Title level={3}>Signed Up</Title>
          <Table
            style={{ height: '500px' }}
            dataSource={refinedData}
            loading={loadingTable}
            rowKey={(record) => record.id}
            columns={[
              {
                title: 'Link Name',
                dataIndex: 'link_name',
                key: 'link_name',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Skip Outside Onboarding',
                dataIndex: 'is_skip_outside_onboarding',
                key: 'is_skip_outside_onboarding',
                render: (is_skip_outside_onboarding: boolean) => (
                  <div style={{ display: 'flex' }}>{is_skip_outside_onboarding ? '✅' : '❌'}</div>
                ),
              },
              {
                title: 'Finished',
                dataIndex: 'subscription_type',
                key: 'subscription_type',
                render: (subscription_type: string) => (subscription_type !== 'REGISTERED' ? '✅' : '❌'),
              },
            ]}></Table>
        </div>
      </div>
    </Card>
  )
}

export default GenerateRegistrationCode
