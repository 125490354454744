import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
// import styles from './GroupsSidebar.module.scss'
import type { MenuProps } from 'antd'
import telegram_icon from 'common/assets/svg/tele_icon.svg'
import facebook_icon from 'common/assets/svg/facebook_icon.svg'
import instagram_icon from 'common/assets/svg/instagram_icon.svg'
import linkedin_icon from 'common/assets/svg/linked_icon.svg'
import discord_icon from 'common/assets/svg/discord_logo.svg'
import twitter_icon from 'common/assets/svg/twitter_logo.svg'
import { SET_MY_GROUPS_FILTERS } from 'common/components/OutreachAI/state/outreachAICampaignSlice'
import { useAppDispatch, useAppSelector } from 'state'

const getLogoByPlatform = (platform: any) => {
  const platformLowerCase = platform.toLocaleLowerCase()
  switch (platformLowerCase) {
    case 'instagram':
      return instagram_icon
    case 'facebook':
      return facebook_icon
    case 'linkedin':
      return linkedin_icon
    case 'twitter':
      return twitter_icon
    case 'discord':
      return discord_icon
    case 'telegram':
      return telegram_icon
    default:
      return ''
  }
}

const { Sider } = Layout

const siderStyle: React.CSSProperties = {
  borderRight: '1px solid #dfe1e6',
  // borderLeft: '1px solid #dfe1e6',
  background: 'white',
}

interface GroupsSidebarProps {
  categories: string[]
  onCategoryChange: (category: string) => void
  onChannelChange: (channel: string) => void
  myGroups?: boolean
}

const GroupsSidebar: React.FC<GroupsSidebarProps> = ({
  categories = [],
  onCategoryChange,
  onChannelChange,
  myGroups,
}) => {
  const { my_groups_filters } = useAppSelector((state) => state.outreachAICampaign)
  // const [selectedKey, setSelectedKey] = useState<string | null>(null)
  const [selectedKey, setSelectedKey] = useState<{ category?: string; channel?: string } | null>(null)
  const [viewAll, setViewAll] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const displayedCategories = myGroups
    ? ['All Groups', 'On Tracking', 'On Importing Progress']
    : viewAll
    ? ['Explore All', ...categories, 'View Less']
    : ['Explore All', ...categories?.slice(0, 3), 'View All']

  const platforms = ['Facebook', 'Instagram', 'LinkedIn', 'Telegram', 'Discord', 'Twitter']

  const handleViewToggle = () => {
    setViewAll(!viewAll)
  }

  const handleExploreAll = () => {
    setSelectedKey(null)
    onChannelChange('')
    onCategoryChange('')
  }

  const handlePlatformSelection = (key: string) => {
    if (selectedKey?.channel === key) {
      setSelectedKey({ ...selectedKey, channel: undefined })
      onChannelChange('')
    } else {
      setSelectedKey({ ...selectedKey, channel: key })
      onChannelChange(key)
    }
  }

  const handleCategorySelection = (key: string) => {
    if (selectedKey?.category === key) {
      setSelectedKey({ ...selectedKey, category: undefined })
      onCategoryChange('')
    } else {
      setSelectedKey({ ...selectedKey, category: key })
      onCategoryChange(key)
    }
  }

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'View All':
      case 'View Less':
        handleViewToggle()
        break
      case 'Explore All':
      case 'All Groups':
        dispatch(SET_MY_GROUPS_FILTERS(''))
        handleExploreAll()
        break
      case 'On Tracking':
        if (my_groups_filters === 'trackMode') {
          setSelectedKey(null)
          dispatch(SET_MY_GROUPS_FILTERS(''))
        } else {
          setSelectedKey({ category: e.key, channel: undefined })
          dispatch(SET_MY_GROUPS_FILTERS('trackMode'))
        }
        break
      case 'On Importing Progress':
        if (my_groups_filters === 'in_progress') {
          setSelectedKey(null)
          dispatch(SET_MY_GROUPS_FILTERS(''))
        } else {
          setSelectedKey({ category: e.key, channel: undefined })
          dispatch(SET_MY_GROUPS_FILTERS('in_progress'))
        }
        break
      default:
        platforms.includes(e.key) ? handlePlatformSelection(e.key) : handleCategorySelection(e.key)
        break
    }
  }

  const items: MenuProps['items'] = ['Categories', 'Channels'].map((text, index) => {
    const key = String(index + 1)
    if (text === 'Categories') {
      return {
        key: `sub${key}`,
        label: <span style={{ fontWeight: 600 }}>{text}</span>,
        children: displayedCategories.map((cat, j) => {
          return {
            key: cat,
            label: <span style={cat === 'View All' || cat === 'View Less' ? { color: '#7043ff' } : {}}>{cat}</span>,
          }
        }),
      }
    } else {
      return {
        key: `sub${key}`,
        label: <span style={{ fontWeight: 600 }}>{text === 'Twitter' ? '(X) Twitter' : text}</span>,

        children: platforms.map((channel, j) => {
          return {
            key: channel,
            label: channel === 'Twitter' ? 'X (Twitter)' : channel,
            icon: (
              <img
                style={{
                  height: '25px',
                  width: '25px',
                  opacity: ['Twitter', 'Discord', 'Telegram'].includes(channel) ? '0.3' : '1',
                }}
                src={getLogoByPlatform(channel)}
                alt=''
              />
            ),
            disabled: ['Twitter', 'Discord', 'Telegram'].includes(channel),
          }
        }),
      }
    }
  })

  return (
    <Sider style={siderStyle} width={220}>
      <Menu
        defaultOpenKeys={['sub1', 'sub2']}
        className='groupsSidebar'
        mode='inline'
        style={{ height: '100%', borderRight: 0, maxHeight: '680px', overflowY: 'auto' }}
        items={items}
        onClick={onClick}
        selectedKeys={[selectedKey?.channel || '', selectedKey?.category || '']}
      />
    </Sider>
  )
}

export default GroupsSidebar
