import styles from './Where.module.scss'
import twitter from 'common/assets/svg/twitter_logo.svg'
import linkedin from 'common/assets/svg/linkedin_logo.svg'
import google from 'common/assets/svg/google_icon.svg'
import youtube from 'common/assets/png/Youtube.png'
import facebook from 'common/assets/svg/facebook_logo.svg'
import tiktok from 'common/assets/svg/TikTok.svg'
import instagram from 'common/assets/svg/instagram_icon.svg'
import reddit from 'common/assets/svg/reddit_icon.svg'
import { useAppSelector } from 'state'
interface WhereProps {
  where: string
  setWhere: (value: string) => void
}

export default function Where({ where, setWhere }: WhereProps) {
  const { settings } = useAppSelector((state) => state.signup)
  const wheres: any = settings?.where_did_you_find_us || []
  const { isMobile } = useAppSelector((state) => state.General)
  const valueToImg: any = {
    twitter: twitter,
    linkedin: linkedin,
    google: google,
    youtube: youtube,
    facebook: facebook,
    tiktok: tiktok,
    instagram: instagram,
    reddit: reddit,
  }

  const clickedStyle = {
    borderRadius: ' 10px',
    background: ' #faf9ff',
    border: '1px solid #7144FF',
  }
  return (
    <div className={styles.frame}>
      <div className={styles.frame_title}>
        <span className={styles.text}>Where did you find us?</span>
      </div>
      {isMobile ? (
        <>
          <div className={styles.places}>
            <div
              className={styles.friend}
              style={{ cursor: 'pointer', ...(where === 'friend' ? clickedStyle : {}) }}
              onClick={() => setWhere('friend')}>
              <span className={styles.text}>A friend</span>
            </div>
            {wheres.map((place: any) => (
              <div
                className={styles.social}
                style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
                onClick={() => setWhere(place.value)}>
                <div className={styles.text_frame}>
                  <span className={styles.text}>{place.label}</span>
                </div>
                <div className={styles.logo}>
                  <img src={valueToImg[place.value]} alt='Social' />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className={styles.places}>
          <div
            className={styles.friend}
            style={{ cursor: 'pointer', ...(where === 'friend' ? clickedStyle : {}) }}
            onClick={() => setWhere('friend')}>
            <span className={styles.text}>A friend</span>
          </div>
          {wheres.map((place: any) => (
            <div
              className={styles.social}
              style={{ cursor: 'pointer', ...(where === place.value ? clickedStyle : {}) }}
              onClick={() => setWhere(place.value)}>
              <div className={styles.text_frame}>
                <span className={styles.text}>{place.label}</span>
              </div>
              <div className={styles.logo}>
                <img src={valueToImg[place.value]} alt='Social' />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
