import { Button, Divider, Form, Input, message } from 'antd'
import styles from './InitialForm.module.scss'
import { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useLocalStorage } from 'usehooks-ts'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { AuthService } from 'common/services/authServices'
import { useLazySignUpAffiliateQuery } from 'features/sign-up/AffiliateSignup/state/api/SignUpApi'
import { useAppDispatch, useAppSelector } from 'state'
import axios from 'axios'
import { SET_LOGIN_VALUES } from '../../state/slice/signupSlice'
// import { post } from 'common/api/axios'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'

export default function InitialForm() {
  const [user, setUser] = useState<any>([])
  const googleSSOSignUp = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.error('Google SSO Login Failed:', error),
  })
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [, setStep] = useLocalStorage('step', 0)
  const [signUpAffiliate] = useLazySignUpAffiliateQuery()
  const [, setIsAffiliateStill] = useLocalStorage('isAffiliateStill', false)
  const dispatch = useAppDispatch()
  // const { affiliateData } = useAppSelector((state) => state.signup)
  const { isMobile } = useAppSelector((state) => state.General)
  const [size, setSize] = useState<'large' | 'middle' | 'small'>('small')

  const [, setAccessUser] = useLocalStorage(LocalStorageKeys.ACCESS_TOKEN, null)
  const [, setHubspot] = useLocalStorage(LocalStorageKeys.HUBSPOT_ACCESS_TOKEN, null)
  const [, setLocalUser] = useLocalStorage('user', null)

  const handleLoginNavigate = () => {
    navigate('/login')
  }
  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }
  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  const formik = useFormik({
    initialValues: {
      first: '',
      last: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      const affiliateToken = localStorage.getItem('affiliateToken') || ''
      const body = {
        name: values.first + ' ' + values.last,
        company: '',
        email: values.email,
        password: values.password,
        affiliateToken: affiliateToken,
      }
      setLoading(true)

      const result = await signUpAffiliate(body)
      // setIsSuccessfully(result.code === 201 ? true : false)
      if (!result.isError && result.data) {
        setIsAffiliateStill(true)
        dispatch(SET_LOGIN_VALUES(values))
        setStep(1)
        return
      }
      if (result?.error?.status === 422) {
        // setErrorSignup(result.response.data.message)
        setLoading(false)
        message.error(result?.error?.data?.message)
      } else {
        // setErrorSignup(result.message)
        setLoading(false)
        message.error(result?.error?.data?.message)
      }
      setLoading(false)
    },
  })
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          })
          .then(async (res) => {
            let data: any = {}

            const affiliateToken = localStorage.getItem('affiliateToken') || ''

            data.email = res.data.email
            data.name = res.data.name
            data.affiliateToken = affiliateToken

            const result = await AuthService.affiliateSSOSignUp(data)
            const { response } = result

            if (
              response?.data?.message === 'The email already exists' ||
              response?.data?.message === 'The email already exists'
            ) {
              navigate('/login')
              return
            }
            dispatch(SET_LOGIN_VALUES({ email: res.data.email, name: res.data.name }))
            setIsAffiliateStill(true)

            const resultLogin = await AuthService.doSSOLogin({ email: res.data.email })
            // if (resultLogin?.url) {
            //   return window.open(resultLogin.url, '_self')
            // }
            if (resultLogin.accessToken) {
              setAccessUser(resultLogin.accessToken)
              setHubspot(resultLogin.hubspotToken)
              setLocalUser(resultLogin.user)
            }

            // post('/log', {
            //   action: `Outside Signup: First step`,
            //   data: {
            //     email: data.email,
            //     name: data.name,
            //     sso: true,
            //     affiliateData: affiliateData,
            //   },
            // })
            setStep(2)
            navigate('/register?signedup-success=true', { replace: true })
          })
          .catch((err) => console.error(err))
      }
    }

    fetchData().catch(console.error)
  }, [user])

  // const googleSSOLogin = useGoogleLogin({
  //   onSuccess: (codeResponse) => setUser(codeResponse),
  //   onError: (error) => console.error('Google SSO Login Failed:', error),
  // })

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (user) {
  //       axios
  //         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
  //           headers: {
  //             Authorization: `Bearer ${user.access_token}`,
  //             Accept: 'application/json',
  //           },
  //         })
  //         .then(async (res) => {
  //           const resultLogin = await AuthService.doSSOLogin({ email: res.data.email })
  //           if (resultLogin?.url) {
  //             return window.open(resultLogin.url, '_self')
  //           }
  //           if (resultLogin.accessToken) {
  //             handleLogin(resultLogin)
  //           }
  //         })
  //         .catch((err) => console.error(err))
  //     }
  //   }
  //   fetchData().catch(console.error)
  // }, [user])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 860) {
        setSize('small')
      } else {
        setSize('large')
      }
    }

    // Run on initial load
    handleResize()

    // Listen for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures this effect runs only on mount and unmount

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.contentMobile}>
            <div className={styles.cardWrapperMobile}>
              <div className={styles.cardMobile}>
                <div className={styles.titleFrame}>
                  <div className={styles.title}>Sign Up</div>
                  <div className={styles.subTitle}>
                    <div className={styles.subTitleText}>
                      Create your account or sign up with Google to start using Convrt.
                    </div>
                  </div>
                </div>
                <div className={styles.google}>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='gsi-material-button'
                      onClick={() => {
                        googleSSOSignUp()
                      }}>
                      <div className='gsi-material-button-state'></div>
                      <div className='gsi-material-button-content-wrapper'>
                        <div className='gsi-material-button-icon'>
                          <svg
                            version='1.1'
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 48 48'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            style={{ display: 'block' }}>
                            <path
                              fill='#EA4335'
                              d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                            <path
                              fill='#4285F4'
                              d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                            <path
                              fill='#FBBC05'
                              d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                            <path
                              fill='#34A853'
                              d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                            <path fill='none' d='M0 0h48v48H0z'></path>
                          </svg>
                        </div>
                        <span className='gsi-material-button-contents'>Sign up with Google</span>
                        <span style={{ display: 'none' }}>Sign up with Google</span>
                      </div>
                    </button>
                  </div>
                </div>
                <Divider plain style={{ marginTop: 5, borderColor: '#DFE1E6' }}>
                  Or
                </Divider>

                <Form onFinish={formik.handleSubmit} className={styles.form}>
                  <div className={styles.form}>
                    <div className={styles.first_last}>
                      <div className={styles.first}>
                        <span className={styles.first_text}>First name</span>
                        <Form.Item
                          style={{ padding: 0, margin: 0, width: '100%' }}
                          name='first'
                          rules={[{ required: true, message: 'First Name is required', whitespace: true }]}>
                          <Input
                            height={40}
                            width={'100%'}
                            style={{ width: '100%' }}
                            placeholder='Enter first name'
                            {...formik.getFieldProps('first')}
                          />
                        </Form.Item>
                      </div>
                      <div className={styles.last}>
                        <span className={styles.last_text}>Last name</span>
                        <Form.Item
                          style={{ padding: 0, margin: 0, width: '100%' }}
                          name='last'
                          rules={[{ required: true, message: 'Last Name is required', whitespace: true }]}>
                          <Input height={40} placeholder='Enter last name' {...formik.getFieldProps('last')} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={styles.email}>
                      <div className={styles.emailTitle}>
                        <div className={styles.emailText}>Email</div>
                      </div>
                      <Form.Item
                        name='email'
                        rules={[
                          {
                            type: 'email',
                            message: 'Invalid email address',
                          },
                          {
                            required: true,
                            message: 'Email is required',
                          },
                        ]}
                        style={{ padding: 0, margin: 0, width: '100%' }}>
                        <Input height={40} placeholder='Enter email' {...formik.getFieldProps('email')}></Input>
                      </Form.Item>
                    </div>
                    <div className={styles.email}>
                      <div className={styles.emailTitle}>
                        <div className={styles.emailText}>Password</div>
                      </div>

                      <Form.Item
                        name={'password'}
                        style={{ padding: 0, margin: 0, width: '100%' }}
                        rules={[
                          {
                            required: true,
                            message: 'Password is required',
                          },
                          {
                            min: 8,
                            message: 'Invalid credentials',
                          },
                        ]}>
                        <Input.Password
                          height={40}
                          placeholder='Enter password'
                          {...formik.getFieldProps('password')}></Input.Password>
                      </Form.Item>
                    </div>
                  </div>
                  <Form.Item style={{ padding: 0, margin: 0, width: '100%' }}>
                    <Button
                      type='primary'
                      style={{ width: '100%', height: '40px' }}
                      htmlType='submit'
                      loading={loading}>
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
                <div className={styles.details}>
                  <span className={styles.term}>
                    By Signing up, you agree to our{' '}
                    <span className={styles.underline} onClick={handleTerms}>
                      Terms
                    </span>{' '}
                    &{' '}
                    <span className={styles.underline} onClick={handlePrivacyPolicy}>
                      Privacy Policy.
                    </span>
                  </span>
                  <span className={styles.already}>
                    Already have an account?{' '}
                    <span className={styles.log} onClick={handleLoginNavigate}>
                      Log In
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.outerLeftContainer}>
          <div className={styles.card} style={size === 'small' ? { padding: '32px 64px' } : {}}>
            <div className={styles.titleFrame}>
              <div className={styles.title} style={size === 'small' ? { fontSize: '24px' } : {}}>
                Sign Up
              </div>
              <div className={styles.subTitle}>
                <div className={styles.subTitleText} style={size === 'small' ? { fontSize: '12px' } : {}}>
                  Create your account or sign up with Google to start using Convrt.
                </div>
              </div>
            </div>
            <div className={styles.google} style={size === 'small' ? { height: '28px' } : {}}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <button
                  className='gsi-material-button'
                  style={{ width: '400px' }}
                  onClick={() => {
                    googleSSOSignUp()
                  }}>
                  <div className='gsi-material-button-state'></div>
                  <div className='gsi-material-button-content-wrapper'>
                    <div className='gsi-material-button-icon'>
                      <svg
                        version='1.1'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 48 48'
                        xmlnsXlink='http://www.w3.org/1999/xlink'
                        style={{ display: 'block' }}>
                        <path
                          fill='#EA4335'
                          d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                        <path
                          fill='#4285F4'
                          d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                        <path
                          fill='#FBBC05'
                          d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                        <path
                          fill='#34A853'
                          d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                        <path fill='none' d='M0 0h48v48H0z'></path>
                      </svg>
                    </div>
                    <span className='gsi-material-button-contents'>Sign up with Google</span>
                    <span style={{ display: 'none' }}>Sign up with Google</span>
                  </div>
                </button>
              </div>
            </div>
            <Divider plain style={{ marginTop: 5, borderColor: '#DFE1E6' }}>
              Or
            </Divider>

            <Form
              onFinish={formik.handleSubmit}
              className={styles.form}
              style={size === 'small' ? { gap: '10px' } : {}}>
              <div className={styles.form} style={size === 'small' ? { gap: '5px' } : {}}>
                <div className={styles.first_last}>
                  <div className={styles.first}>
                    <span className={styles.first_text}>First name</span>
                    <Form.Item
                      style={{ padding: 0, margin: 0 }}
                      name='first'
                      rules={[{ required: true, message: 'First Name is required', whitespace: true }]}>
                      <Input size={size} placeholder='Enter first name' {...formik.getFieldProps('first')} />
                    </Form.Item>
                  </div>
                  <div className={styles.last}>
                    <span className={styles.last_text}>Last name</span>
                    <Form.Item
                      style={{ padding: 0, margin: 0 }}
                      name='last'
                      rules={[{ required: true, message: 'Last Name is required', whitespace: true }]}>
                      <Input size={size} placeholder='Enter last name' {...formik.getFieldProps('last')} />
                    </Form.Item>
                  </div>
                </div>
                <div className={styles.email}>
                  <div className={styles.emailTitle}>
                    <div className={styles.emailText}>Email</div>
                  </div>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        message: 'Invalid email address',
                      },
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ]}
                    style={{ padding: 0, margin: 0, width: '100%' }}>
                    <Input size={size} placeholder='Enter email' {...formik.getFieldProps('email')}></Input>
                  </Form.Item>
                </div>
                <div className={styles.email}>
                  <div className={styles.emailTitle}>
                    <div className={styles.emailText}>Password</div>
                  </div>

                  <Form.Item
                    name={'password'}
                    style={{ padding: 0, margin: 0, width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Password is required',
                      },
                      {
                        min: 8,
                        message: 'Invalid credentials',
                      },
                    ]}>
                    <Input.Password
                      size={size}
                      placeholder='Enter password'
                      {...formik.getFieldProps('password')}></Input.Password>
                  </Form.Item>
                </div>
              </div>
              <Form.Item style={{ padding: 0, margin: 0, width: '100%' }}>
                <Button
                  type='primary'
                  style={{ width: '100%', height: size === 'small' ? '30px' : '40px' }}
                  htmlType='submit'
                  loading={loading}>
                  Continue
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className={styles.details}>
            <span className={styles.term}>
              By Signing up, you agree to our{' '}
              <span className={styles.underline} onClick={handleTerms}>
                Terms
              </span>{' '}
              &{' '}
              <span className={styles.underline} onClick={handlePrivacyPolicy}>
                Privacy Policy.
              </span>
            </span>
            <span className={styles.already}>
              Already have an account?{' '}
              <span className={styles.log} onClick={handleLoginNavigate}>
                Log In
              </span>
            </span>
          </div>
        </div>
      )}
    </>
  )
}
