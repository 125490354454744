import { useState } from 'react'
import { Button, Form, Input, message, Modal, Select, Skeleton, Upload, UploadFile } from 'antd'
import { useAppSelector } from 'state'
import plusOutline from 'common/assets/svg/plus_outlined.svg'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import styles from './Articles.module.scss'
import ArticleCard from './ArticleCard'
import { LocalStorageKeys } from 'common/constants/localstorage.constants'
interface ArticlesProps {
  articles: any
  title: any
  actionText: any
  onClose: () => void
  onOpen: () => void
  loading: boolean
  actionFunc?: () => void
}

export default function Articles({ articles, onClose, onOpen, title, actionText, loading, actionFunc }: ArticlesProps) {
  const { isAdmin, allCategories } = useAppSelector((state) => state.learning)
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const [coverImage, setCoverImage] = useState<UploadFile>()
  const all = title === 'Articles'
  let allCategoriesWithArticles = Array.isArray(allCategories)
    ? [...allCategories, { title: 'Articles', name: 'articles' }]
    : []
  const options = allCategoriesWithArticles?.map((category: any) => ({
    label: category.title,
    value: category.name,
  }))

  const openUploadModal = () => {
    setUploadModalOpen(true)
  }
  const closeUploadModal = () => {
    setUploadModalOpen(false)
  }
  const handleSeeMore = () => {
    actionFunc && actionFunc()
  }
  const uploadArticle = async (values: any) => {
    const coverName = coverImage?.fileName
    const formData = new FormData()
    formData.append('title', values.title)
    formData.append('description', values.description)
    formData.append('category', values.category)
    formData.append('cover', new File([new Blob([coverImage as any])], coverName as string))
    formData.append('reading_min', values.reading_min)
    const headers = {
      'Content-Type': 'multipart/form-data',
      authorization: `Bearer ${localStorage
        .getItem(LocalStorageKeys.ACCESS_TOKEN)
        ?.substring(1, String(localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN)).length - 1)} `,
      environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/learning_center/uploadArticle`, formData, {
        headers: headers,
      })

      message.success('Article uploaded successfully')
    } catch (error) {
      message.error('Error uploading article')
      console.error('Error uploading article:', error)
    }
  }

  return (
    <>
      <Modal
        width={800}
        title='Upload Article'
        open={uploadModalOpen}
        onCancel={closeUploadModal}
        footer={null}
        zIndex={1002}>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={uploadArticle}
          autoComplete='off'>
          <Form.Item label='Title' name='title' rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item label='description' name='description' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label='reading minutes' name='reading_min' rules={[{ required: true }]}>
            <Input type='number' />
          </Form.Item>
          <Form.Item label='category' name='category' rules={[{ required: true }]}>
            <Select options={options}></Select>
          </Form.Item>

          <Form.Item label='cover image' name='cover' rules={[{ required: true }]}>
            <Upload
              beforeUpload={(file) => {
                setCoverImage(file)
                return false
              }}
              onRemove={() => {
                setCoverImage(undefined)
              }}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* FINISH UPLOAD ARTICLE MODAL */}
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div style={{ flex: 0.05, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            <span className={styles.title}>{title}</span>
            {isAdmin && (
              <span>
                <img src={plusOutline} alt='Plus' style={{ cursor: 'pointer' }} onClick={openUploadModal} />
              </span>
            )}
          </div>

          <span className={styles.seeMore} onClick={handleSeeMore}>
            {actionText}
          </span>
        </div>
        <div
          style={{
            flex: 0.95,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            maxHeight: '300px',
            overflow: 'hidden',
            overflowX: 'hidden',
          }}>
          {loading ? (
            <>
              <Skeleton.Button active size='large' block style={{ height: '70px' }} />
              <Skeleton.Button active size='large' block style={{ height: '70px' }} />
              <Skeleton.Button active size='large' block style={{ height: '70px' }} />
            </>
          ) : (
            <>
              {articles
                ?.filter((article: any) => article.published)
                .sort((a: any, b: any) => (all ? a.priority_all - b.priority_all : a.priority - b.priority))
                .slice(0, 3)
                .map((article: any, index: number) => {
                  if (!article.published) {
                    return null
                  } else {
                    return (
                      <ArticleCard
                        key={index}
                        article={article}
                        onClose={onClose}
                        onOpen={onOpen}
                        all={title === 'Articles'}
                      />
                    )
                  }
                })}
            </>
          )}
        </div>
      </div>
    </>
  )
}
