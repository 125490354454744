import { Tooltip } from 'antd'
import styles from './Where.module.scss'
import { useAppSelector } from 'state'

interface GoalProps {
  goal: string
  setGoal: (value: string) => void
}

export default function Goal({ goal, setGoal }: GoalProps) {
  const { settings } = useAppSelector((state) => state.signup)
  const goals: any = settings?.goal || []
  const clickedStyle = {
    borderRadius: ' 10px',
    background: ' #faf9ff',
    border: '1px solid #7144FF',
  }

  return (
    <div className={styles.frame}>
      <div className={styles.frame_title}>
        <span className={styles.text}>What is your main goal with Convrt?</span>
      </div>
      <div className={styles.places}>
        {goals.map((p: any) => (
          <div
            className={styles.friend}
            style={{ cursor: 'pointer', ...(goal === p.value ? clickedStyle : {}) }}
            onClick={() => setGoal(p.value)}>
            <Tooltip title={p.label}>
              <span className={styles.text}>{p.label}</span>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  )
}
