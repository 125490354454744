import { Layout } from 'antd'
import React, { useEffect } from 'react'
import { Content } from 'antd/es/layout/layout'
import BuilderHeader from './BuilderHeader'
import Sider from 'antd/es/layout/Sider'
import BuilderSider from './BuilderSider'
import BuilderFlow from './BuilderFlow'
import { useLocation, useNavigate } from 'react-router-dom'

// interface BuilderProps {

// }

const Builder: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const siderStyle: React.CSSProperties = {
    lineHeight: '120px',
    color: '#ffffff',
    backgroundColor: '#F9FAFB',
    boxShadow: '10px 0px 30px 0px #0000000D',
    padding: '8px 16px',
    height: '100%',
    overflowY: 'scroll',
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const urlCampaignId = params.get('campaign_id')

    if (location.pathname.includes('/edit-manually/view') && urlCampaignId && !location.state?.prevPath) {
      navigate(`/outreach/create-new-campaign/summary?campaign_id=${urlCampaignId}`, { replace: true })
    } else if (location.pathname.includes('/edit-manually') && urlCampaignId && !location.state?.prevPath) {
      navigate(`/outreach/create-new-campaign?campaign_id=${urlCampaignId}`, { replace: true })
    } else if (location.pathname.includes('/campaign-builder') && urlCampaignId && !location.state?.prevPath) {
      navigate(`/outreach/create-new-campaign?campaign_id=${urlCampaignId}`, { replace: true })
    } else {
      window.history.replaceState({}, '')
    }
  }, [location.pathname, location.search, navigate])

  return (
    <Layout>
      <BuilderHeader />
      <Layout>
        <Sider style={siderStyle} width='25%'>
          <BuilderSider />
        </Sider>
        <Content>
          <BuilderFlow />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Builder
