import React, { useEffect, useState } from 'react'
import { Card, Input, Slider, Select, Typography } from 'antd'
import styles from './ROICalculator.module.scss'
import CustomMarks from './CustomMarks'
import small_tick from 'common/assets/svg/small_tick.svg'
import dropdown_icon from 'common/assets/svg/dropdown_icon.svg'
import { evaluate } from 'mathjs'

const { Option } = Select
const { Title, Text } = Typography

interface ROICalculatorProps {
  selectedPlan: any
  selectedPrice: any
  formulas: any
  selectedOptions: any
  billingCycle?: any
}

interface RoiData {
  hoursSavedMonthly: number
  newRevenue: number
  timeValueSaved: number
  convrtCost: number
  totalROI: number
}

const ROICalculator: React.FC<ROICalculatorProps> = ({
  selectedPlan,
  selectedPrice,
  formulas,
  selectedOptions,
  billingCycle,
}) => {
  const [numLeads, setNumLeads] = useState(6000)
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(25)
  const [dealSize, setDealSize] = useState<number>(5000)
  const [hourlyRate, setHourlyRate] = useState<number>(50)
  const [conversionRate, setConversionRate] = useState<number>(23)
  const [roiValues, setRoiValues] = useState<RoiData>({
    hoursSavedMonthly: 0,
    newRevenue: 0,
    timeValueSaved: 0,
    convrtCost: 0,
    totalROI: 0,
  })

  const evaluateFormula = (formula: string, variables: Record<string, number>) => {
    let parsedFormula = formula

    // Replace variables in the formula string with actual values
    Object.keys(variables).forEach((key) => {
      // const regex = new RegExp(key, 'g')
      const regex = new RegExp(`\\b${key}\\b`, 'g')
      parsedFormula = parsedFormula.replace(regex, variables[key].toString())
    })

    try {
      return evaluate(parsedFormula)
    } catch (error) {
      console.error('Error evaluating formula:', error)
      return 0
    }
  }

  // Calculate ROI based on user input
  const calculateROI = () => {
    // const hoursSavedMonthly = calculateHoursSaved() * 4
    // const timeValueSaved = hoursSavedMonthly * hourlyRate
    // const newRevenue = (dealSize * conversionRate) / 100
    // const convrtCost = selectedPrice

    const hoursSavedFormula = formulas.find((f: any) => f.formula_name === 'hours_saved')
    const newRevenueFormula = formulas.find((f: any) => f.formula_name === 'new_revenue')
    const timeValueSavedFormula = formulas.find((f: any) => f.formula_name === 'time_value_saved')
    const totalROIFormula = formulas.find((f: any) => f.formula_name === 'total_roi')

    const hoursSaved = evaluateFormula(hoursSavedFormula.formula, { hoursPerWeek })

    // Calculate new revenue
    const newRevenue = evaluateFormula(newRevenueFormula.formula, {
      conversionRate: conversionRate / 100,
      numLeads,
      dealSize,
    })

    // Calculate time value saved
    const timeValueSaved = evaluateFormula(timeValueSavedFormula.formula, {
      hoursSaved,
      hourlyRate,
    })

    // Calculate total ROI
    const convrtCost = selectedPrice
    const totalROI = evaluateFormula(totalROIFormula.formula, {
      newRevenue,
      timeValueSaved,
      convrtCost,
    })

    return {
      hoursSavedMonthly: Math.round(hoursSaved),
      newRevenue: Math.round(newRevenue),
      timeValueSaved: Math.round(timeValueSaved),
      convrtCost: Math.round(convrtCost),
      totalROI: Math.round(totalROI),
    }
  }

  useEffect(() => {
    if (formulas?.length > 0) {
      const roiValues = calculateROI()
      setRoiValues(roiValues)
    }
  }, [formulas, hoursPerWeek, dealSize, hourlyRate, conversionRate, numLeads, selectedPlan, selectedPrice])

  useEffect(() => {
    if (formulas?.length > 0) {
      const planId = selectedPlan?.id
      const option = selectedOptions?.[planId]
      const selectedObj = selectedPlan?.pricingOptions?.[billingCycle]?.[option]

      if (selectedObj?.points && typeof selectedObj.points === 'number') {
        setNumLeads(selectedObj.points * 2)
      }
    }
  }, [selectedPlan, selectedPrice])

  return (
    <div className={styles.container}>
      <h1>HOW MUCH CAN YOU SAVE & EARN WITH CONVRT?</h1>
      <Card className='CardNoPadding'>
        <div className={styles.roiCalculator}>
          <div className={styles.leftSide}>
            <Title className={styles.specialFont} level={3}>
              ROI CALCULATOR
            </Title>
            <Text className={styles.specialFont}>
              How many hours do you spend each week looking for leads, scraping socials, trying to match details, and
              copying things into Excel?
            </Text>
            <div className={styles.sliderContainer}>
              <Title level={1} style={{ color: '#7C3AED' }}>
                {hoursPerWeek} h
              </Title>
              <Slider
                className='ROISlider'
                min={0}
                max={40}
                value={hoursPerWeek}
                onChange={setHoursPerWeek}
                style={{ color: '#7C3AED' }}
              />
              <CustomMarks min={0} max={40} step={10} value={hoursPerWeek} onChange={setHoursPerWeek} />
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.rowDiv}>
                <p className={styles.specialFont}>
                  <img style={{ marginRight: '5px' }} src={small_tick} alt='' />
                  What is your avg deal size?
                </p>
                <Input
                  type='number'
                  value={dealSize}
                  onChange={(e) => setDealSize(Number(e.target.value))}
                  prefix='$'
                  suffix='/ year'
                  style={{ width: '28%' }}
                />
              </div>
              <div className={styles.rowDiv}>
                <p className={styles.specialFont}>
                  <img style={{ marginRight: '5px' }} src={small_tick} alt='' />
                  How much is one hour worth for you?
                </p>
                <Input
                  type='number'
                  value={hourlyRate}
                  onChange={(e) => setHourlyRate(Number(e.target.value))}
                  prefix='$'
                  suffix='/ hr'
                  style={{ width: '20%' }}
                />
              </div>
              <div className={styles.rowDiv}>
                <p className={styles.specialFont}>
                  <img style={{ marginRight: '5px' }} src={small_tick} alt='' />
                  What is your conversion rate from meeting?
                </p>
                <Select
                  value={conversionRate}
                  onChange={(value) => setConversionRate(Number(value))}
                  style={{ width: '85px' }}
                  suffixIcon={<img src={dropdown_icon} alt='' />}>
                  {Array.from(Array(101).keys()).map((rate) => (
                    <Option key={rate} value={rate}>
                      {rate} %
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <Card className={styles.resultsContainer} bordered={false} style={{ width: '350px' }}>
            <div className={styles.resFlexDiv}>
              <div className={styles.resultsHeader}>
                <Title level={1} style={{ textAlign: 'center', fontSize: '32px' }}>
                  ${roiValues.totalROI.toLocaleString()} <span>/mo</span>
                </Title>
                <Text className={styles.specialFont}>is your monthly ROI with Convrt</Text>
              </div>
              <div className={styles.roiBreakdown}>
                <div>
                  <Text className={styles.specialFont}>Hours saved monthly</Text>
                  <Text>{roiValues.hoursSavedMonthly}</Text>
                </div>
                <div>
                  <Text className={styles.specialFont}>New revenue with Convrt</Text>
                  <Text>${roiValues.newRevenue.toLocaleString()}</Text>
                </div>
                <div>
                  <Text className={styles.specialFont}>Time value saved by Convrt</Text>
                  <Text>${roiValues.timeValueSaved.toLocaleString()}</Text>
                </div>
                <div style={{ marginBottom: '2px', borderBottom: '2px solid black' }}>
                  <Text className={styles.specialFont}>Convrt monthly cost</Text>
                  <Text>${roiValues.convrtCost}</Text>
                </div>
                <div>
                  <Text className={styles.specialFont}>Total monthly ROI</Text>
                  <Text strong>${roiValues.totalROI.toLocaleString()}</Text>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export default ROICalculator
