import React, { useEffect, useState } from 'react'
import { Tabs, Modal } from 'antd'
import styles from './Campaigns.module.scss'
import type { TabsProps } from 'antd'
import { useLazyGetCampaignHeaderQuery, useLazyGetCampaignsQuery } from 'features/Outreach/state/api/OutreachApi'
import BlurPaywallFilters from '../../../../features/intent-signals/components/paywalls/BlurPaywallFilters'
import { outreach } from 'common/constants/outreach.constants'
import { useAppDispatch, useAppSelector } from 'state'
// import CooldownBanner from '../../OutreachCampaign/MainCampaign/Campaigns/UI/CooldownBanner'
// import StautsBanner from '../../OutreachCampaign/MainCampaign/Campaigns/UI/StautsBanner'
// import OutreachMainPageHeader from '../../OutreachCampaign/OutreachMainPageHeader'
import CampaignFilters from '../../OutreachCampaign/MainCampaign/Campaigns/Filters/CampaignFilters'
// import MainCampaign from '../../OutreachCampaign/MainCampaign/Campaigns/MainCampaign'
import MainRecipients from '../../OutreachCampaign/MainCampaign/Recipients/MainRecipients'
import MainMessages from '../../OutreachCampaign/MainCampaign/Messages/MainMessages'
import CampaignsTable from './CampaignsTab/CampaignsTable'
import CampaignsStats from './CampaignsStats'
import { SET_ALL_CAMPAIGNS_DATA } from 'common/components/OutreachCampaign/state/outreachCampaignSlice'
import { useNavigate } from 'react-router-dom'
import { RESET_CAMPAIGN_AI } from '.././state/outreachAICampaignSlice'
import { RESET_BUILDER } from '../Builder/Slice/CampaignBuilderSlice'

const Campaigns: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [getCampaigns, { data: campaignsData }] = useLazyGetCampaignsQuery()
  const [getCampaignStats, { data: campaignsHeaderData }] = useLazyGetCampaignHeaderQuery()
  const { campaign_filters, campaign_date_filters } = useAppSelector((state) => state.outreachCampaign)

  const [addonType, setAddonType] = useState('')
  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState<string>('1')
  const [openContactSales, setOpenContactSales] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [triggerGetCampaigns, setTriggerGetCampaigns] = useState(false)
  // const [hasCampaigns, setHasCampaigns] = useState(true)

  const handleTabChange = (key: string) => {
    if (
      (!user?.addons?.campaign_messages_pannel && key === '3') ||
      (!user?.addons?.campaign_recipient_pannel && key === '2')
    ) {
      setOpenContactSales(true)
      setAddonType(key === '3' ? 'campaign_messages_pannel' : 'campaign_recipient_pannel')
    } else {
      setSelectedTab(key)
    }
  }

  useEffect(() => {
    // getCampaignsData()
    dispatch(RESET_CAMPAIGN_AI())
    dispatch(RESET_BUILDER())
  }, [])

  useEffect(() => {
    const getCampaignsData = async () => {
      if (filtersChanged) {
        setLoading(true)
      }
      if (campaign_filters.type) {
        const { from, to, dateType } = campaign_date_filters
        switch (campaign_filters.type) {
          case 'Campaign Name':
            await getCampaignStats({
              campaign_name: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              campaign_name: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            break
          case 'Senders':
            await getCampaignStats({
              senders: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              senders: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          case 'Show Archive':
            await getCampaignStats({
              is_archived: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              is_archived: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          case 'Platform':
            await getCampaignStats({
              platform: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })
            await getCampaigns({
              platform: campaign_filters.value,
              fromDate: from,
              toDate: to,
              dateType: dateType,
            })

            break
          default:
            break
        }
      } else {
        const { from, to, dateType } = campaign_date_filters
        await getCampaignStats({ fromDate: from, toDate: to, dateType: dateType })
        const { data } = await getCampaigns({ fromDate: from, toDate: to, dateType: dateType })
        dispatch(SET_ALL_CAMPAIGNS_DATA(data))
      }
      if (filtersChanged) {
        setLoading(false)
        setFiltersChanged(false)
      }
    }
    getCampaignsData()

    const intervalId = setInterval(getCampaignsData, 30000)
    return () => clearInterval(intervalId)
  }, [campaign_filters, campaign_date_filters, filtersChanged, triggerGetCampaigns])

  useEffect(() => {
    setFiltersChanged(true)
  }, [campaign_filters, campaign_date_filters])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Campaigns',
    },
    {
      key: '2',
      label: 'Recipients',
    },
    {
      key: '3',
      label: 'Messages',
    },
  ]

  useEffect(() => {
    if (!loading && campaignsData?.length === 0 && !campaign_date_filters.from) {
      navigate('/outreach/create-new-campaign')
    }
  }, [campaignsData, loading])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <Tabs defaultActiveKey='1' items={items} onChange={handleTabChange} className='outreachCampaignTabs' />
        </div>
        <CampaignFilters selectedTab={selectedTab} />
        <CampaignsStats campaignsHeaderData={campaignsHeaderData} isFetching={loading} />

        {selectedTab === '1' && (
          <CampaignsTable
            campaigns={campaignsData}
            isFetching={loading}
            setTriggerGetCampaigns={setTriggerGetCampaigns}
          />
        )}
        {selectedTab === '2' && <MainRecipients />}
        {selectedTab === '3' && <MainMessages />}

        <Modal
          open={openContactSales}
          onCancel={() => setOpenContactSales(false)}
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}>
          <div className='flex justify-content-center'>
            <BlurPaywallFilters
              addon={addonType}
              component={[]}
              onClick={() => window.open(outreach.ROY_TG_LINK, '_blank', 'noreferrer')}
              customClass='contactSalesNoMargin'
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Campaigns
