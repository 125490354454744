import React, { useEffect, useMemo, useState } from 'react'
import { Typography, Modal, Breadcrumb, Tooltip } from 'antd'
import styles from './SocialSettings.module.scss'
import OutreachConnection from 'common/components/OutreachConnection/OutreachConnection'
import useHubspot from '../Pages/Hooks/useHubspot'
import { useLocalStorage } from 'usehooks-ts'
import OnBoardingQrcodPage from '../../../common/components/OutreachConnection/OnBoardingPages/OnBoardingQrcodPage' //OnBoardingPages/OnBoardingQrcodPage'
import {
  useLazyUpdateOutreachDetailsQuery,
  useLazyGetCampaignsForAccountsServiceQuery,
  useLazyGetUserMetadataQuery,
} from 'features/settings/state/api/SettingsApi'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import {
  getFacebookTableData,
  getInstagramTableData,
  getLinkedinTableData,
  getTelegramTableData,
} from 'common/utils/userHelpers'
import SocialBox from './Components/SocialBox'
import hubspotLogo from 'common/assets/jpg/hubspot.jpg'
import zapierLogo from 'common/assets/jpg/zapier.png'
import pipedriveLogo from 'common/assets/jpg/pipedrive.jpg'
import slackLogo from 'common/assets/svg/slack_square_logo.svg'
import salesforceLogo from 'common/assets/svg/salesforce_logo.svg'
// import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
// import { useAuth } from 'common/hooks/useAuth.hooks'
import SocialAccountTable from './Components/SocialAccountTable'
import AppIntegration from './AppIntegration'
import useIntegrations from './Hooks/useIntegrations'
import NotificationModal from 'GeneralComponents/NotificationModal'
import { useLazyGetPricingPlansQuery } from './Upgrade/API/UpgradeApi'

interface SocialSettingsProps {
  hubspotKey: any
  slackKey: any
  salesforceKey?: any
  shouldHideCheckout: any
  resetKeysStates: (state: string) => void
}

const SocialSettings: React.FC<SocialSettingsProps> = ({
  hubspotKey,
  slackKey,
  salesforceKey,
  shouldHideCheckout,
  resetKeysStates,
}) => {
  const [getUserMetadata] = useLazyGetUserMetadataQuery()
  const [refetchUpdateOutreachDetails] = useLazyUpdateOutreachDetailsQuery()
  const [getCampaignNames, { data: allCampaignsAccount }] = useLazyGetCampaignsForAccountsServiceQuery()
  // const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [openQrcodPage, setOpenQrcodPage] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState({ area_code: '', number: '' })
  const [user, setUser] = useLocalStorage<any>('user', null)
  const [polling, setPolling] = useState(false)
  const [outreachConnectionModalOpen, setOutreachConnectionModalOpen] = useState(false)
  const { handleLoginHubSpot } = useHubspot()
  const [openSocialConnectionModal, setOpenSocialConnectionModal] = useState(false)
  const [platform, setPlatform] = useState<any>('')
  const [checkLocalStorage, setCheckLocalStorage] = useState()
  // const { userDetails } = useAuth()
  // const [userData, setUserData] = useState(userDetails)
  const { addons } = user || '{}'
  const total_number_of_accounts_connected = addons?.total_number_of_accounts_connected || 0
  const [numberOfConnectedAccounts, setNumberOfConnectedAccounts] = useState(total_number_of_accounts_connected)
  const { slack_integration, salesforce_integration } = addons
  const [currentDetailSocial, setCurrentDetailSocial] = useState('')
  const [telegramTableData, setTelegramTableData] = useState<any>(getTelegramTableData(user, allCampaignsAccount))
  const [linkedinTableData, setLinkedinTableData] = useState<any>(getLinkedinTableData(user))
  const [instagramTableData, setInstagramTableData] = useState<any>(getInstagramTableData(user))
  const [facebookTableData, setFacebookTableData] = useState<any>(getFacebookTableData(user))
  const { handleConnect, handleDisconnect } = useIntegrations()
  const [userMetadata] = useLocalStorage<any>('user_metadata', {})
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false)
  const [getPricingPlans, { data: plansData }] = useLazyGetPricingPlansQuery()

  // const toggleAddon = (platform: string) => {
  //   if (user?.addons[`channel_${platform}`] === true) {
  //     return true
  //   }
  //   return false
  // }

  const canConnectMore = (platform: string) => {
    if (user?.addons?.[`channel_${platform}`] === true) {
      return true
    }
    const maxChannels = user?.addons?.maxChannels || 0

    const trueAddons = [
      user?.addons?.channel_instagram,
      user?.addons?.channel_facebook,
      user?.addons?.channel_linkedin,
      user?.addons?.channel_telegram,
      user?.addons?.channel_discord,
      user?.addons?.channel_twitter,
    ].filter(Boolean).length

    const canConnectMore = trueAddons < maxChannels
    return canConnectMore
  }

  const handleOutreachEdit = async (key: React.Key, e: React.ChangeEvent<HTMLInputElement>, type: any) => {
    const value = e.target.value

    const userData = await refetchUpdateOutreachDetails({
      value,
      type,
      key,
    })

    setUser(userData?.data)
  }

  const channelConnectedCount = useMemo(() => {
    let count = 0
    telegramTableData?.length > 0 && count++
    linkedinTableData?.length > 0 && count++
    instagramTableData?.length > 0 && count++
    facebookTableData?.length > 0 && count++

    return count
  }, [telegramTableData, linkedinTableData, instagramTableData, facebookTableData])

  const formatAmountBySocial: any = {
    telegram: telegramTableData?.length,
    linkedin: linkedinTableData?.length,
    instagram: instagramTableData?.length,
    facebook: facebookTableData?.length,
  }
  const shouldRender = Object.values(formatAmountBySocial).some((amount) => amount === 0)

  const handleHubspotMapping = async () => {
    setCurrentDetailSocial('Hubspot')
  }

  const appIntergration = [
    {
      name: 'Hubspot',
      logo: hubspotLogo,
      description: 'A CRM platform for managing customer interactions, marketing, and sales automation.',
      connected: hubspotKey,
      functions: { connect: handleLoginHubSpot, button: handleHubspotMapping },
      button: 'Hubspot Field Mapping',
    },
    {
      name: 'Slack',
      logo: slackLogo,
      description:
        'A collaboration hub that connects your work with the people you work with through real-time messaging and integrations.',
      comingSoon: slack_integration !== true,
      connected: slackKey,
      functions: {
        connect: async () => {
          await handleConnect('Slack')
        },
        button: async () => {
          const data = await handleDisconnect('Slack')
          if (data) {
            resetKeysStates('slack')
          }
        },
      },
      button: 'Disconnect Slack',
    },
    {
      name: 'Salesforce',
      logo: salesforceLogo,
      description:
        'A leading CRM platform that helps businesses manage customer relationships, sales, and marketing efforts.',
      comingSoon: salesforce_integration !== true,
      connected: salesforceKey,
      functions: {
        connect: async () => {
          await handleConnect('Salesforce')
        },
        button: async () => {
          const data = await handleDisconnect('Salesforce')
          if (data) {
            resetKeysStates('salesforce')
          }
        },
      },
      button: 'Disconnect Salesforce',
    },
    {
      name: 'Zapier',
      logo: zapierLogo,
      description: 'Automates workflows by connecting your apps, saving time and reducing manual work.',
      comingSoon: true,
    },
    {
      name: 'Pipedrive',
      logo: pipedriveLogo,
      description: 'A sales CRM with visual pipelines and customizable workflows to streamline sales processes.',
      comingSoon: true,
    },
  ]

  const handleOpenSocialConnectionModal = (platform: string) => {
    setPlatform(platform)
    if (canConnectMore(platform)) {
      setOpenSocialConnectionModal(true)
    } else {
      // message.warning(`Your plan does not support connecting your ${platform} account.`)
      setOpenUpgradeModal(true)
    }
  }

  useEffect(() => {
    const { facebook, instagram, linkedin } = user
    const length = facebook?.length + instagram?.length + linkedin?.length
    setNumberOfConnectedAccounts(length)
  }, [user])

  const handleAddSocialAccount = (socialName: string) => {
    if (socialName === 'telegram') {
      if (total_number_of_accounts_connected <= numberOfConnectedAccounts) {
        handleOpenSocialConnectionModal(socialName)
      } else {
        setOutreachConnectionModalOpen(true)
        setPolling(true)
      }
    } else {
      handleOpenSocialConnectionModal(socialName)
    }
  }

  const handleNavigateToDetailSocial = (socialName: string) => {
    setCurrentDetailSocial(socialName)
  }

  const handleNavigateToDetailAppIntegration = (appName: string) => {
    // For now added the if, remove when adding the inside screens
    if (appName === 'hubspot') {
      setCurrentDetailSocial(appName)
    }
  }

  const handleAddTelegramAccount = () => {
    setOutreachConnectionModalOpen(true)
    setPolling(true)
  }

  const handleEditTelegramAccount = (data: any) => {
    setPhoneNumber({ area_code: data.area_code, number: data.number })
    // setOpenChangeAccountInfo(true)
  }

  const handleGetTelegramCode = (data: any) => {
    setPhoneNumber({ area_code: data.area_code, number: data.number })
    setOpenQrcodPage(true)
  }

  useEffect(() => {
    if (polling) {
      const pollLocalStorage = setInterval(() => {
        const currentUser = JSON.parse(localStorage.getItem('user') || 'null')
        if (JSON.stringify(currentUser) !== JSON.stringify(user)) {
          setUser(currentUser)
        }
      }, 1000)

      return () => clearInterval(pollLocalStorage)
    }
  }, [user, setUser])

  useEffect(() => {
    setTelegramTableData(getTelegramTableData(user))
  }, [user])

  useEffect(() => {
    const getMetadata = async (platform: any, data: any, setData: any) => {
      try {
        // const { data: newData } = await getPlatformMetadata({ platform })
        // if (newData) {
        //   setData(newData[platform])
        // }
        if (platform === 'instagram') {
          const metadata = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find((m: any) => m.username === account?.handle?.replace('@', ''))
            if (metadataAccount) {
              const toReturn = {
                ...account,
                profilePhoto: metadataAccount.profile_picture_url,
                username: account?.handle,
                email: account?.email,
              }
              return toReturn
            }
            return {
              ...account,
              username: account.handle,
            }
          })
          setData(newData)
        }
        if (platform === 'facebook') {
          const metadata: any = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find(
              (m: any) => m.username.toLowerCase() === account.username.toLowerCase(),
            )
            if (metadataAccount) {
              return {
                ...metadataAccount,
                profilePhoto: metadataAccount.profile_picture_url,
                displayName: metadataAccount.contact_name,
              }
            }
            return {
              ...account,
            }
          })
          setData(newData)
        }
        if (platform === 'linkedin') {
          const metadata = userMetadata[platform] || []
          const newData = data.map((account: any) => {
            const metadataAccount = metadata.find((m: any) => m.username === account.username)
            if (metadataAccount) {
              return {
                ...metadataAccount,
                profilePhoto: metadataAccount.profile_picture_url,
                displayName: metadataAccount.contact_name,
              }
            }
            return {
              ...account,
            }
          })
          setData(newData)
        }
      } catch (err) {
        console.error(err)
      }
    }
    getMetadata('instagram', getInstagramTableData(user), setInstagramTableData)
    getMetadata('linkedin', getLinkedinTableData(user), setLinkedinTableData)
    getMetadata('facebook', getFacebookTableData(user), setFacebookTableData)
  }, [checkLocalStorage, openSocialConnectionModal, user])

  useEffect(() => {
    const getPlans = async () => {
      await getPricingPlans()
    }

    const getCampaignsData = async () => {
      await getCampaignNames()
    }

    getCampaignsData()
    getPlans()
  }, [])

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}')
    if (Object.keys(user)?.length > 0) {
      setTelegramTableData(getTelegramTableData(user, allCampaignsAccount))
    }
  }, [localStorage.getItem('user')])

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const { data } = await getUserMetadata()
        if (data) {
          const { instagram, facebook, linkedin } = data
          // Initialize updated user object with metadata
          const updatedUser = {
            ...user,
            instagram: instagram.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
            facebook: facebook.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
            linkedin: linkedin.map((account: any) => ({
              ...account,
              profilePicture: account.profilePhoto || '', // Replace with actual property name
              displayName: account.displayName || '',
            })),
          }

          setUser(updatedUser) // Update state with new user object
        }
      } catch (error) {
        console.error('Error fetching user metadata:', error)
      }
    }

    getMetadata()
  }, [])
  return (
    <>
      <div className={styles['social-page']}>
        <div className={styles['social-page__header']}>
          <Breadcrumb
            items={[
              {
                title: 'Settings',
                onClick: () => setCurrentDetailSocial(''),
              },
              {
                title: <span>{currentDetailSocial && 'Social Intergration'}</span>,
              },
            ]}
          />

          <Typography className={styles['social-page__header__pageName']}>
            {currentDetailSocial ? currentDetailSocial : 'Social & Intergrations'}
          </Typography>
        </div>

        {!currentDetailSocial && (
          <div className={styles['social-page__body']}>
            {channelConnectedCount > 0 && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  {channelConnectedCount} Channels Connected to Convrt
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] > 0)
                    .map((social: any, index: number) => {
                      return (
                        <SocialBox
                          key={index}
                          name={social}
                          imageSocialKey={social}
                          accountCount={formatAmountBySocial[social]}
                          onClickBox={() => handleNavigateToDetailSocial(social)}
                          isCommingSoon={social === 'telegram'}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            {shouldRender && (
              <div className={styles['social-page__body__section']}>
                <Typography className={styles['social-page__body__section__title']}>
                  Add {channelConnectedCount > 0 && 'More'} Networks
                </Typography>
                <div className={styles.wrapping}>
                  {Object.keys(formatAmountBySocial)
                    .filter((social) => formatAmountBySocial[social] === 0)
                    .map((social: any, index: number) => {
                      return (
                        <SocialBox
                          key={index}
                          name={social}
                          imageSocialKey={social}
                          isAddAccount
                          onAddAccount={() => handleAddSocialAccount(social)}
                        />
                      )
                    })}
                </div>
              </div>
            )}

            <div className={styles['social-page__body__section']}>
              <Typography className={styles['social-page__body__section__title']}>App Intergrations</Typography>

              <div className={styles['social-page__body__appIntergrate']}>
                {appIntergration.map((app) => {
                  return (
                    <div
                      className={styles['social-page__body__appIntergrate__item']}
                      onClick={() => app.connected && handleNavigateToDetailAppIntegration(app.name)}>
                      <div className={styles['social-page__body__appIntergrate__item__info']}>
                        <img src={app.logo} alt={`${app.name} logo`} />
                        <div>
                          <Typography className={styles['social-page__body__appIntergrate__item__info__name']}>
                            {app.name}
                          </Typography>
                          <Tooltip title={app.description}>
                            <Typography className={styles['social-page__body__appIntergrate__item__info__description']}>
                              {app.description}
                            </Typography>
                          </Tooltip>
                        </div>
                      </div>
                      {app.comingSoon && (
                        <div className={styles['social-page__body__appIntergrate__item__bottom']}>Coming Soon</div>
                      )}
                      {!app.comingSoon && (
                        <div
                          className={styles['social-page__body__appIntergrate__item__bottom__connected']}
                          onClick={(e) => {
                            e.stopPropagation()
                            app.connected ? app.functions?.button() : app.functions?.connect()
                          }}>
                          {app.connected ? app.button : 'Connect'}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {currentDetailSocial === 'telegram' && telegramTableData?.length > 0 && (
          <SocialAccountTable
            data={telegramTableData}
            setData={setTelegramTableData}
            platform='telegram'
            onAddAccount={handleAddTelegramAccount}
            onEditAccount={handleEditTelegramAccount}
            setOpenQrcodPage={handleGetTelegramCode}
            handleOutreachEdit={handleOutreachEdit}
          />
        )}

        {currentDetailSocial === 'linkedin' && linkedinTableData?.length > 0 && (
          <SocialAccountTable
            data={linkedinTableData}
            setData={setLinkedinTableData}
            platform='linkedin'
            onAddAccount={() => handleOpenSocialConnectionModal('linkedin')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'instagram' && instagramTableData?.length > 0 && (
          <SocialAccountTable
            data={instagramTableData}
            setData={setInstagramTableData}
            platform='instagram'
            onAddAccount={() => handleOpenSocialConnectionModal('instagram')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'facebook' && facebookTableData?.length > 0 && (
          <SocialAccountTable
            data={facebookTableData}
            setData={setFacebookTableData}
            platform='facebook'
            onAddAccount={() => handleOpenSocialConnectionModal('facebook')}
            onEditAccount={() => {}}
          />
        )}

        {currentDetailSocial === 'Hubspot' && <AppIntegration setCurrentDetailSocial={setCurrentDetailSocial} />}
      </div>
      {openQrcodPage && (
        <Modal
          open={openQrcodPage}
          onCancel={() => setOpenQrcodPage(false)}
          centered
          okButtonProps={{
            className: 'hidden',
          }}
          cancelButtonProps={{
            className: 'hidden',
          }}
          className={styles.modal_outreach_connection}
          bodyStyle={{
            height: 590,
            display: 'flex',
            flexDirection: 'column',
          }}>
          <OnBoardingQrcodPage
            areaCode={phoneNumber.area_code}
            phoneNumber={phoneNumber.number}
            handleGetChatLoginCode={() => {}}
            closeIcon={false}
          />
        </Modal>
      )}
      {outreachConnectionModalOpen && (
        <OutreachConnection
          open={outreachConnectionModalOpen}
          onClose={() => {
            setOutreachConnectionModalOpen(false)
            setPolling(false)
          }}
        />
      )}
      {openSocialConnectionModal && (
        <SocialConnectionModal
          open={openSocialConnectionModal}
          onClose={() => setOpenSocialConnectionModal(false)}
          platform={platform}
          setCheckLocalStorage={setCheckLocalStorage}
        />
      )}
      {openUpgradeModal && (
        <NotificationModal
          open={openUpgradeModal}
          onClose={() => setOpenUpgradeModal(false)}
          type='addAccount'
          data={{ ...plansData, platform }}
        />
      )}
    </>
  )
}

export default SocialSettings
