import React, { useEffect } from 'react'
import styles from './CampaignSummaryCanvas.module.scss'
import ReactFlow, { Background, useEdgesState, useNodesState, useReactFlow } from 'reactflow'
import CustomControls from './UI/CustomControls'
import { getNodesAndEdges } from './getNodesAndEdges'
import { useAppDispatch, useAppSelector } from 'state'
import CustomNode from '../Canvas/CustomNode'
import CustomCollapseNode from '../Canvas/CustomCollapseNode'
import { SET_FLOW_DATA } from '../state/outreachAICampaignSlice'
import { FalseEdge, TrueEdge } from '../Canvas/CustomEdge'

const CampaignSummaryCanvas: React.FC = () => {
  const dispatch = useAppDispatch()
  const { fitView } = useReactFlow()
  const { prompt, active_platforms, select_custom_template, default_templates } = useAppSelector(
    (state) => state.outreachAICampaign,
  )
  const { type: promptType } = prompt
  const isCustom = promptType === 'custom' || promptType === 'templates'

  const { builder_data } = useAppSelector((state) => state.CampaignBuilder)

  const { nodes: initialNodes, edges: initialEdges } = getNodesAndEdges(
    prompt.id,
    active_platforms,
    select_custom_template,
    builder_data,
    isCustom,
    default_templates,
    true,
    promptType,
  )
  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  // eslint-disable-next-line
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  const nodeTypes = { customNode: CustomNode, customCollapseNode: CustomCollapseNode }
  const edgeTypes: any = { trueEdge: TrueEdge, falseEdge: FalseEdge }

  useEffect(() => {
    const { nodes: newNodes, edges: newEdges } = getNodesAndEdges(
      prompt.id,
      active_platforms,
      select_custom_template,
      builder_data,
      isCustom,
      default_templates,
      true,
      promptType,
    )

    if (!isCustom) {
      dispatch(SET_FLOW_DATA({ nodes: newNodes, edges: newEdges }))
    }
    setNodes(newNodes)
    setEdges(newEdges)
  }, [prompt, setNodes, setEdges])

  return (
    <div className={styles.container}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView
        onLoad={() =>
          setTimeout(() => {
            fitView()
          }, 100)
        }
        proOptions={{ hideAttribution: true }}>
        <Background />
        <CustomControls />
      </ReactFlow>
    </div>
  )
}

export default CampaignSummaryCanvas
