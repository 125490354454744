import React, { useEffect, useState } from 'react'
import { Layout, Menu, Spin, message } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ProfileOutlined,
  NotificationOutlined,
  DollarCircleOutlined,
  // TeamOutlined,
  AppstoreAddOutlined,
  // RocketOutlined,
  TeamOutlined,
  LoadingOutlined,
  ThunderboltOutlined,
  QrcodeOutlined,
} from '@ant-design/icons'
import styles from './SettingsPage.module.scss'
import ProfileSettings from './Pages/ProfileSettings'
import SocialSettings from './Pages/SocialSettings'
import Categorizer from './Pages/Categorizer'
import SubscriptionSettings from './Pages/SubscriptionSettings'
import {
  useLazyGetIndividualPersonasQuery,
  // useLazyGetPaymentDetailsQuery,
  useLazyGetSalesforceAccessTokenQuery,
  useLazyGetSalesforceKeysQuery,
  useLazyGetSlackKeysQuery,
  useLazyGetSubscriptionSettingsQuery,
  useLazyGetUserSettingsQuery,
  useLazyGetUsersListQuery,
  useLazyIsRegistrationAdminQuery,
  useLazyRedirectSlackOAuthQuery,
} from './state/api/SettingsApi'
// import AdminSettings from './Pages/AdminSettings'
import { useAuth } from 'common/hooks/useAuth.hooks'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import useHubspot from './Pages/Hooks/useHubspot'
import ICPSettings from './Pages/ICPSettings'
import { useLazyGetPricingPlansQuery } from './Pages/Upgrade/API/UpgradeApi'
import { useLocalStorage } from 'usehooks-ts'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_REGISTRATION_ADMIN } from './state/slice/settingsSlice'
import GenerateRegistrationCode from './Pages/GenerateRegistrationCode'

const { Sider, Content } = Layout

const Settings: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [redirectSlackOAuth] = useLazyRedirectSlackOAuthQuery()
  const [getSalesforceAccessToken] = useLazyGetSalesforceAccessTokenQuery()
  const [selectedMenu, setSelectedMenu] = useState('profile')
  // eslint-disable-next-line
  const [refetchGetSubscriptionSettings, { data: subscriptionData, isFetching }] = useLazyGetSubscriptionSettingsQuery()
  const [getPricingPlans] = useLazyGetPricingPlansQuery()
  const [getUserSettings, { data: businessPersonasData }] = useLazyGetUserSettingsQuery()
  const [getIndividualPersonas, { data: individualPersonasData }] = useLazyGetIndividualPersonasQuery()
  // const [refetchGetPaymentDetails, { data: paymentData }] = useLazyGetPaymentDetailsQuery()
  // const [generateStripeLink] = useLazyGenerateStripeLinkQuery()
  const [refetchUsersList] = useLazyGetUsersListQuery()
  const { userDetails, updateUserDetails } = useAuth()
  // const { company_admin } = userDetails
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [getSlackKeys] = useLazyGetSlackKeysQuery()
  const [getSalesforceKeys] = useLazyGetSalesforceKeysQuery()
  const [getIsRegistrationAdminQuery] = useLazyIsRegistrationAdminQuery()
  const [slackKey, setSlackKey] = useState('')
  const [salesforceKey, setSalesforceKey] = useState('')
  const { existKey } = useHubspot()
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [user] = useLocalStorage<any>('user', null)
  const [userAddons, setUserAddons] = useState(user?.addons || {})
  const { pricing } = userAddons
  const { registrationAdmin } = useAppSelector((state) => state.settings)
  const dispatch = useAppDispatch()
  const handleMenuClick = async (selectedKey: string) => {
    // if (selectedKey === 'subscription') {
    //   setLoading(true)
    //   const { data } = await generateStripeLink({ settings: true })
    //   if (data?.url) {
    //     window.open(data.url, '_self')
    //   } else if (data?.not_found) {
    //     message.error('Your account is not associated with Stripe yet, please contact support.')
    //     setLoading(false)
    //   } else {
    //     message.error('Something went wrong, please contact support.')
    //     setLoading(false)
    //   }
    // } else {
    setSelectedMenu('')
    setTimeout(() => {
      setSelectedMenu(selectedKey)
    }, 1)
    // }
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      setShouldHideCheckout(data.flag)
    }

    const getSubscriptionSettings = async () => {
      await refetchGetSubscriptionSettings()
    }
    // const getPaymentDetails = async () => {
    //   await refetchGetPaymentDetails()
    // }

    const getUsersList = async () => {
      await refetchUsersList()
    }

    const userSettings = async () => {
      await Promise.all([getUserSettings(), getIndividualPersonas()])
    }
    const getIsRegistrationAdmin = async () => {
      try {
        const { data } = await getIsRegistrationAdminQuery()
        if (data) {
          const { isRegistrationAdmin } = data
          dispatch(SET_REGISTRATION_ADMIN(isRegistrationAdmin))
        }
      } catch (e) {}
    }
    getUserDetails()
    getSubscriptionSettings()
    // getPaymentDetails()
    getUsersList()
    updateUserDetails()
    userSettings()
    getIsRegistrationAdmin()
  }, [])

  useEffect(() => {
    const getKeys = async () => {
      const { data: slackData } = await getSlackKeys()
      if (slackData?.access_token) {
        setSlackKey(slackData?.access_token)
        localStorage.setItem('slack_access_token', slackData.access_token)
      }

      const { data: salesforceData } = await getSalesforceKeys()
      if (salesforceData?.access_token) {
        setSalesforceKey(salesforceData?.access_token)
        localStorage.setItem('salesforce_access_token', salesforceData.access_token)
      }
    }
    getKeys()
  }, [])

  useEffect(() => {
    if (user && user.addons) {
      setUserAddons(user.addons)
    }
  }, [user])

  const redirectSlack = async (code: any, state?: any) => {
    const { data, error } = await redirectSlackOAuth({ code })
    if (data?.connected) {
      setSlackKey(data.access_token)
      message.success(data?.message)
    } else if (error?.data?.error === 'invalid_code') {
      message.error('Invalid Session')
    } else {
      message.error('Something went wrong connecting with Slack. Please try again.')
    }
  }

  const connectSalesforce = async (code: any) => {
    const codeVerifier = localStorage.getItem('salesforce_code_verifier')
    const { data, error } = await getSalesforceAccessToken({ codeVerifier: codeVerifier, authCode: code })

    if (data?.connected) {
      setSalesforceKey(data.access_token)
      message.success(data?.message)
    } else if (error?.data?.message === 'User already connected to Salesforce') {
      // message.error('Invalid Session')
    } else {
      message.error('Something went wrong connecting with Salesforce. Please try again.')
    }
  }

  const resetKeysStates = (state: string) => {
    switch (state) {
      case 'slack':
        setSlackKey('')
        break
      case 'salesforce':
        setSalesforceKey('')
        break
    }
  }

  useEffect(() => {
    const getPlans = async () => {
      await getPricingPlans()
    }
    getPlans()
  }, [])
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')
    const path = window.location.pathname
    const slack = localStorage.getItem('slack_access_token')
    const salesforce = localStorage.getItem('salesforce_access_token')

    if (code && path.includes('slack') && !slack) {
      redirectSlack(code)
    } else if (code && path.includes('salesforce') && !salesforce) {
      connectSalesforce(code)
    }
  }, [location.search])

  return (
    <Layout className={styles.settingsLayout}>
      <Sider width={260} theme='light' className={styles.sidebar}>
        <div className={styles.sidebarHeader}>Settings</div>

        <Menu
          mode='inline'
          style={{ paddingRight: '20px' }}
          selectedKeys={[selectedMenu]}
          onClick={({ key }) => handleMenuClick(key.toString())}>
          {registrationAdmin && (
            <Menu.ItemGroup key='registrationCode' title='Registration' style={{ marginTop: '20px' }}>
              <Menu.Item key='registrationCode' icon={<QrcodeOutlined />}>
                Generate Registration Code
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          <Menu.ItemGroup key='account' title='Account' style={{ marginTop: '20px' }}>
            <Menu.Item key='profile' icon={<ProfileOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key='notifications' icon={<NotificationOutlined />}>
              Social & Integrations
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key='categorizer' title='Convert AI Settings' style={{ marginTop: '20px' }}>
            {
              <Menu.Item key='categorizer' icon={<AppstoreAddOutlined />}>
                Categorizer
              </Menu.Item>
            }
            <Menu.Item key='icp' icon={<TeamOutlined />}>
              ICP Persona
            </Menu.Item>
          </Menu.ItemGroup>
          {pricing && (
            <Menu.ItemGroup key='admin' title='Admin' style={{ marginTop: '20px' }}>
              <Menu.Item
                key='subscription'
                icon={loading ? <Spin size='small' indicator={<LoadingOutlined spin />} /> : <DollarCircleOutlined />}>
                Subscriptions & Billing
              </Menu.Item>
              {/* {company_admin && (
                <Menu.Item key='companyAdmin' icon={<RocketOutlined />}>
                  {userDetails.company}'s Cockpit
                </Menu.Item>
              )} */}
              {!shouldHideCheckout && (
                <Menu.Item
                  key='upgrade'
                  icon={<ThunderboltOutlined />}
                  onClick={() => {
                    navigate('/upgrade')
                  }}>
                  Upgrade
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content className={styles.content}>
          {/* Content for each setting page goes here */}
          {selectedMenu === 'registrationCode' && registrationAdmin && <GenerateRegistrationCode />}

          {selectedMenu === 'profile' && <ProfileSettings shouldHideCheckout={shouldHideCheckout} />}
          {selectedMenu === 'notifications' && (
            <SocialSettings
              shouldHideCheckout={shouldHideCheckout}
              hubspotKey={existKey}
              slackKey={slackKey}
              salesforceKey={salesforceKey}
              resetKeysStates={resetKeysStates}
            />
          )}
          {selectedMenu === 'categorizer' && <Categorizer shouldHideCheckout={shouldHideCheckout} />}
          {selectedMenu === 'icp' && (
            <ICPSettings businessPersonas={businessPersonasData?.ICP} individualPersonas={individualPersonasData} />
          )}
          {selectedMenu === 'subscription' && (
            <SubscriptionSettings
              data={subscriptionData}
              isFetching={isFetching}
              // paymentData={paymentData}
            />
          )}
          {/* {selectedMenu === 'companyAdmin' && (
            <AdminSettings shouldHideCheckout={shouldHideCheckout} data={usersList} paymentData={paymentData} />
          )} */}
        </Content>
      </Layout>
    </Layout>
  )
}

export default Settings
