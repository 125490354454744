import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { useDispatch } from 'react-redux'
import styles from './ChatTabs.module.scss'
import ChatMessage from '../ChatMessage'
import chatData from '../chatData'
import ChatTab from './ChatTab'
import { useAppSelector } from 'state'
import { post } from 'common/api/axios'
import { getMessageCountsForTabs, getReadUnreadSentRepliedCounts } from '../ChatHelpers'

import { SET_UNREAD, SET_ALL_CHAT, SET_GROUP } from '../state/chatSlice'
interface ChatTabsProps {
  data: any
}

const ChatTabs: React.FC<ChatTabsProps> = ({ data }) => {
  // eslint-disable-next-line
  const dispatch = useDispatch()
  const [selectedOption] = useState<string>('')
  const { selected_account, selected_chat_platform, total_all_chat, total_unread } = useAppSelector(
    (state) => state.chat,
  )
  const [activeItemId, setActiveItemId] = useState<number | null>(null)

  const accountsReadUnreadSentReplied = getReadUnreadSentRepliedCounts(data, selected_account, selected_chat_platform)
  const type =
    selected_chat_platform === 'instagram' || selected_chat_platform === 'facebook' ? 'username' : 'receiver_number'
  const { read, groups, unread } = getMessageCountsForTabs(accountsReadUnreadSentReplied, selected_account, 'all', type)

  const handleChange = (e: any) => {
    switch (e) {
      case '1':
        // post('/log', {
        //   action: 'Unified Inbox: User Selected All Chats',
        // })
        break
      case '2':
        // post('/log', {
        //   action: 'Unified Inbox: User Selected Unread Chats',
        // })
        break
      case '3':
        post('/log', {
          action: 'Unified Inbox: User Selected Groups Chats',
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (read || unread) {
      dispatch(SET_ALL_CHAT(read + unread))
      dispatch(SET_UNREAD(unread))
    }
    if (groups) {
      dispatch(SET_GROUP(groups))
    }
  }, [read, unread, groups, dispatch])

  return (
    <>
      <div className={styles.ChatTabsSidebar}>
        <div className={styles.ChatTabsSidebarContent}>
          <h2>{selected_chat_platform[0]?.toLocaleUpperCase() + selected_chat_platform?.slice(1)}</h2>
          <h1>All Active Accounts</h1>
          <Tabs defaultActiveKey='1' centered className='ChatTabsSidebarContent_tabs' onChange={handleChange}>
            <Tabs.TabPane
              tab={
                <>
                  All Chats <span>x{(total_all_chat || read + unread || 0)?.toLocaleString()}</span>
                </>
              }
              key='1'
              style={{ position: 'relative' }}>
              <ChatTab activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  Unread <span>x{(total_unread || unread || 0)?.toLocaleString()}</span>
                </>
              }
              key='2'>
              <ChatTab filter={'unread'} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
            </Tabs.TabPane>
            {selected_chat_platform === 'telegram' && (
              <Tabs.TabPane
                tab={
                  <>
                    Groups <span>x{groups?.toLocaleString()}</span>
                  </>
                }
                key='3'>
                <ChatTab filter={'groups'} activeItemId={activeItemId} setActiveItemId={setActiveItemId} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
      <ChatMessage selectedChannelId={selectedOption} selectedChannelMessages={chatData} />
    </>
  )
}

export default ChatTabs
