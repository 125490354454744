import React from 'react'
import { message } from 'antd'
import custom_message_warning from 'common/assets/svg/custom_message_warning.svg'
import custom_message_info from 'common/assets/svg/custom_message_info.svg'
import custom_message_tick from 'common/assets/svg/custom_message_tick.svg'

const useCustomMessage = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const showSuccess = (content: string) => {
    messageApi.open({
      type: 'success',
      content: content,
      icon: <img style={{ marginRight: '5px' }} src={custom_message_tick} alt='' />,
      className: 'custom-success-message',
    })
  }

  const showError = (content: string) => {
    messageApi.open({
      type: 'error',
      content: content,
      icon: <img style={{ marginRight: '5px', height: '13px' }} src={custom_message_warning} alt='' />,
      className: 'custom-error-message',
    })
  }

  const showInfo = (content: string) => {
    messageApi.open({
      type: 'info',
      content: content,
      icon: <img style={{ marginRight: '5px', height: '13px' }} src={custom_message_info} alt='' />,
      className: 'custom-info-message',
    })
  }

  const showWarning = (content: string) => {
    messageApi.open({
      type: 'warning',
      content: content,
      icon: <img style={{ margin: '0px 5px 0px 0px' }} src={custom_message_warning} alt='' />,
      className: 'custom-warning-message',
    })
  }

  return {
    contextHolder,
    showSuccess,
    showError,
    showInfo,
    showWarning,
  }
}

export default useCustomMessage
