import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Button, Divider, message } from 'antd'
import { useGoogleLogin } from '@react-oauth/google'
import { AuthService } from 'common/services/authServices'
import convrt_logo_login from '../../common/assets/svg/convrt_logo_login.svg'
import styles from './sign-up.module.scss'
import { isJWTVaild } from 'common/utils/signupUtils'
import rightSection from 'common/assets/png/RightSection.png'
import { useAppSelector } from 'state'

const SignUpPage: React.FC = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const { token } = useParams()
  const { isMobile } = useAppSelector((state) => state.General)
  const [size, setSize] = useState<'large' | 'middle' | 'small'>('small')
  // const [errorSignup, setErrorSignup] = useState('')
  // const [isSuccessfully, setIsSuccessfully] = useState(false)
  useEffect(() => {
    const affiliateToken = localStorage.getItem('affiliateToken')
    if (token) {
      try {
        // Decode the JWT token
        const payload = isJWTVaild(token)
        if (payload) {
          localStorage.setItem('affiliateToken', token)
          navigate('/register')
        }
      } catch (error) {
        console.error('Failed to decode token:', error)
      }
    }
    if (affiliateToken) {
      try {
        // Decode the JWT token
        const payload = isJWTVaild(affiliateToken)
        if (payload) {
          localStorage.setItem('affiliateToken', affiliateToken)
          navigate('/register')
        }
      } catch (error) {
        console.error('Failed to decode token:', error)
      }
    }
  }, [token])
  const formik = useFormik({
    initialValues: {
      name: '',
      company: '',
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true)
      const result = await AuthService.signUp(values)
      // setIsSuccessfully(result.code === 201 ? true : false)
      if (result.code === 201) {
        // setIsSuccessfully(true)
        // message.success('Signed Up Successfully')
        setLoading(false)
        return navigate('/login?p-conversion=true')
        // return navigate('/signup/success')
      } else {
        // setIsSuccessfully(false)
      }
      if (result.code === 'ERR_BAD_REQUEST') {
        // setErrorSignup(result.response.data.message)
        if (result?.response?.data?.redirect === true) {
          return window.open('https://bit.ly/40e4itx', '_self')
        }
        setLoading(false)
        message.error(result.response.data.message)
      } else {
        // setErrorSignup(result.message)
        setLoading(false)
        message.error(result.message)
      }
    },
  })

  const handleLoginNavigate = () => {
    navigate('/login?p-conversion=true')
  }

  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }
  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  const googleSSOSignUp = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.error('Google SSO Login Failed:', error),
  })

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        axios
          .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
            },
          })
          .then(async (res) => {
            let data: any = {}
            data.email = res.data.email
            data.name = res.data.name

            const result = await AuthService.ssoSignUp(data)
            console.log('result', result)
            if (
              (result && result?.response?.data?.message === 'The email already exists') ||
              result?.user?.email === res.data.email
            ) {
              navigate('/login?p-conversion=true')
            }
          })
          .catch((err) => console.error(err))
      }
    }

    fetchData().catch(console.error)
  }, [user])

  // if (isMobile) {
  //   console.log('Mobile')
  //   return (
  //     <>
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           width: '100%',
  //           height: '100%',
  //           overflow: 'hidden',
  //           backgroundColor: 'black',
  //         }}>
  //         Mobile
  //       </div>
  //     </>
  //   )
  // }
  const handleNavigate = () => {
    window.open('https://www.convrt.ai/', '_self')
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerHeight < 860) {
        setSize('small')
      } else {
        setSize('large')
      }
    }

    // Run on initial load
    handleResize()

    // Listen for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures this effect runs only on mount and unmount

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.page}>
            <div className={styles.innerPageMobile}>
              <div className={styles.leftMobile}>
                <div className={styles.logo}>
                  <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
                </div>
                <div className={styles.main}>
                  <div className={styles.contentMobile}>
                    <div className={styles.cardWrapperMobile}>
                      <div className={styles.cardMobile}>
                        <div className={styles.titleFrame}>
                          <div className={styles.title}>Sign Up</div>
                          <div className={styles.subTitle}>
                            <div className={styles.subTitleText}>
                              Create your account or sign up with Google to start using Convrt.
                            </div>
                          </div>
                        </div>
                        <div className={styles.google}>
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <button
                              className='gsi-material-button'
                              onClick={() => {
                                googleSSOSignUp()
                              }}>
                              <div className='gsi-material-button-state'></div>
                              <div className='gsi-material-button-content-wrapper'>
                                <div className='gsi-material-button-icon'>
                                  <svg
                                    version='1.1'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 48 48'
                                    xmlnsXlink='http://www.w3.org/1999/xlink'
                                    style={{ display: 'block' }}>
                                    <path
                                      fill='#EA4335'
                                      d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                                    <path
                                      fill='#4285F4'
                                      d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                                    <path
                                      fill='#FBBC05'
                                      d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                                    <path
                                      fill='#34A853'
                                      d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                                    <path fill='none' d='M0 0h48v48H0z'></path>
                                  </svg>
                                </div>
                                <span className='gsi-material-button-contents'>Sign up with Google</span>
                                <span style={{ display: 'none' }}>Sign up with Google</span>
                              </div>
                            </button>
                          </div>
                        </div>
                        <Divider plain style={{ marginTop: 5, borderColor: '#DFE1E6' }}>
                          Or
                        </Divider>

                        <Form onFinish={formik.handleSubmit} className={styles.form}>
                          <div className={styles.form}>
                            <div className={styles.first}>
                              <span className={styles.first_text}>Full name</span>
                              <Form.Item
                                style={{ marginTop: '0px', marginBottom: '0px' }}
                                name='name'
                                rules={[{ required: true, message: 'Full Name is required', whitespace: true }]}>
                                <Input
                                  style={{ height: 40, borderRadius: '10px', width: '100%', marginBottom: 0 }}
                                  classNames={{ input: styles.input }}
                                  {...formik.getFieldProps('name')}
                                  placeholder='Full Name'
                                />
                              </Form.Item>
                            </div>
                            <div className={styles.first}>
                              <span className={styles.first_text}>Company</span>
                              <Form.Item
                                name='company'
                                style={{ marginTop: '0px', marginBottom: '0px' }}
                                rules={[{ required: true, message: 'Company Name is required', whitespace: true }]}>
                                <Input
                                  style={{ height: 40, borderRadius: '10px' }}
                                  {...formik.getFieldProps('company')}
                                  placeholder='Company Name'
                                />
                              </Form.Item>
                            </div>
                            <div className={styles.first}>
                              <span className={styles.first_text}>Email</span>
                              <Form.Item
                                style={{ marginTop: '0px', marginBottom: '0px' }}
                                name='email'
                                rules={[
                                  {
                                    type: 'email',
                                    message: 'Invalid email address',
                                  },
                                  {
                                    required: true,
                                    message: 'Email is required',
                                  },
                                ]}>
                                <Input
                                  style={{ height: 40, borderRadius: '10px' }}
                                  {...formik.getFieldProps('email')}
                                  placeholder='Email'
                                />
                              </Form.Item>
                            </div>
                            <div className={styles.first}>
                              <span className={styles.first_text}>Password</span>
                              <Form.Item
                                style={{ marginBottom: '0px', marginTop: '0px' }}
                                name='password'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Password is required',
                                  },
                                  {
                                    min: 8,
                                    message: 'Invalid credentials',
                                  },
                                ]}>
                                <Input.Password
                                  style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
                                  {...formik.getFieldProps('password')}
                                  placeholder='Password'
                                  className='password'
                                />
                              </Form.Item>
                            </div>
                          </div>
                          <Button
                            type='primary'
                            style={{ width: '80%', height: '40px', alignSelf: 'center', marginTop: '15px' }}
                            htmlType='submit'
                            loading={loading}>
                            Continue
                          </Button>
                        </Form>
                        <div className={styles.detailsMobile}>
                          <span className={styles.term}>
                            By Signing up, you agree to our{' '}
                            <span className={styles.underline} onClick={handleTerms}>
                              Terms
                            </span>{' '}
                            &{' '}
                            <span className={styles.underline} onClick={handlePrivacyPolicy}>
                              Privacy Policy.
                            </span>
                          </span>
                          <span className={styles.already}>
                            Already have an account?{' '}
                            <span className={styles.log} onClick={handleLoginNavigate}>
                              Log In
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.page}>
            <div className={styles.innerPage}>
              <div className={styles.left}>
                <div className={styles.logo}>
                  <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
                </div>
                <div className={styles.main}>
                  <div className={styles.content}>
                    <div className={styles.card} style={size === 'small' ? { padding: '32px 64px' } : {}}>
                      <div className={styles.titleFrame}>
                        <div className={styles.title} style={size === 'small' ? { fontSize: '24px' } : {}}>
                          Sign Up
                        </div>
                        <div className={styles.subTitle}>
                          <div className={styles.subTitleText} style={size === 'small' ? { fontSize: '12px' } : {}}>
                            Create your account or sign up with Google to start using Convrt.
                          </div>
                        </div>
                      </div>
                      <div className={styles.google} style={size === 'small' ? { height: '28px' } : {}}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <button
                            className='gsi-material-button'
                            style={{ width: '400px' }}
                            onClick={() => {
                              googleSSOSignUp()
                            }}>
                            <div className='gsi-material-button-state'></div>
                            <div className='gsi-material-button-content-wrapper'>
                              <div className='gsi-material-button-icon'>
                                <svg
                                  version='1.1'
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 48 48'
                                  xmlnsXlink='http://www.w3.org/1999/xlink'
                                  style={{ display: 'block' }}>
                                  <path
                                    fill='#EA4335'
                                    d='M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z'></path>
                                  <path
                                    fill='#4285F4'
                                    d='M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z'></path>
                                  <path
                                    fill='#FBBC05'
                                    d='M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z'></path>
                                  <path
                                    fill='#34A853'
                                    d='M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z'></path>
                                  <path fill='none' d='M0 0h48v48H0z'></path>
                                </svg>
                              </div>
                              <span className='gsi-material-button-contents'>Sign up with Google</span>
                              <span style={{ display: 'none' }}>Sign up with Google</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <Divider plain style={{ marginTop: 5, borderColor: '#DFE1E6' }}>
                        Or
                      </Divider>

                      <Form
                        onFinish={formik.handleSubmit}
                        className={styles.form}
                        style={size === 'small' ? { gap: '0px' } : {}}>
                        <div className={styles.form} style={size === 'small' ? { gap: '5px' } : {}}>
                          <div className={styles.first}>
                            <span className={styles.first_text}>Full name</span>
                            <Form.Item
                              style={{ marginTop: '0px', marginBottom: '0px' }}
                              name='name'
                              rules={[{ required: true, message: 'Full Name is required', whitespace: true }]}>
                              <Input
                                // style={{ height: 40, borderRadius: '10px', width: '100%', marginBottom: 0 }}
                                size={size}
                                classNames={{ input: styles.input }}
                                {...formik.getFieldProps('name')}
                                placeholder='Full Name'
                              />
                            </Form.Item>
                          </div>
                          <div className={styles.first}>
                            <span className={styles.first_text}>Company</span>
                            <Form.Item
                              name='company'
                              style={{ marginTop: '0px', marginBottom: '0px' }}
                              rules={[{ required: true, message: 'Company Name is required', whitespace: true }]}>
                              <Input
                                // style={{ height: 40, borderRadius: '10px' }}
                                size={size}
                                {...formik.getFieldProps('company')}
                                placeholder='Company Name'
                              />
                            </Form.Item>
                          </div>
                          <div className={styles.first}>
                            <span className={styles.first_text}>Email</span>
                            <Form.Item
                              style={{ marginTop: '0px', marginBottom: '0px' }}
                              name='email'
                              rules={[
                                {
                                  type: 'email',
                                  message: 'Invalid email address',
                                },
                                {
                                  required: true,
                                  message: 'Email is required',
                                },
                              ]}>
                              <Input
                                // style={{ height: 40, borderRadius: '10px' }}
                                size={size}
                                {...formik.getFieldProps('email')}
                                placeholder='Email'
                              />
                            </Form.Item>
                          </div>
                          <div className={styles.first}>
                            <span className={styles.first_text}>Password</span>
                            <Form.Item
                              style={{ marginBottom: '0px', marginTop: '0px' }}
                              name='password'
                              rules={[
                                {
                                  required: true,
                                  message: 'Password is required',
                                },
                                {
                                  min: 8,
                                  message: 'Invalid credentials',
                                },
                              ]}>
                              <Input.Password
                                // style={{ height: 40, color: 'rgba(255,255,255)', borderRadius: '10px' }}
                                size={size}
                                {...formik.getFieldProps('password')}
                                placeholder='Password'
                                className='password'
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <Button
                          type='primary'
                          style={{
                            width: '80%',
                            height: size === 'small' ? '30px' : '40px',
                            alignSelf: 'center',
                            marginTop: '15px',
                          }}
                          htmlType='submit'
                          loading={loading}>
                          Continue
                        </Button>
                      </Form>
                    </div>
                    <div className={styles.details}>
                      <span className={styles.term}>
                        By Signing up, you agree to our{' '}
                        <span className={styles.underline} onClick={handleTerms}>
                          Terms
                        </span>{' '}
                        &{' '}
                        <span className={styles.underline} onClick={handlePrivacyPolicy}>
                          Privacy Policy.
                        </span>
                      </span>
                      <span className={styles.already}>
                        Already have an account?{' '}
                        <span className={styles.log} onClick={handleLoginNavigate}>
                          Log In
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.right}>
                <img src={rightSection} className={styles.rightImage} alt='rightSection' />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SignUpPage
