import { Tooltip } from 'antd'
import styles from './Where.module.scss'
import { useAppSelector } from 'state'

interface CompanyDomainProps {
  domain: string
  setDomain: (value: string) => void
}

export default function CompanyDomain({ domain, setDomain }: CompanyDomainProps) {
  const { settings } = useAppSelector((state) => state.signup)
  const domains: any = settings?.company_domain || []

  const clickedStyle = {
    borderRadius: ' 10px',
    background: ' #faf9ff',
    border: '1px solid #7144FF',
  }

  return (
    <div className={styles.frame}>
      <div className={styles.frame_title}>
        <span className={styles.text}>What describes best your company?</span>
      </div>
      <div className={styles.places}>
        {domains.map((place: any) => (
          <div
            className={styles.friend}
            style={{ cursor: 'pointer', ...(domain === place.value ? clickedStyle : {}) }}
            onClick={() => setDomain(place.value)}>
            <Tooltip title={place.label}>
              <span className={styles.text}>{place.label}</span>
            </Tooltip>
          </div>
        ))}
      </div>
    </div>
  )
}
