import { Divider, Modal } from 'antd'
import ReactFlow, { Background, ReactFlowProvider } from 'reactflow'
import InitialNode from '../../Builder/UI/InitialNode'
import PlaceholderNode from '../../Builder/UI/PlaceholderNode'
import CustomCollapseNode from '../../Canvas/CustomCollapseNode'
import CustomNode from '../../Canvas/CustomNode'
import { filterPlaceholderNodesAndEdges, getLayoutedElements } from '../../Builder/Utils/BuilderUtils'
import { FalseEdge, TrueEdge } from '../../Canvas/CustomEdge'

interface TemplatePreviewProps {
  open: boolean
  onModalClose: () => void
  template: any
}

export default function TemplatePreview({ open, onModalClose, template }: TemplatePreviewProps) {
  const edges = template?.template?.edges
  const nodes = template?.template?.nodes
  const nodeTypes: any = {
    // customNode: CustomNode,
    customNode: (props: any) => <CustomNode noRedOutline={true} {...props} />,
    initialNode: InitialNode,
    placeholderNode: () => <PlaceholderNode hideTitle={true} />,
    customCollapseNode: CustomCollapseNode,
  }
  const edgeTypes: any = { trueEdge: TrueEdge, falseEdge: FalseEdge }
  const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(nodes, edges)
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)

  return (
    <Modal
      open={open}
      onCancel={onModalClose}
      footer={false}
      centered
      width={500}
      bodyStyle={{
        height: 600,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: 20,
      }}
      className='template-preview-modal'>
      <div
        style={{
          fontSize: '20px',
          alignSelf: 'flex-start',
          marginLeft: 20,
          paddingBottom: 20,
          fontWeight: 600,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <span>Preview Template:</span>&nbsp;<span style={{ fontWeight: 500 }}>{template?.name}</span>
      </div>
      <Divider style={{ padding: 0, margin: 0 }} />
      <div
        style={{
          width: '100%',
          height: '90%',
        }}>
        <ReactFlowProvider>
          <ReactFlow
            className={nodes[0]?.type === 'initialNode' ? 'flowBuilder' : ''}
            edges={layoutedEdges}
            nodes={layoutedNodes}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            elementsSelectable={false}
            nodesDraggable={false}
            panOnDrag={true}
            fitView
            style={{ width: '100%', height: '100%', overflow: 'hidden' }}
            defaultViewport={{
              x: window.innerWidth * 0.15,
              y: window.innerHeight * 0.15,
              zoom: 1,
            }}
            proOptions={{ hideAttribution: true }}>
            <Background />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </Modal>
  )
}
