import { Node } from 'reactflow'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { filterPlaceholderNodesAndEdges, getLayoutedElements } from '../Builder/Utils/BuilderUtils'

interface CustomNodeData {
  type: string
  subType?: string
  headerText?: string
  stepsAmount?: number
  isExpand?: boolean
  message_id?: any
  children?: any
  defaultAmount?: any
  parentId?: any
}

interface CustomNode extends Node<CustomNodeData> {}

interface NodesEdges {
  nodes: CustomNode[]
  edges: any
}
const mapPlatformToInteger = (id: number, activePlatform: any): number => {
  switch (activePlatform) {
    case 'instagram':
      // if (id === 1) return 1 // Warm Up Prospects - Instagram
      // if (id === 2) return 2 // Smart Engagement - Instagram
      if (id === 3) return 3 // Cold Outreach - Instagram
      return 3
    case 'linkedin':
      // if (id === 1) return 4 // Warm Up Prospects - LinkedIn
      // if (id === 2) return 5 // Smart Engagement - LinkedIn
      if (id === 3) return 6 // Cold Outreach - LinkedIn
      return 6

    case 'facebook':
      // if (id === 1) return 7 // Warm Up Prospects - Facebook
      // if (id === 2) return 8 // Smart Engagement - Facebook
      if (id === 3) return 9 // Cold Outreach - Facebook
      return 9

    default:
      break
  }
  return -1
}

const idToTemplate = (id: number, activePlatform: any, defaultTemplates: any, type: any): any => {
  const platform = activePlatform?.[0] // Change when switch to multi-campaign
  if (type === 'general') {
    const coldOut = defaultTemplates?.filter((temp: any) => temp.prompt_type === 'quick_start')
    return coldOut?.filter((temp: any) => temp?.platforms?.includes(platform))?.[0]
  }
  switch (id) {
    case 1:
      // Warm Up Prospects
      const warmUp = defaultTemplates?.filter((temp: any) => temp.prompt_type === 'warm_up_prospects')
      return warmUp?.filter((temp: any) => temp?.platforms?.includes(platform))?.[0]

    case 2:
      // Smart Engagement
      const smartEng = defaultTemplates?.filter((temp: any) => temp.prompt_type === 'smart_engagement')
      return smartEng?.filter((temp: any) => temp?.platforms?.includes(platform))?.[0]
    case 3:
      // Cold Outreach
      const coldOut = defaultTemplates?.filter((temp: any) => temp.prompt_type === 'cold_outreach')
      return coldOut?.filter((temp: any) => temp?.platforms?.includes(platform))?.[0]
  }
}

const replaceMessageIds = (nodes: any) => {
  return nodes.map((node: any) => {
    const newNode = _.cloneDeep(node)
    if (newNode.data && newNode.data.message_id && Array.isArray(newNode.data.message_id)) {
      newNode.data.message_id = newNode.data.message_id.map(() => uuidv4())
    }
    return newNode
  })
}

export const getNodesAndEdges = (
  id: number,
  active_platforms: any,
  select_custom_template: any,
  builder_data?: any,
  custom_prompt?: boolean,
  defaultTemplates?: any,
  ignorePlaceholders?: boolean,
  type?: any,
): NodesEdges => {
  // promptTitle === 'Custom' && !use_custom_flow

  // if (!id) return { nodes: [], edges: [] }

  if (builder_data?.nodes?.length > 0) {
    const { nodes, edges } = builder_data
    // return { nodes, edges }
    if (ignorePlaceholders) {
      const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(nodes, edges)
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
      return {
        nodes: layoutedNodes,
        edges: layoutedEdges,
      }
    } else {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, true)
      return { nodes: layoutedNodes, edges: layoutedEdges }
    }
  }

  if (select_custom_template) {
    const template = select_custom_template.template
    const nodes = template?.nodes || []
    // const updatedNodes = replaceMessageIds(nodes)
    const edges = template?.edges || []
    if (ignorePlaceholders) {
      const { filteredNodes, filteredEdges } = filterPlaceholderNodesAndEdges(nodes, edges)
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(filteredNodes, filteredEdges, true)
      return {
        nodes: layoutedNodes,
        edges: layoutedEdges,
      }
    } else {
      return {
        nodes: nodes,
        edges: edges,
      }
    }
  }

  if (custom_prompt) {
    // Custom prompt but no nodes/edges were defined
    return {
      nodes: [],
      edges: [],
    }
  }

  const activePlatform = Object.entries(active_platforms)
    .filter(([_, value]) => value === true)
    .map(([key, _]) => key)

  const defaultTemp = idToTemplate(id, activePlatform, defaultTemplates, type)

  if (defaultTemp) {
    const nodes = defaultTemp?.template?.nodes || []
    const updatedNodes = replaceMessageIds(nodes)
    const edges = defaultTemp?.template?.edges || []
    return {
      nodes: updatedNodes,
      edges: edges,
    }
  }

  const newId = mapPlatformToInteger(id, activePlatform[0])

  switch (newId) {
    case 1:
      return {
        nodes: [
          {
            id: '1',
            data: { type: 'follow_lead' },
            position: { x: 0, y: 0 },
            type: 'customNode',
          },
          {
            id: '2',
            data: { type: 'lead_accepted' },
            position: { x: 0, y: 125 },
            type: 'customNode',
          },
          {
            id: '3_1',
            data: { type: 'unfollow_lead' },
            position: { x: -150, y: 250 },
            type: 'customNode',
          },
          {
            id: '3_2',
            data: { type: 'like_post' },
            position: { x: 150, y: 250 },
            type: 'customNode',
          },
          {
            id: '4_1',
            data: { type: 'is_wait' },
            position: { x: -150, y: 375 },
            type: 'customNode',
          },
          {
            id: '4_2',
            data: { type: 'is_wait' },
            position: { x: 150, y: 375 },
            type: 'customNode',
          },
          {
            id: '5_1',
            data: { type: 'follow_lead' },
            position: { x: -150, y: 500 },
            type: 'customNode',
          },
          {
            id: '5_2',
            data: { type: 'like_post' },
            position: { x: 150, y: 500 },
            type: 'customNode',
          },
          {
            id: '6_2',
            data: { type: 'is_wait' },
            position: { x: 150, y: 625 },
            type: 'customNode',
          },
          {
            id: '7_2',
            data: { type: 'like_post' },
            position: { x: 150, y: 750 },
            type: 'customNode',
          },
        ],
        edges: [
          { id: '1-2', source: '1', target: '2', type: 'smoothstep' },
          { id: '2-3_1', source: '2', target: '3_1', type: 'smoothstep', label: 'False' },
          { id: '2-3_2', source: '2', target: '3_2', type: 'smoothstep', label: 'True' },
          { id: '3_1-4_1', source: '3_1', target: '4_1', type: 'smoothstep' },
          { id: '4_1-5_1', source: '4_1', target: '5_1', type: 'smoothstep' },
          { id: '3_2-4_2', source: '3_2', target: '4_2', type: 'smoothstep' },
          { id: '4_2-5_2', source: '4_2', target: '5_2', type: 'smoothstep' },
          { id: '5_2-6_2', source: '5_2', target: '6_2', type: 'smoothstep' },
          { id: '6_2-7_2', source: '6_2', target: '7_2', type: 'smoothstep' },
        ],
      }
    case 2:
      return {
        nodes: [
          {
            id: '1',
            data: { type: 'follow_lead' },
            position: { x: 0, y: 0 },
            type: 'customNode',
          },
          {
            id: '2',
            data: { type: 'lead_accepted' },
            position: { x: 0, y: 125 },
            type: 'customNode',
          },
          {
            id: '3_1', // left True
            data: { type: 'is_wait' },
            position: { x: -225, y: 250 },
            type: 'customNode',
          },
          {
            id: '4_1', // left side
            data: { type: 'comment_on_story' },
            position: { x: -225, y: 375 },
            type: 'customNode',
          },
          {
            id: '5_1', // left side
            data: { type: 'like_post' },
            position: { x: -225, y: 500 },
            type: 'customNode',
          },
          {
            id: '6_1', // left side
            data: { type: 'is_wait' },
            position: { x: -225, y: 625 },
            type: 'customNode',
          },
          {
            id: '7_1', // left side
            data: { type: 'like_post' },
            position: { x: -225, y: 750 },
            type: 'customNode',
          },
          {
            id: '8_1', // left side
            data: { type: 'follow_x_friends' },
            position: { x: -225, y: 875 },
            type: 'customNode',
          },
          {
            id: '9_1', // left side
            data: { type: 'send_message', subType: active_platforms },
            position: { x: -225, y: 1000 },
            type: 'customNode',
          },
          {
            id: '10_1', // left side
            data: { type: 'is_wait' },
            position: { x: -225, y: 1125 },
            type: 'customNode',
          },
          {
            id: '11_1', // left side
            data: { type: 'comment_on_post' },
            position: { x: -225, y: 1250 },
            type: 'customNode',
          },
          {
            id: '3_2', // right False
            data: { type: 'is_public' },
            position: { x: 225, y: 250 },
            type: 'customNode',
          },
          {
            id: '4_2_2', // right right
            data: { type: 'send_message', subType: active_platforms },
            position: { x: 375, y: 375 },
            type: 'customNode',
          },
          {
            id: '5_2_2', // right right
            data: { type: 'is_wait' },
            position: { x: 375, y: 500 },
            type: 'customNode',
          },
          {
            id: '6_2_2', // right right
            data: { type: 'unfollow_lead' },
            position: { x: 375, y: 625 },
            type: 'customNode',
          },
          {
            id: '4_2_1', // right left
            data: { type: 'comment_on_story' },
            position: { x: 75, y: 375 },
            type: 'customNode',
          },
          {
            id: '5_2_1', // right left
            data: { type: 'comment_on_post' },
            position: { x: 75, y: 500 },
            type: 'customNode',
          },
          {
            id: '6_2_1', // right left
            data: { type: 'is_wait' },
            position: { x: 75, y: 625 },
            type: 'customNode',
          },
          {
            id: '7_2_1', // right left
            data: { type: 'like_post' },
            position: { x: 75, y: 750 },
            type: 'customNode',
          },
          {
            id: '8_2_1', // right left
            data: { type: 'is_wait' },
            position: { x: 75, y: 875 },
            type: 'customNode',
          },
          {
            id: '9_2_1', // right left
            data: { type: 'send_message', subType: active_platforms },
            position: { x: 75, y: 1000 },
            type: 'customNode',
          },
          {
            id: '10_2_1', // right left
            data: { type: 'comment_on_post' },
            position: { x: 75, y: 1125 },
            type: 'customNode',
          },
        ],
        edges: [
          { id: '1-2', source: '1', target: '2', type: 'smoothstep' },
          { id: '2-3', source: '2', target: '3', type: 'smoothstep' },
          { id: '2-3_1', source: '2', target: '3_1', type: 'smoothstep', label: 'True' },
          { id: '2-3_2', source: '2', target: '3_2', type: 'smoothstep', label: 'False' },
          { id: '3_1-4_1', source: '3_1', target: '4_1', type: 'smoothstep' },
          { id: '4_1-5_1', source: '4_1', target: '5_1', type: 'smoothstep' },
          { id: '5_1-6_1', source: '5_1', target: '6_1', type: 'smoothstep' },
          { id: '6_1-7_1', source: '6_1', target: '7_1', type: 'smoothstep' },
          { id: '7_1-8_1', source: '7_1', target: '8_1', type: 'smoothstep' },
          { id: '8_1-9_1', source: '8_1', target: '9_1', type: 'smoothstep' },
          { id: '9_1-10_1', source: '9_1', target: '10_1', type: 'smoothstep' },
          { id: '10_1-11_1', source: '10_1', target: '11_1', type: 'smoothstep' },
          { id: '3_2-4_2_1', source: '3_2', target: '4_2_1', type: 'smoothstep', label: 'True' },
          { id: '3_2-4_2_2', source: '3_2', target: '4_2_2', type: 'smoothstep', label: 'False' },
          { id: '4_2_1-5_2_1', source: '4_2_1', target: '5_2_1', type: 'smoothstep' },
          { id: '5_2_1-6_2_1', source: '5_2_1', target: '6_2_1', type: 'smoothstep' },
          { id: '6_2_1-7_2_1', source: '6_2_1', target: '7_2_1', type: 'smoothstep' },
          { id: '7_2_1-8_2_1', source: '7_2_1', target: '8_2_1', type: 'smoothstep' },
          { id: '8_2_1-9_2_1', source: '8_2_1', target: '9_2_1', type: 'smoothstep' },
          { id: '9_2_1-10_2_1', source: '9_2_1', target: '10_2_1', type: 'smoothstep' },
          { id: '4_2_2-5_2_2', source: '4_2_2', target: '5_2_2', type: 'smoothstep' },
          { id: '5_2_2-6_2_2', source: '5_2_2', target: '6_2_2', type: 'smoothstep' },
          { id: '6_2_2-1', source: '6_2_2', target: '1', type: 'smoothstep' },
        ],
      }
    case 3: // Cold Outreach - Instagram
      return {
        nodes: [
          {
            id: '1',
            data: { type: 'follow_lead', message_id: [uuidv4()], children: ['2'] },
            // data: { type: 'follow_lead', isExpand: false, headerText: 'Initial Engagement Phase', stepsAmount: 10 },
            position: { x: 0, y: 0 },
            type: 'customNode',
            // child: ''
          },
          {
            id: '2',
            data: { type: 'lead_accepted', message_id: [uuidv4()], children: ['3_1', '3_2'] },
            position: { x: 0, y: 125 },
            type: 'customNode',
            // parentNode: '1',
          },
          {
            id: '3_1',
            data: { type: 'unfollow_lead', message_id: [uuidv4()], children: [] },
            position: { x: -150, y: 250 },
            // position: { x: -150, y: 375 },
            type: 'customNode',
            // parentNode: '2',
          },
          {
            id: '3_2',
            data: { type: 'like_post', message_id: [uuidv4()], children: ['4_2'] },
            position: { x: 150, y: 250 },
            type: 'customNode',
            // parentNode: '2',
          },

          {
            id: '4_2',
            data: { type: 'is_wait', message_id: [uuidv4()], children: ['5_2'] },
            position: { x: 150, y: 375 },
            type: 'customNode',
            // parentNode: '3_2',
          },

          {
            id: '5_2',
            data: {
              type: 'send_message',
              subType: active_platforms,
              message_id: [uuidv4(), uuidv4(), uuidv4()],
              children: ['6_2'],
            },
            position: { x: 150, y: 500 },
            type: 'customNode',
            // parentNode: '4_2',
          },

          {
            id: '6_2',
            data: { type: 'is_message_replied', message_id: [uuidv4()], children: ['7_2'] },
            position: { x: 150, y: 625 },
            type: 'customNode',
            // parentNode: '5_2',
          },

          {
            id: '7_2',
            data: { type: 'like_post', message_id: [uuidv4()], children: ['8_2'] },
            position: { x: 150, y: 750 },
            type: 'customNode',
            // parentNode: '6_2',
          },

          {
            id: '8_2',
            // data: { type: 'is_wait', message_id: [uuidv4()], children: ['9_2'] },
            data: { type: 'is_message_replied', message_id: [uuidv4()], children: ['9_2'] },
            position: { x: 150, y: 875 },
            type: 'customNode',
            // parentNode: '7_2',
          },
          {
            id: '9_2',
            data: {
              type: 'unfollow_lead',
              message_id: [uuidv4()],
              children: [],
            },
            position: { x: 150, y: 1000 },
            type: 'customNode',
            // parentNode: '8_2',
          },

          // {
          //   id: '10_2',
          //   data: { type: 'is_wait' },
          //   position: { x: 150, y: 1125 },
          //   type: 'customNode',
          // },

          // {
          //   id: '11_2',
          //   data: { type: 'comment_on_story' },
          //   position: { x: 150, y: 1250 },
          //   type: 'customNode',
          // },
        ],
        edges: [
          { id: '1-2', source: '1', target: '2', type: 'smoothstep' },
          { id: '2-3_1', source: '2', target: '3_1', type: 'smoothstep', label: 'False' },
          { id: '2-3_2', source: '2', target: '3_2', type: 'smoothstep', label: 'True' },
          // { id: '3_1-4_1', source: '3_1', target: '4_1', type: 'smoothstep' },
          { id: '4_1-5_1', source: '4_1', target: '5_1', type: 'smoothstep' },
          { id: '3_2-4_2', source: '3_2', target: '4_2', type: 'smoothstep' },
          { id: '4_2-5_2', source: '4_2', target: '5_2', type: 'smoothstep' },
          { id: '5_2-6_2', source: '5_2', target: '6_2', type: 'smoothstep' },
          { id: '6_2-7_2', source: '6_2', target: '7_2', type: 'smoothstep', label: 'False' },
          { id: '7_2-8_2', source: '7_2', target: '8_2', type: 'smoothstep' },
          { id: '8_2-9_2', source: '8_2', target: '9_2', type: 'smoothstep' },
          // { id: '9_2-10_2', source: '9_2', target: '10_2', type: 'smoothstep' },
          // { id: '10_2-11_2', source: '10_2', target: '11_2', type: 'smoothstep' },
        ],
      }
    case 6: // Cold Outreach - LinkedIn
      return {
        nodes: [
          {
            id: '1',
            data: { type: 'connect_lead', message_id: [uuidv4()], children: ['2'] },
            position: { x: 0, y: 0 },
            type: 'customNode',
            // child: ''
          },
          {
            id: '2',
            data: { type: 'lead_accepted', message_id: [uuidv4()], children: ['3_1', '3_2'] },
            position: { x: 0, y: 125 },
            type: 'customNode',
            // parentNode: '1',
          },
          {
            id: '3_1',
            data: { type: 'follow_lead', message_id: [uuidv4()], children: ['4_1'] },
            position: { x: -150, y: 250 },
            type: 'customNode',
          },
          {
            id: '4_1',
            data: { type: 'is_wait', message_id: [uuidv4()], children: ['4_1'] },
            position: { x: -150, y: 375 },
            type: 'customNode',
          },
          {
            id: '5_1',
            data: { type: 'like_post', message_id: [uuidv4()], children: ['6_1'] },
            position: { x: -150, y: 500 },
            type: 'customNode',
          },
          {
            id: '6_1',
            data: { type: 'lead_accepted', message_id: [uuidv4()], children: ['7_1'] },
            position: { x: -150, y: 625 },
            type: 'customNode',
          },
          {
            id: '7_1',
            data: { type: 'send_message', message_id: [uuidv4(), uuidv4(), uuidv4()], children: [] },
            position: { x: -150, y: 750 },
            type: 'customNode',
          },

          {
            id: '3_2',
            data: { type: 'like_post', message_id: [uuidv4()], children: ['4_2'] },
            position: { x: 150, y: 250 },
            type: 'customNode',
            // parentNode: '2',
          },

          {
            id: '4_2',
            data: { type: 'is_wait', message_id: [uuidv4()], children: ['5_2'] },
            position: { x: 150, y: 375 },
            type: 'customNode',
            // parentNode: '3_2',
          },

          {
            id: '5_2',
            data: {
              type: 'send_message',
              subType: active_platforms,
              message_id: [uuidv4(), uuidv4(), uuidv4()],
              children: ['6_2'],
            },
            position: { x: 150, y: 500 },
            type: 'customNode',
            // parentNode: '4_2',
          },

          {
            id: '6_2',
            data: { type: 'is_message_replied', message_id: [uuidv4()], children: ['7_2'] },
            position: { x: 150, y: 625 },
            type: 'customNode',
            // parentNode: '5_2',
          },

          {
            id: '7_2',
            data: { type: 'send_message', message_id: [uuidv4(), uuidv4(), uuidv4()], children: ['8_2'] },
            position: { x: 150, y: 750 },
            type: 'customNode',
            // parentNode: '6_2',
          },
          // {
          //   id: '10_2',
          //   data: { type: 'is_wait' },
          //   position: { x: 150, y: 1125 },
          //   type: 'customNode',
          // },

          // {
          //   id: '11_2',
          //   data: { type: 'comment_on_story' },
          //   position: { x: 150, y: 1250 },
          //   type: 'customNode',
          // },
        ],
        edges: [
          { id: '1-2', source: '1', target: '2', type: 'smoothstep' },
          { id: '2-3_1', source: '2', target: '3_1', type: 'smoothstep', label: 'False' },
          { id: '2-3_2', source: '2', target: '3_2', type: 'smoothstep', label: 'True' },
          { id: '3_1-4_1', source: '3_1', target: '4_1', type: 'smoothstep' },
          { id: '4_1-5_1', source: '4_1', target: '5_1', type: 'smoothstep' },
          { id: '5_1-6_1', source: '5_1', target: '6_1', type: 'smoothstep' },
          { id: '6_1-7_1', source: '6_1', target: '7_1', type: 'smoothstep', label: 'True' },
          { id: '3_2-4_2', source: '3_2', target: '4_2', type: 'smoothstep' },
          { id: '4_2-5_2', source: '4_2', target: '5_2', type: 'smoothstep' },
          { id: '5_2-6_2', source: '5_2', target: '6_2', type: 'smoothstep' },
          { id: '6_2-7_2', source: '6_2', target: '7_2', type: 'smoothstep', label: 'False' },
          { id: '7_2-8_2', source: '7_2', target: '8_2', type: 'smoothstep' },
          { id: '8_2-9_2', source: '8_2', target: '9_2', type: 'smoothstep' },
          // { id: '9_2-10_2', source: '9_2', target: '10_2', type: 'smoothstep' },
          // { id: '10_2-11_2', source: '10_2', target: '11_2', type: 'smoothstep' },
        ],
      }
    case 9: // Cold Outreach - Facebook
      return {
        nodes: [
          {
            id: '1',
            data: { type: 'follow_lead', message_id: [uuidv4()], children: ['2'] },
            position: { x: 0, y: 0 },
            type: 'customNode',
          },
          {
            id: '2',
            data: { type: 'lead_accepted', message_id: [uuidv4()], children: ['3_1', '3_2'] }, // add 7 days
            position: { x: 0, y: 125 },
            type: 'customNode',
          },
          // {
          //   id: '3_1',
          //   data: { type: 'follow_lead', message_id: [uuidv4()], children: ['4_1'] },
          //   position: { x: -150, y: 250 },
          //   type: 'customNode',
          // },
          {
            id: '3_2',
            data: { type: 'like_post', message_id: [uuidv4()], children: ['4_2'] },
            position: { x: 150, y: 250 },
            type: 'customNode',
            // parentNode: '2',
          },

          {
            id: '4_2',
            data: { type: 'is_wait', message_id: [uuidv4()], children: ['5_2'] },
            position: { x: 150, y: 375 },
            type: 'customNode',
            // parentNode: '3_2',
          },

          {
            id: '5_2',
            data: {
              type: 'send_message',
              subType: active_platforms,
              message_id: [uuidv4(), uuidv4(), uuidv4()],
              children: ['6_2'],
            },
            position: { x: 150, y: 500 },
            type: 'customNode',
            // parentNode: '4_2',
          },
        ],
        edges: [
          { id: '1-2', source: '1', target: '2', type: 'smoothstep' },
          // { id: '2-3_1', source: '2', target: '3_1', type: 'smoothstep', label: 'False' },
          { id: '2-3_2', source: '2', target: '3_2', type: 'smoothstep', label: 'True' },
          // { id: '3_1-4_1', source: '3_1', target: '4_1', type: 'smoothstep' },
          // { id: '4_1-5_1', source: '4_1', target: '5_1', type: 'smoothstep' },
          // { id: '5_1-6_1', source: '5_1', target: '6_1', type: 'smoothstep' },
          // { id: '6_1-7_1', source: '6_1', target: '7_1', type: 'smoothstep', label: 'True' },
          { id: '3_2-4_2', source: '3_2', target: '4_2', type: 'smoothstep' },
          { id: '4_2-5_2', source: '4_2', target: '5_2', type: 'smoothstep' },
          // { id: '5_2-6_2', source: '5_2', target: '6_2', type: 'smoothstep' },
          // { id: '6_2-7_2', source: '6_2', target: '7_2', type: 'smoothstep', label: 'False' },
          // { id: '7_2-8_2', source: '7_2', target: '8_2', type: 'smoothstep' },
          // { id: '8_2-9_2', source: '8_2', target: '9_2', type: 'smoothstep' },
        ],
      }
    default:
      return {
        nodes: [],
        edges: [],
      }
  }
}
