import { createSlice } from '@reduxjs/toolkit'

interface IGeneralSlice {
  status: string
  force_pricing: any
  outOfOutreach: boolean
  isMobile: boolean
  justSignedUp: boolean
  emailToken: string
}

const initialState = {
  status: '',
  force_pricing: false,
  outOfOutreach: false,
  isMobile: false,
  justSignedUp: false,
  emailToken: '',
} as IGeneralSlice

export const GeneralSlice = createSlice({
  name: 'General',
  initialState,
  reducers: {
    SET_FORCE_PRICING(state, action) {
      state.status = 'succeeded'
      state.force_pricing = action.payload
    },
    SET_OUT_OF_OUTREACH(state, action) {
      state.status = 'succeeded'
      state.outOfOutreach = action.payload
    },
    RESET_GENERAL(state) {
      return initialState
    },
    SET_IS_MOBILE(state, action) {
      state.isMobile = action.payload
    },
    SET_JUST_SIGNED_UP(state, action) {
      state.justSignedUp = action.payload
    },
    SET_EMAIL_TOKEN(state, action) {
      state.emailToken = action.payload
    },
  },
})

export const {
  SET_FORCE_PRICING,
  SET_OUT_OF_OUTREACH,
  RESET_GENERAL,
  SET_IS_MOBILE,
  SET_JUST_SIGNED_UP,
  SET_EMAIL_TOKEN,
} = GeneralSlice.actions
export const GeneralReducer = GeneralSlice.reducer
