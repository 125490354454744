import React, { useEffect, useState } from 'react'
import { Empty, Table, Tooltip } from 'antd'
// import { CheckCircleOutlined } from '@ant-design/icons'
import styles from './MessagesTable.module.scss'
import CustomTag from '../Messages/CustomTag'
import UsernameFromNumber from '../Campaigns/UI/UsernameFromNumber'
import LoadingSkeleton from 'common/components/LoadingBox/LoadingSkeleton'
import CustomLastStepTag from '../Campaigns/UI/CustomLastStepTag'
import CustomSumTag from '../Messages/CustomSumTag'

interface MainMessagesTableProps {
  data: Array<{
    key: React.Key
    message: string
    sent: number
    replied: number
    blocked: number
    meetings: number
    campaign: string
    step: string
    sent_from: string
    total_recipient: Number
  }>
  isLoading: any
}

const MainMessagesTable: React.FC<MainMessagesTableProps> = ({ data, isLoading }) => {
  const [yAxisTableSize, setYAxisTableSize] = useState<any>()
  const columns = [
    {
      title: 'Message',
      dataIndex: 'message',
      width: 400,
      key: 'message',
      sorter: (a: any, b: any) => a.message.localeCompare(b.message),
      render: (message: string) => (
        <div style={{ color: '#5E6C84' }}>
          {/* <span>{message}</span> */}
          {message.length > 50 ? (
            <Tooltip title={message} placement='top'>
              <span>{`${message.slice(0, 50)}...`}</span>
            </Tooltip>
          ) : (
            <span>{message}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Sent',
      dataIndex: 'sent',
      width: 150,
      key: 'sent',
      sorter: (a: any, b: any) => a.sent - b.sent,
      render: (sent: string, data: any) => {
        return (
          <div style={{ color: '#5E6C84' }}>
            <CustomTag
              tag='sent'
              number={Number(sent)}
              percentage={data.total_recipient === 0 ? 0 : Math.trunc((Number(sent) / data.total_recipient) * 100)}
            />
          </div>
        )
      },
    },
    {
      title: 'Replied',
      dataIndex: 'replied',
      width: 150,
      key: 'replied',
      sorter: (a: any, b: any) => a.replied - b.replied,
      render: (replied: string, data: any) => {
        return (
          <div style={{ color: '#5E6C84' }}>
            <CustomTag
              tag='replied'
              number={Number(replied)}
              percentage={Number(data.sent) === 0 ? 0 : Math.trunc((Number(replied) / Number(data.sent)) * 100)}
            />
          </div>
        )
      },
    },
    // {
    //   title: 'Meetings',
    //   dataIndex: 'meetings',
    //   width: 150,
    //   key: 'meetings',
    //   render: (meetings: string, data: any) => (
    //     <div style={{ color: '#5E6C84' }}>
    //       <CustomTag
    //         tag='meetings'
    //         number={Number(meetings)}
    //         // percentage={Number(meetings)}
    //         percentage={data.total_recipient === 0 ? 0 : Math.trunc((Number(meetings) / data.total_recipient) * 100)}
    //       />
    //     </div>
    //   ),
    // },
    {
      title: 'Blocked',
      dataIndex: 'blocked',
      width: 150,
      key: 'blocked',
      sorter: (a: any, b: any) => a.blocked - b.blocked,
      render: (blocked: string, data: any) => {
        return (
          <div style={{ color: '#5E6C84' }}>
            <CustomTag
              tag='blocked'
              number={Number(blocked)}
              percentage={data.total_recipient === 0 ? 0 : Math.trunc((Number(blocked) / data.total_recipient) * 100)}
            />
          </div>
        )
      },
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign_name',
      width: 300,
      key: 'campaign_name',
      sorter: (a: any, b: any) => a.campaign_name.localeCompare(b.campaign_name),
      // render: (campaign: string) => <span>{campaign}</span>,
      render: (campaign_name: string, data: any) => {
        return (
          <Tooltip title={campaign_name}>
            <div className={styles.truncatedText} style={{ maxWidth: '260px' }}>
              <span>{campaign_name}</span>
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: 'Step',
      dataIndex: 'step_type',
      // dataIndex: 'message_type',
      width: 225,
      key: 'step_type',
      sorter: (a: any, b: any) => a.step_type.localeCompare(b.step_type),
      // key: 'message_type',
      // render: (step: string) => <Typography.Text>{step}</Typography.Text>,
      render: (step_type: any, data: any) => {
        return step_type ? <CustomLastStepTag tag={step_type} message_level={data.message_level} /> : '-'
      },
      // render: (step: any) => {
      //   return step ? <CustomLastStepTag tag={step} /> : '-'
      // },
    },
    {
      title: 'Sent From',
      dataIndex: 'sent_from',
      width: 230,
      key: 'sent_from',
      sorter: (a: any, b: any) => (a.sent_from || '').localeCompare(b.sent_from || ''),
      render: (sent_from: string) => (
        <div>
          {sent_from !== 'undefined' && sent_from !== null ? <UsernameFromNumber phoneNumbers={[sent_from]} /> : '-'}
        </div>
      ),
    },
  ]

  useEffect(() => {
    // RESIZE TABLE
    const updateYAxisTableSize = () => {
      const cooldownBannerElement = document.querySelector('[class^="CooldownBanner"]')?.clientHeight || 0
      const scheduleBannerElement = document.querySelector('[class^="CampaignScheduleStatusBanner"]')?.clientHeight || 0
      const statusBannerElement = document.querySelector('[class^="StautsBanner"]')?.clientHeight || 0
      const filterElement = document.querySelector('[class^="CampaignFilters"]')?.clientHeight || 0

      let newSize =
        window.innerHeight -
        200 -
        170 -
        cooldownBannerElement -
        scheduleBannerElement -
        statusBannerElement -
        (filterElement + 10)
      if (newSize < 0.2 * window.innerHeight) {
        newSize = 0.2 * window.innerHeight
      }
      setYAxisTableSize(newSize)
    }
    updateYAxisTableSize()
    window.addEventListener('resize', updateYAxisTableSize)
    return () => {
      window.removeEventListener('resize', updateYAxisTableSize)
    }
  }, [window.innerHeight, window.innerWidth])

  return (
    <Table
      // loading={!isLoading}
      // loading={{ spinning: !isLoading, indicator: <Skeleton active /> }}
      locale={{
        // emptyText: isLoading ? <Skeleton active={true} paragraph={{ rows: 10 }} style={{ opacity: 0.5 }} /> : <Empty />,
        emptyText: isLoading ? (
          <LoadingSkeleton
            listStyle={{ margin: '-16px' }}
            listItemStyle={{ padding: '34px 0px 34px 0px' }}
            skeletonStyle={{
              paddingTop: '0px',
              paddingLeft: '10px',
              paddingRight: '10px',
              display: 'flex',
              alignItems: 'center',
              opacity: 0.5,
            }}
            avatar={false}
          />
        ) : (
          <Empty />
        ),
      }}
      dataSource={data}
      columns={columns}
      // className={'messagesCampaignTable'} // in global.css
      pagination={{ pageSize: 6 }}
      //   style={{ height: '1000px' }}
      scroll={{ y: yAxisTableSize }}
      summary={() => {
        // let totalProspects = 0
        let totalSent = 0
        let totalReplied = 0
        let totalBlocked = 0
        // let totalMeetings = 0
        let totalRecipient = 0

        data.forEach(({ sent, replied, blocked, meetings, total_recipient }) => {
          totalSent += Number(sent)
          totalReplied += Number(replied)
          // totalMeetings += Number(meetings)
          totalBlocked += Number(blocked)
          totalRecipient += Number(total_recipient)
        })

        return isLoading || data?.length === 0 ? null : (
          <Table.Summary.Row>
            <Table.Summary.Cell index={1}>
              <span style={{ fontWeight: 600 }}>Sum:</span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3}>
              <span style={{ fontWeight: 600 }}>
                {/* {totalSent} */}
                <CustomSumTag
                  tag='sent'
                  number={totalSent}
                  percentage={totalRecipient === 0 ? 0 : Math.trunc((Number(totalSent) / totalRecipient) * 100)}
                />
              </span>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={4}>
              <span style={{ fontWeight: 600 }}>
                {/* {totalReplied} */}
                <CustomSumTag
                  tag='replied'
                  number={totalReplied}
                  percentage={totalSent === 0 ? 0 : Math.trunc((Number(totalReplied) / totalSent) * 100)}
                />
              </span>
            </Table.Summary.Cell>
            {/* <Table.Summary.Cell index={6}>
              <span style={{ fontWeight: 600 }}>{totalMeetings}</span>
            </Table.Summary.Cell> */}
            <Table.Summary.Cell index={5}>
              <span style={{ fontWeight: 600 }}>
                {/* {totalBlocked} */}
                <CustomSumTag
                  tag='blocked'
                  number={totalBlocked}
                  percentage={totalRecipient === 0 ? 0 : Math.trunc((Number(totalBlocked) / totalRecipient) * 100)}
                />
              </span>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )
      }}
    />
  )
}

export default MainMessagesTable
