import { post } from 'common/api/axios'

const REQUIRED_KEYS = [
  'event_purpose',
  'trigger',
  'related_screen',
  'key_data_points',
  'expected_outcome',
  'notes',
] as const

interface ConvrtInternalDescription {
  event_purpose: string
  trigger: string
  related_screen: string
  key_data_points: string[]
  expected_outcome: string
  notes: string
}

interface LogEventPayload {
  name: string
  event_source: string
  convrt_internal_description: ConvrtInternalDescription
  data: any
}

const validateDescription = (
  description: Partial<ConvrtInternalDescription>,
): description is ConvrtInternalDescription => {
  // Check if every required key is present and non-empty
  return REQUIRED_KEYS.every((key) => {
    return (
      description[key as keyof ConvrtInternalDescription] !== undefined &&
      description[key as keyof ConvrtInternalDescription] !== ''
    )
  })
}

/**
 * Logs an event to the Mixpanel/CIO endpoint.
 * @param name - Name of the event.
 * @param event_source - The source where this event originated.
 * @param convrt_internal_description - Detailed JSON description of the event's purpose and context.
 */
export const logEvent = (
  name: string,
  event_source: string,
  convrt_internal_description: ConvrtInternalDescription,
  data?: any,
) => {
  // Validate the convrt_internal_description
  if (!validateDescription(convrt_internal_description)) {
    throw new Error(
      `Invalid convrt_internal_description. Please provide all required fields: ${REQUIRED_KEYS.join(', ')}`,
    )
  }

  // Prepare the payload for logging
  const payload: LogEventPayload = {
    name,
    event_source,
    convrt_internal_description,
    ...data,
  }

  // Log the event using the post function
  post('/log', {
    action: name,
    data: payload,
  })
}
