import { createSlice, current } from '@reduxjs/toolkit'
import { findIndex, some, uniqBy } from 'lodash'
// import dayjs from 'dayjs'

interface IChatSlice {
  status: string
  is_unify_finished: boolean
  unified_accounts: any[]
  selected_account: any
  selected_chat_recipient: any
  close_left_sidebar: boolean
  current_tags_dialog: any[]
  all_tags_dialog_data: any[]
  selected_chat_platform: string
  total_all_chat: number
  total_unread: number
  total_group: number
  priorities: { [recipientId: string]: string } // To prevent jumping when data is refetches
  pendingMessages: any
  reunify: boolean
}

const initialState = {
  status: '',
  is_unify_finished: false,
  total_unread: 0,
  total_all_chat: 0,
  total_group: 0,
  unified_accounts: [],
  selected_account: [],
  selected_chat_recipient: { title: '', id: '', senderData: '' },
  close_left_sidebar: false,
  current_tags_dialog: [],
  all_tags_dialog_data: [],
  selected_chat_platform: 'instagram',
  priorities: {}, // To prevent jumping when data is refetches,
  pendingMessages: [],
  reunify: false,
} as IChatSlice

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    SET_IS_UNIFY_FINISHED(state, action) {
      state.status = 'succeeded'
      state.is_unify_finished = action.payload
    },
    SET_UNREAD(state, action) {
      state.status = 'succeeded'
      state.total_unread = action.payload
    },
    SET_ALL_CHAT(state, action) {
      state.status = 'succeeded'
      state.total_all_chat = action.payload
    },
    SET_GROUP(state, action) {
      state.status = 'succeeded'
      state.total_group = action.payload
    },
    SET_UNIFIED_ACCOUNTS(state, action) {
      state.status = 'succeeded'
      state.unified_accounts = action.payload
    },
    SET_SELECTED_ACCOUNT(state, action) {
      state.status = 'succeeded'
      state.selected_account = action.payload
    },
    SET_SELECTED_CHAT_RECIPIENT(state, action) {
      state.status = 'succeeded'
      state.selected_chat_recipient = action.payload
    },
    SET_CLOSE_LEFT_SIDEBAR(state, action) {
      state.status = 'succeeded'
      state.close_left_sidebar = action.payload
    },

    SET_TAG_DIALOG(state, action) {
      if (action.payload) {
        const { id } = action.payload
        const currentListTagsDialog = state.current_tags_dialog

        const isDetailExist = some(currentListTagsDialog, { id })
        if (isDetailExist) {
          const newDetailList: any = [...currentListTagsDialog]
          const indexOfOldDetailRecord = findIndex(newDetailList, { id })

          //update old record
          newDetailList[indexOfOldDetailRecord] = action.payload

          return {
            ...state,
            current_tags_dialog: newDetailList,
          }
        }

        const currentState = uniqBy(current(currentListTagsDialog), 'id')
        return {
          ...state,
          current_tags_dialog: uniqBy([...currentState, action.payload], 'id'),
        }
      }
    },
    SET_ALL_TAG_DIALOG(state, action) {
      state.status = 'succeeded'
      state.all_tags_dialog_data = action.payload
    },

    SET_SELECTED_CHAT_PLATFORM(state, action) {
      state.status = 'succeeded'
      state.selected_chat_platform = action.payload
    },
    SET_PRIORITY(state, action) {
      // To prevent jumping when data is refetches
      const { recipientId, priority } = action.payload
      state.priorities[recipientId] = priority
    },
    SET_PENDING_MESSAGES(state, action) {
      state.status = 'succeeded'
      state.pendingMessages = action.payload
    },
    SET_REUNIFY(state, action) {
      state.status = 'succeeded'
      state.reunify = action.payload
    },
  },
})

export const {
  SET_IS_UNIFY_FINISHED,
  SET_UNIFIED_ACCOUNTS,
  SET_SELECTED_ACCOUNT,
  SET_SELECTED_CHAT_RECIPIENT,
  SET_CLOSE_LEFT_SIDEBAR,
  SET_TAG_DIALOG,
  SET_ALL_TAG_DIALOG,
  SET_SELECTED_CHAT_PLATFORM,
  SET_PRIORITY,
  SET_PENDING_MESSAGES,
  SET_REUNIFY,
  SET_UNREAD,
  SET_ALL_CHAT,
  SET_GROUP,
} = chatSlice.actions

export const chatSliceReducer = chatSlice.reducer
