import React, { useEffect, useState } from 'react'

import type { MenuProps } from 'antd'
import { Menu } from 'antd'

import settingsBlack from 'common/assets/svg/settingsBlack.svg'
import settingsWhite from 'common/assets/svg/settingsWhite.svg'
import { MenuServices } from 'common/services/menu-services'

import styles from './bottom-menu.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import ExitModal from '../OutreachCampaign/NewCampaign/UI/ExitModal'
import { useAppSelector } from 'state'
import { useLocalStorage } from 'usehooks-ts'
import CustomHover from '../top-menu/CustomHover'

interface IBottomMenu {
  collapsed: boolean
  callbackOnChange?: (value?: string) => void
  selectedKey: string
}
const BottomMenu: React.FC<IBottomMenu> = ({ collapsed, callbackOnChange, selectedKey }) => {
  const [localUser] = useLocalStorage<any>('user', null)
  const { isSubscriptionDaysPassed } = useAppSelector((state) => state.filters)
  const [blockNavigation, setBlockNavigation] = useState(false)

  const [openExitModal, setOpenExitModal] = useState(false)
  const navigate = useNavigate()
  const disableNavigationsRoutes = ['/outreach/create-new-campaign']
  const refreshAfterNavigation = ['/checkout/success']
  const [navigateTo, setNavigateTo] = useState('')
  type MenuItem = Required<MenuProps>['items'][number]
  const location = useLocation()
  const settings = location.pathname === '/settings' ? settingsBlack : settingsWhite
  // const settings = location.pathname === '/settings' ? settingsBlack : settingsWhite

  const items: MenuItem[] = [
    // MenuServices.getItem(!collapsed && 'Get Points', 'settings', <img src={getPointsWhite} alt={''} />),
    MenuServices.getItem(
      !collapsed && (
        <CustomHover
          navigate={() => {
            handleNavigation('settings')
          }}
          hkey={'settings'}
          title='Settings'
          body='Where you manage your account, subscription, and Convrt settings.'
          image={'https://storage.googleapis.com/convrt-media-store/Settings.png'}>
          <div style={{ width: '100%' }}>Settings</div>
        </CustomHover>
      ),
      'settings',
      <img src={settings} alt={''} />,
      blockNavigation,
    ),
  ]

  const handleNavigation = (value: any) => {
    const routeToNavigate = `/${value.key}`
    const currentRoute = window.location.pathname

    if (disableNavigationsRoutes.includes(currentRoute)) {
      setOpenExitModal(true)
      setNavigateTo(routeToNavigate)
    } else {
      callbackOnChange && callbackOnChange(value.key)
      navigate(routeToNavigate)
    }

    if (refreshAfterNavigation.includes(currentRoute)) {
      window.location.reload()
      navigate(routeToNavigate)
    }
  }

  useEffect(() => {
    if (localUser) {
      const { requires_onboarding, onboarding_status } = localUser?.addons
      if ((requires_onboarding && !onboarding_status) || isSubscriptionDaysPassed) {
        setBlockNavigation(true)
      } else {
        setBlockNavigation(false)
      }
    }
  }, [localUser])
  return (
    <>
      <Menu
        defaultSelectedKeys={[window.location.pathname.indexOf('settings') >= 0 ? 'settings' : '']}
        selectedKeys={[window.location.pathname.indexOf('settings') >= 0 ? 'settings' : '']}
        theme='dark'
        mode='inline'
        items={items}
        className={`bottomSideBar ${styles.bottomMenu}`}
        onSelect={handleNavigation}
      />
      {openExitModal && (
        <ExitModal
          open={openExitModal}
          onClose={() => {
            setOpenExitModal(false)
          }}
          navigateTo={navigateTo}
        />
      )}
    </>
  )
}

export default BottomMenu
