import React, { useState, useEffect, useRef } from 'react'
import styles from './InputOTP.module.scss'

interface InputOTPProps {
  length: number
  value?: string // Optional value prop for controlled input
  onChange: (otp: string) => void
  disabled?: boolean
  className?: string
}

const InputOTP: React.FC<InputOTPProps> = ({ length, value = '', onChange, disabled = false, className }) => {
  const [otp, setOtp] = useState<string[]>(Array(length).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]) // Store input refs for focusing

  // Sync internal state with value prop
  useEffect(() => {
    if (value) {
      const otpArray = value.split('').slice(0, length) // Only take up to `length` digits
      setOtp([...otpArray, ...Array(length - otpArray.length).fill('')]) // Fill remaining with empty strings
    }
  }, [value, length])

  // Automatically focus on the first empty input field
  useEffect(() => {
    const firstEmptyIndex = otp.findIndex((digit) => digit === '')
    if (firstEmptyIndex !== -1 && inputRefs.current[firstEmptyIndex]) {
      inputRefs.current[firstEmptyIndex]?.focus()
    } else if (otp.every((digit) => digit !== '') && inputRefs.current[length - 1]) {
      inputRefs.current[length - 1]?.focus() // Focus on the last input if all are filled
    }
  }, [otp, length])

  const handleChange = (digit: string, index: number) => {
    if (isNaN(Number(digit))) return // Only allow numerical input

    const newOtp = [...otp]
    newOtp[index] = digit

    setOtp(newOtp)
    onChange(newOtp.join(''))

    // Move focus to the next input field if available
    if (digit && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  return (
    <div className={`${styles.otpContainer} ${className ? className : ''}`}>
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)} // Set ref for each input
          id={`otp-input-${index}`}
          type='text'
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          disabled={disabled}
          className={styles.otpInput}
        />
      ))}
    </div>
  )
}

export default InputOTP
