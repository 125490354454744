import { createSlice } from '@reduxjs/toolkit'
import { ISignUpSlice } from '../interface/ISignUpSlice'

const initialState = {
  affiliateData: {},
  settings: {},
  loginValues: {},
} as ISignUpSlice

export const signUpSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    SET_AFFILIATE_DATA: (state, action) => {
      state.affiliateData = action.payload
    },
    SET_SETTINGS(state, action) {
      state.settings = action.payload
    },
    SET_LOGIN_VALUES(state, action) {
      state.loginValues = action.payload
    },
  },
})

export const { SET_AFFILIATE_DATA, SET_SETTINGS, SET_LOGIN_VALUES } = signUpSlice.actions

export const signupReducer = signUpSlice.reducer
