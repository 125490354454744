import React, { useState, useEffect } from 'react'
import { Alert, Button } from 'antd'
import SocialConnectionModal from 'common/components/SocialConnection/SocialConnectionModal'
import { useNavigate } from 'react-router-dom'
import { CloseCircleOutlined } from '@ant-design/icons'

interface NotificationStripProps {
  username: string
  platform: string
  message: string
  actionText?: string
  type?: 'info' | 'warning' | 'success' | 'error'
  modalType?: 'pinCode' | 'upgrade' | 'relogin'
  data?: any
  style?: any
}

const NotificationStrip: React.FC<NotificationStripProps> = ({
  message,
  username,
  platform,
  actionText,
  type = 'info',
  modalType,
  data,
  style = {},
}) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const dismissedUsers = JSON.parse(localStorage.getItem('dismissedUsers') || '[]')
    if (dismissedUsers.includes(username)) {
      setIsDismissed(true)
    }
    setIsLoading(false)
  }, [username])

  const showModal = () => {
    if (modalType === 'upgrade') {
      return navigate('/upgrade')
    }
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleDismiss = () => {
    const dismissedUsers = JSON.parse(localStorage.getItem('dismissedUsers') || '[]')
    if (!dismissedUsers.includes(username)) {
      dismissedUsers.push(username)
      localStorage.setItem('dismissedUsers', JSON.stringify(dismissedUsers))
    }
    setIsDismissed(true)
  }

  if (isLoading || isDismissed) {
    return null
  }

  return (
    <div>
      <Alert
        banner
        style={{ ...style }}
        message={
          message || (
            <>
              Your {platform} account {username} has logged out. Please{' '}
              <span onClick={showModal} style={{ cursor: 'pointer', color: '#7043ff' }}>
                login
              </span>{' '}
              again.
            </>
          )
        }
        type={type}
        action={
          <>
            {actionText && (
              <Button size='small' onClick={showModal}>
                {actionText}
              </Button>
            )}
            <CloseCircleOutlined onClick={handleDismiss} style={{ marginLeft: 15, cursor: 'pointer' }} />
          </>
        }
        showIcon
      />
      {modalType === 'pinCode' && isModalOpen && (
        <SocialConnectionModal
          open={isModalOpen}
          onClose={handleCloseModal}
          platform='facebook'
          step={'pin_code_required'}
          selectedUsername={data}
        />
      )}
      {modalType === 'relogin' && isModalOpen && (
        <SocialConnectionModal
          open={isModalOpen}
          onClose={handleCloseModal}
          platform={data.platform}
          step={'incoming'}
          selectedUsername={data.username}
        />
      )}
    </div>
  )
}

export default NotificationStrip
