import { useEffect, useState } from 'react'
import cx from 'classnames'
import { ConfigProvider, FloatButton, Layout } from 'antd'
import { Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom'
import { SideFilter } from 'common/components/SideFilter/SideFilter'
import { TopBar } from 'common/components/TopBar/TopBar'
import { useAuth } from 'common/hooks/useAuth.hooks'
import Sidebar from './common/components/sidebar/sidebar'
import Login from 'features/login/login'
import ResetPasswordPage from 'features/sign-up/reset-password'
import ContactsPage from 'features/contacts/contacts'
import IntentSignalsPage from 'features/intent-signals/intent-signals'
import { useAppSelector } from 'state'
import { ErrorBoundary } from 'react-error-boundary'
import CompanyAdminPanel from './features/company-admin-pannel/company-admin-pannel'
import { Userpilot } from 'userpilot'
import ConvrtTabs from 'common/components/TopBar/ConvrtTabs'
import SignupRegistrationCode from 'features/sign-up/signupRegistrationCode'
import EditCampaign from 'common/components/OutreachCampaign/EditCampaign/EditCampaign'
import ExposedContacts from 'features/Outreach/ExposedContacts'
import Settings from 'features/settings/SettingsPage'
import { useLazyGetUserDetailsQuery } from 'features/Outreach/state/api/OutreachApi'
import CreateNewCampaign from 'common/components/OutreachAI/CreateCampaign/CreateNewCampaign'
import { setIsSubscriptionDaysPassed } from 'state/filtersSlice/filtersSlice'
import { useAppDispatch } from 'state'
import Chat from 'common/components/Chat/Chat'
import { useIntercom } from 'react-use-intercom'
import CampaignSummaryView from 'common/components/OutreachAI/CreateCampaign/CampaignSummaryView'
import Campaigns from 'common/components/OutreachAI/HomePage/Campaigns'
import Builder from 'common/components/OutreachAI/Builder/Builder'
import Tasks from 'common/components/Tasks/Tasks'
import Home from 'common/components/Home/Home'
import { useLocalStorage } from 'usehooks-ts'
import Onboarding from 'features/settings/Pages/Onboarding/Onboarding'
import {
  useLazyGetExistingSocialMetadataQuery,
  useLazyGetSocialMetadataQuery,
} from 'features/settings/state/api/SettingsApi'
import LearningCenter from 'common/components/OutreachAI/HomePage/LearningCenter/LearningCenter'
import { QuestionOutlined } from '@ant-design/icons'
import {
  SET_ARTICLE_OPEN,
  SET_FLOAT_VISIBLE,
  SET_GETTING_STARTED_OPEN,
  SET_INTERCOM_OPEN,
  SET_LEARNING_OPEN,
} from 'common/components/OutreachAI/HomePage/LearningCenter/state/slice/learningSlice'
import Upgrade from 'features/settings/Pages/Upgrade/Upgrade'
import { SET_EMAIL_TOKEN, SET_FORCE_PRICING, SET_OUT_OF_OUTREACH } from 'GeneralSlice'
import NotificationStrip from 'GeneralComponents/NotificationStrip'
import './App.css'
import './antd-overwrites.scss'
import '/node_modules/primeflex/primeflex.css'
import 'swiper/css'
import 'swiper/css/navigation'
import AffiliateSignUp from 'features/sign-up/AffiliateSignup/AffiliateSignup'
import SignUpPage from 'features/sign-up/sign-up'
import MobileProvider from 'common/layouts/MobileLayout'
import SmallLogout from 'GeneralComponents/SmallLogout'
import NotificationModal from 'GeneralComponents/NotificationModal'

const { Content } = Layout

const App = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { boot, shutdown } = useIntercom()
  const { isSidebarOpen } = useAppSelector((state) => state.filters)
  const {
    floatVisible,
    learningOpen,
    articleOpen,
    gettingStartedOpen,
    intercomOpen,
    articleSeeMoreOpen,
    videoSeeMoreOpen,
  } = useAppSelector((state) => state.learning)
  const locationPathName = useLocation().pathname.split('/')[1]
  const [localUser, setLocalUser] = useLocalStorage<any>('user', null)
  const [, setStep] = useLocalStorage<any>('step', 0)
  const [, setLocalUserMetadata] = useLocalStorage<any>('user_metadata', null)
  const [userAddons, setUserAddons] = useState(localUser?.addons || {})
  const { requires_onboarding, onboarding_status, pricing } = userAddons
  const { user, logout, userDetails } = useAuth()
  const [shouldHideCheckout, setShouldHideCheckout] = useState(userDetails?.flag)
  const [refetchGetUserDetails] = useLazyGetUserDetailsQuery()
  const [userData, setUserData] = useState(userDetails)
  const [populateSocialMetadata] = useLazyGetSocialMetadataQuery()
  const [getExistingSocialMetadata] = useLazyGetExistingSocialMetadataQuery()
  const { isOpen: isIntercomOpen, update } = useIntercom()
  const [forcePricing, setForcePricing] = useState(false)
  const [isAffiliateStill, setIsAffiliateStill] = useLocalStorage('isAffiliateStill', false)
  const [facebookUsersWithMissingPin, setFacebookUsersWithMissingPin] = useState<any[]>([])
  const [requireReloginAccounts, setRequireReloginAccounts] = useState<any>({})
  const [outOfOutreach, setOutOfOutreach] = useState(false)
  const { isMobile } = useAppSelector((state) => state.General)
  const [failedSigninAttemps] = useLocalStorage<any>('failed_signin_attempt', null)
  const [openNotificationModal, setOpenNotificationModal] = useState(false)
  const [calledSmartllok, setCalledSmartllok] = useState(false)
  const [isResultionBad, setIsResultionBad] = useState(false)
  const [isSafari, setIsSafari] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const notForLearning = [
    'tasks',
    'home',
    'unifiedInbox',
    'login',
    'signup',
    'reset-password',
    'premium-signup',
    'signupAffiliate',
  ]
  const currentDate = new Date()
  const createdSubscriptionDate = new Date(userDetails?.subscription_created_at)
  const subscriptionDays = userDetails?.subscription_days
  const timeDifference = currentDate?.getTime() - createdSubscriptionDate?.getTime()
  const daysPassed = timeDifference / (1000 * 60 * 60 * 24)
  const navToCheckout =
    !userDetails?.flag && userDetails?.subscription_type === 'BETA' && Math.trunc(subscriptionDays - daysPassed) <= 0

  const bootIntercom = () =>
    boot({
      name: userDetails?.name,
      email: userDetails?.email,
    })

  const showSidebar =
    window.location.pathname !== '/onboarding' &&
    // window.location.pathname !== '/upgrade' &&
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/register') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/')

  const showTopBar =
    window.location.pathname !== '/login' &&
    window.location.pathname !== '/tasks' &&
    window.location.pathname !== '/home' &&
    window.location.pathname !== '/cs-login' &&
    !window.location.pathname.startsWith('/signup') &&
    !window.location.pathname.startsWith('/register') &&
    !window.location.pathname.startsWith('/reset-password') &&
    !window.location.pathname.startsWith('/premium-signup/') &&
    !window.location.pathname.startsWith('/settings') &&
    !window.location.pathname.startsWith('/unifiedInbox') &&
    !window.location.pathname.startsWith('/upgrade') &&
    window.location.pathname !== '/checkout' &&
    window.location.pathname !== '/checkout/success' &&
    !window.location.pathname.startsWith('/outreach') &&
    !window.location.pathname.startsWith('/exposedContacts') &&
    window.location.pathname !== '/onboarding'

  dispatch(setIsSubscriptionDaysPassed(navToCheckout))

  useEffect(() => {
    const href = window.location.href

    if (!user) {
      const path = window.location.pathname.split('/')[1]
      switch (path) {
        case 'signup':
          navigate('/signup')
          return
        case 'premium-signup':
          return
        case 'reset-password':
          return
        case 'login':
          navigate('/login')
          return
        case 'register?signedup-success=true':
          navigate('/register?signedup-success=true', { replace: true })
          return
        case 'register':
          const href = window.location.href
          if (!href.includes('/register?signedup-success=true')) {
            // navigate('/register?signedup-success=true', { replace: true })
            navigate('/register')
          }
          return
        default:
          navigate('/login')
          return
      }
    } else if (isAffiliateStill || localUser?.outside_onboarding?.step < 6) {
      if (!href.includes('/register?signedup-success=true')) {
        // navigate('/register?signedup-success=true', { replace: true })
        navigate('/register')
      }
    } else if (!pricing && window.location.pathname === '/upgrade') {
      navigate('/settings')
    } else if (localUser?.force_pricing) {
      if (!href.includes('/upgrade/success?session_id')) {
        navigate('/upgrade')
      }
    } else if (requires_onboarding && !onboarding_status) {
      const href = window.location.href
      if (!href.includes('/upgrade/success?session_id')) {
        if (localUser?.force_pricing && !href.includes('/upgrade')) {
          navigate('/upgrade')
        } else {
          navigate('/onboarding')
        }
      }
    } else if (window.location.pathname === '/onboarding' && (!requires_onboarding || onboarding_status)) {
      navigate('/outreach')
    } else if (navToCheckout) {
      if (window.location.pathname !== '/checkout') {
        navigate('/checkout')
      }
    } else if (user) {
      Userpilot.identify(userDetails.id, {
        name: userDetails.name,
        email: userDetails.email,
        created_at: userDetails.subscription_created_at,
      })
      bootIntercom()
    } else if (user && window.location.pathname === '/') {
      if (localUser?.force_pricing && !href.includes('/upgrade')) {
        navigate('/upgrade')
      } else {
        navigate(`/outreach`)
      }
    }
    const getMetadata = async () => {
      try {
        populateSocialMetadata({ platform: 'all' })

        const { data: metadata } = await getExistingSocialMetadata()
        setLocalUserMetadata(metadata)
      } catch (error) {
        console.log(error)
      }
    }
    getMetadata()
  }, [user])
  function isSafariBrowser() {
    // Get the user-agent string
    let userAgentString = navigator.userAgent

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false

    setIsSafari(safariAgent)
  }

  useEffect(() => {
    // Define the function to check screen size
    const checkScreenSize = () => {
      setIsResultionBad((window.innerWidth >= 1366 && window.innerWidth < 1600) || window.innerHeight < 768)
    }
    // Initial check
    checkScreenSize()
    isSafariBrowser()
    // Add event listener
    window.addEventListener('resize', checkScreenSize)

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  useEffect(() => {
    if (localUser?.outside_onboarding?.step >= 0) {
      setStep(localUser?.outside_onboarding?.step)
      localStorage.setItem('affiliateToken', localUser?.outside_onboarding?.affiliateToken)
      setIsAffiliateStill(true)
    } else if (localUser?.force_pricing && !isAffiliateStill) {
      // Force user to the /upgrade page if force_pricing is true
      dispatch(SET_FORCE_PRICING(true))
      dispatch(SET_INTERCOM_OPEN(true))
      setForcePricing(true)
      setFacebookUsersWithMissingPin([])
      setOutOfOutreach(false)
      dispatch(SET_OUT_OF_OUTREACH(false))
      if (window.location.pathname !== '/upgrade') {
        const href = window.location.href
        if (!href.includes('/upgrade/success?session_id')) {
          navigate('/upgrade')
        }
      }
    } else {
      dispatch(SET_FORCE_PRICING(false))
      dispatch(SET_INTERCOM_OPEN(false))
      setForcePricing(false)
      const facebookAccounts = localUser?.facebook || []
      const accWithMissingPin = facebookAccounts.filter((acc: any) => !acc.is_pincode_success)

      if (accWithMissingPin.length > 0) {
        setFacebookUsersWithMissingPin(accWithMissingPin)
      } else {
        setFacebookUsersWithMissingPin([])
      }

      const outreachCredits = localUser?.credits?.contacts_outreached_left
      if (!outreachCredits || outreachCredits <= 0) {
        setOutOfOutreach(true)
        dispatch(SET_OUT_OF_OUTREACH(true))
      } else {
        setOutOfOutreach(false)
        dispatch(SET_OUT_OF_OUTREACH(false))
      }
    }
  }, [localUser, userData, isAffiliateStill])

  const handleError = () => {
    if (process.env.NODE_ENV === 'development') return
    shutdown()
    logout()
    window.location.reload()
  }

  const getRequireReloginAccounts = () => {
    const facebookAccounts = localUser?.facebook || []
    const instagramAccounts = localUser?.instagram || []
    const linkedinAccounts = localUser?.linkedin || []

    const platforms = [
      { platform: 'facebook', accounts: facebookAccounts },
      { platform: 'instagram', accounts: instagramAccounts },
      { platform: 'linkedin', accounts: linkedinAccounts },
    ]

    return platforms.reduce((acc, { platform, accounts }) => {
      const requireRelogin = accounts
        .filter((acc: any) => acc.require_relogin)
        .map((acc: any) => {
          return {
            username: platform === 'linkedin' ? acc.email : acc.username,
            platform,
          }
        })
      return {
        ...acc,
        [platform]: requireRelogin,
      }
    }, {})
  }

  useEffect(() => {
    const reloginAccounts = getRequireReloginAccounts()
    setRequireReloginAccounts(reloginAccounts)
  }, [localUser])

  const renderNotifications = (platform: any, accounts: any) => {
    return accounts.map(({ username, platform: plt }: any) => (
      <NotificationStrip
        key={username}
        username={username}
        platform={platform}
        actionText={`Login to ${username}`}
        modalType='relogin'
        type='warning'
        data={{ username, platform: plt }}
        message=''
      />
    ))
  }

  useEffect(() => {
    const getUserDetails = async () => {
      const { data } = await refetchGetUserDetails()
      if (data) {
        setLocalUser(data)
        setShouldHideCheckout(data.flag)
        setUserData(data)
      }
    }

    if (user) {
      getUserDetails()

      const intervalId = setInterval(() => {
        getUserDetails()
      }, 30000)

      return () => clearInterval(intervalId)
    }
  }, [user])

  useEffect(() => {
    if (user && user.addons) {
      setUserAddons(user.addons)
    }
  }, [user])

  useEffect(() => {
    if (localUser && localUser?.outside_onboarding?.step >= 0) {
      setStep(localUser?.outside_onboarding?.step)
      localStorage.setItem('affiliateToken', localUser?.outside_onboarding?.affiliateToken)
      setIsAffiliateStill(true)
    } else if (localUser && !localUser?.outside_onboarding) {
      setIsAffiliateStill(false)
      localStorage.setItem('affiliateToken', 'null')
    }
  }, [localUser])

  useEffect(() => {
    if (!isIntercomOpen) {
      dispatch(SET_INTERCOM_OPEN(false))
      update({ hideDefaultLauncher: true })
    }
  }, [isIntercomOpen])
  useEffect(() => {
    if (
      !learningOpen &&
      articleOpen === 'NONE' &&
      !gettingStartedOpen &&
      !intercomOpen &&
      !articleSeeMoreOpen &&
      !videoSeeMoreOpen
    ) {
      dispatch(SET_FLOAT_VISIBLE(true))
    } else {
      dispatch(SET_FLOAT_VISIBLE(false))
    }
  }, [learningOpen, articleOpen, gettingStartedOpen, intercomOpen, articleSeeMoreOpen, videoSeeMoreOpen])
  useEffect(() => {
    dispatch(SET_FLOAT_VISIBLE(true))
    dispatch(SET_LEARNING_OPEN(false))
    dispatch(SET_ARTICLE_OPEN('NONE'))
    dispatch(SET_GETTING_STARTED_OPEN(false))
  }, [])

  useEffect(() => {
    if (failedSigninAttemps && failedSigninAttemps > 2) {
      setOpenNotificationModal(true)
    }
  }, [failedSigninAttemps])

  useEffect(() => {
    if ((window as any).smartlook && localUser && user && !calledSmartllok) {
      ;(window as any).smartlook('identify', localUser.email, {
        email: localUser.email,
      })
      setCalledSmartllok(true)
    }
  }, [localUser, user, calledSmartllok])

  useEffect(() => {
    if (token) {
      dispatch(SET_EMAIL_TOKEN(token))
    }
  }, [location.search])

  const PayWall = (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#000',
        fontSize: '20px',
        zIndex: 13,
      }}>
      <p style={{ paddingLeft: '80px' }}>Coming soon...</p>
    </div>
  )

  const isHFull =
    window.location.pathname.startsWith('/upgrade') || (window.location.pathname.startsWith('/register') && isMobile)
      ? ''
      : 'h-full'

  return (
    <ErrorBoundary fallback={<>{'Something went wrong'}</>} onError={handleError}>
      <MobileProvider>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#7043FF',
            },
          }}>
          <Layout className='sm:flex min-h-screen layout'>
            {showSidebar && !forcePricing && <Sidebar />}
            {showSidebar && forcePricing && !isMobile && <SmallLogout />}
            <Layout className='h-screen' style={{ marginLeft: !showSidebar || forcePricing ? 0 : '64px' }}>
              {showTopBar && <TopBar />}
              {showSidebar && isResultionBad && (
                <NotificationStrip
                  key={user?.username}
                  username='zoomout'
                  platform=''
                  message={`Recommendation: For the best experience, we recommend zooming out to 80%.`}
                  type='info'
                  style={{
                    background: 'rgb(25,188,254, .1)',
                  }}
                />
              )}
              {showSidebar && isSafari && (
                <NotificationStrip
                  key={user?.username}
                  username='chrome'
                  platform=''
                  message={`Recommendation: for the best user experience, we recommend using Google Chrome.`}
                  type='info'
                  style={{
                    background: 'rgb(25,188,254, .1)',
                  }}
                />
              )}
              {showSidebar &&
                user &&
                facebookUsersWithMissingPin.length > 0 &&
                facebookUsersWithMissingPin.map((user) => (
                  <NotificationStrip
                    username={user?.username}
                    platform=''
                    key={user?.id}
                    message={`Your Facebook account ${user?.username} PIN-code is not configured, please add it.`}
                    actionText='Setup your PIN-code'
                    modalType='pinCode'
                    type='warning'
                    data={user?.username}
                  />
                ))}
              {showSidebar &&
                user &&
                requireReloginAccounts.instagram?.length > 0 &&
                renderNotifications('Instagram', requireReloginAccounts.instagram)}
              {showSidebar &&
                user &&
                requireReloginAccounts.facebook?.length > 0 &&
                renderNotifications('Facebook', requireReloginAccounts.facebook)}
              {showSidebar &&
                user &&
                requireReloginAccounts.linkedin?.length > 0 &&
                renderNotifications('Linkedin', requireReloginAccounts.linkedin)}
              {showSidebar && user && outOfOutreach && (
                <NotificationStrip
                  key={user?.username}
                  username=''
                  platform=''
                  message={`Your campaigns have stopped sending to new contacts because you've reached the send rate limit for this billing cycle. Upgrade now to resume sending.`}
                  actionText='Resume Now'
                  modalType='upgrade'
                  type='warning'
                  data={user?.username}
                />
              )}

              {openNotificationModal && (
                <NotificationModal
                  open={openNotificationModal}
                  onClose={() => setOpenNotificationModal(false)}
                  type='signin'
                />
              )}
              <Content className='overflow-auto flex max-h-full'>
                {isSidebarOpen && <SideFilter />}

                {((!userData?.addons?.unified_inbox && window.location.pathname.includes('unifiedInbox')) ||
                  (!userData?.addons?.research &&
                    (window.location.pathname.includes('companies') ||
                      window.location.pathname.includes('contacts'))) ||
                  (!userData?.addons?.my_contacts && window.location.pathname.includes('exposedContacts'))) &&
                  PayWall}
                {userData?.subscription_type === 'BETA' &&
                  window.location.pathname !== '/login' &&
                  window.location.pathname !== '/signup' &&
                  !window.location.pathname.startsWith('/reset-password') &&
                  !window.location.pathname.startsWith('/premium-signup/') &&
                  !window.location.pathname.includes('checkout') &&
                  !window.location.pathname.includes('upgrade') &&
                  !window.location.pathname.includes('settings') &&
                  !window.location.pathname.includes('outreach') &&
                  !window.location.pathname.includes('unifiedInbox') &&
                  !window.location.pathname.includes('onboarding') &&
                  PayWall}
                {window.location.pathname.includes('tasks') && PayWall}
                {window.location.pathname.includes('home') && PayWall}
                <Layout
                  style={{
                    backgroundColor: '#F9FAFB',
                  }}
                  className={cx('flex flex-column relative overflow-auto max-h-full')}>
                  {(locationPathName === 'contacts' || locationPathName === 'companies') && (
                    <ConvrtTabs activeTab={locationPathName} />
                  )}
                  <div className={`flex flex-column ${isHFull}`}>
                    {userData?.addons?.learning_center &&
                      !notForLearning.includes(locationPathName) &&
                      !forcePricing && (
                        <>
                          <FloatButton
                            shape='square'
                            type='primary'
                            style={{
                              borderRadius: 50,
                              display: !floatVisible ? 'none' : 'block',
                            }}
                            icon={<QuestionOutlined />}
                            className='outreachHelpFloater'
                            onClick={() => {
                              dispatch(SET_LEARNING_OPEN(true))
                            }}
                          />
                          <LearningCenter />
                        </>
                      )}

                    <Routes>
                      {!shouldHideCheckout && (
                        <>
                          <Route path='/upgrade' element={<Upgrade />} />
                          <Route path='/upgrade/success' element={<Upgrade />} />
                          <Route path='/upgrade/canceled' element={<Upgrade />} />
                          <Route path='/upgrade/subscription-canceled' element={<Upgrade />} />
                        </>
                      )}
                      <Route path='/login' element={<Login />} />
                      <Route path='/signup' element={<SignUpPage />} />
                      <Route path='/signup/:token' element={<SignUpPage />} />
                      <Route path='/register' element={<AffiliateSignUp />} />
                      <Route path='/reset-password' element={<ResetPasswordPage />} />
                      <Route path='/reset-password/:userId' element={<ResetPasswordPage />} />
                      <Route path='/premium-signup/:registrationCode' element={<SignupRegistrationCode />} />

                      {!navToCheckout ? (
                        <>
                          <Route path='/companies' element={<IntentSignalsPage />} />
                          <Route path='/companies/:selectedId' element={<IntentSignalsPage />} />
                          <Route path='/contacts' element={<ContactsPage />} />
                          <Route path='/contacts/:selectedId' element={<ContactsPage />} />
                          <Route path='/settings' element={<Settings />} />
                          <Route path='/settings/success' element={<Settings />} />
                          <Route path='/settings/slack/success' element={<Settings />} />
                          <Route path='/settings/salesforce/success' element={<Settings />} />
                          <Route path='/home' element={<Home />} />
                          <Route path='/tasks' element={<Tasks />} />
                          <Route path='/exposedContacts' element={<ExposedContacts />} />
                          <Route path='/exposedContacts/:selectedId' element={<ExposedContacts />} />
                          <Route path='/outreach' element={<Campaigns />} />
                          <Route path='/outreach/create-new-campaign/campaign-builder' element={<Builder />} />
                          <Route path='/outreach/create-new-campaign' element={<CreateNewCampaign />} />
                          <Route path='/outreach/create-new-campaign/summary' element={<CampaignSummaryView />} />
                          <Route path='/outreach/create-new-campaign/edit-manually' element={<Builder />} />
                          <Route path='/outreach/create-new-campaign/edit-manually/view' element={<Builder />} />
                          <Route path='/outreach/edit-campaign' element={<EditCampaign />} />
                          <Route path='/outreach/edit-campaign/:id' element={<EditCampaign />} />
                          <Route path='/company_admin' element={<CompanyAdminPanel />} />
                          <Route path='*' element={<Navigate to='/outreach' />} />
                          <Route path='/unifiedInbox' element={<Chat />} />
                          <Route path='/onboarding' element={<Onboarding />} />
                        </>
                      ) : (
                        <Route path='*' element={<Navigate to='/settings' />} />
                      )}
                    </Routes>
                  </div>
                </Layout>
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      </MobileProvider>
    </ErrorBoundary>
  )
}

export default App
