import React, { useState, useEffect } from 'react'
import styles from './RotatingText.module.scss'

interface RotatingTextProps {
  messages: string[]
  time?: number
  spanStyle?: React.CSSProperties
}

const RotatingText: React.FC<RotatingTextProps> = ({ messages, time = 10000, spanStyle }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0)
  const [fadeClass, setFadeClass] = useState(styles.fadeIn)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    const interval = setInterval(() => {
      setFadeClass(styles.fadeOut)
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % messages.length)
        setFadeClass(styles.fadeIn)
      }, 500)
    }, time)

    return () => clearInterval(interval)
  }, [messages.length, time])

  useEffect(() => {
    if (!mounted) {
      setFadeClass('')
    }
  }, [mounted])

  return (
    <span style={{ ...spanStyle }} className={`${styles.rotatingText} ${fadeClass}`}>
      {messages[currentTextIndex]}
    </span>
  )
}

export default RotatingText
