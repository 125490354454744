import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

interface IOutreachAICampaignSlice {
  status: string
  prompt: any
  current_collapse_key: any
  saved_prompt_params: any
  active_platforms: any
  selected_platforms_accounts: any
  nodes_data: any
  selected_leads: any //string[]
  stored_temp_leads: any //string[]
  stored_leads_from_db: any //string[]
  initial_ai_data: any
  initial_ai_companies_data: any
  initial_ai_data_filtered: any
  initial_ai_companies_data_filtered: any
  ai_leads_type: 'leads' | 'companies_leads'
  ai_campaign_name: string
  campaign_settings_ai: any
  campaign_id: any
  messages_types: any
  ai_messages: any
  ai_comments: any
  edges_data: any
  loading_generate_campaign: any
  selected_node: any
  reset_campaign_data: any
  block_editing: any
  stats_data: any
  generate_messages: any
  loading_flow: boolean
  disabled_nodes: any
  campaign_status: string
  initial_group_data: any
  my_groups_filters: any
  are_leads_from_groups: boolean
  is_campaign_running_already: boolean
  are_messages_missing: boolean
  trigger: number
  skip_prompt: boolean
  is_edit_campaign: boolean
  select_custom_template: any
  default_templates: any
  flow_data: any
  is_review_mode: boolean
  created_at: Date
  setup_method: '' | 'ai' | 'manually'
  steps: any
  open_templates: boolean
  navigate_to_builder: boolean
  user_updated_sequence: boolean
  last_prompt_params: any
}

const initialState = {
  status: '',
  prompt: 0,
  current_collapse_key: '1',
  saved_prompt_params: {
    campaign_type: '',
    target_persona: '',
    industry: '',
    location: '',
    goal: '',
    expertise: '',
  },
  last_prompt_params: {
    campaign_type: '',
    target_persona: '',
    industry: '',
    location: '',
    goal: '',
    expertise: '',
  },
  active_platforms: {
    instagram: false,
    facebook: false,
    telegram: false,
    linkedin: false,
    discord: false,
    twitter: false,
  },
  selected_platforms_accounts: {
    instagram: [],
    facebook: [],
    telegram: [],
    linkedin: [],
    discord: [],
    twitter: [],
  },
  nodes_data: [],
  selected_leads: [],
  stored_temp_leads: [],
  stored_leads_from_db: [],
  initial_ai_data: [],
  initial_ai_companies_data: [],
  initial_ai_data_filtered: [],
  initial_ai_companies_data_filtered: [],
  ai_leads_type: 'leads',
  ai_campaign_name: 'New Campaign',
  campaign_settings_ai: {
    isDoNotSendMessagesActive: false,
    isAvoidMessagesInOtherCampaignsActive: false,
    timingCampaign: dayjs(),
    schedule_id: '',
  },
  campaign_id: '',
  messages_types: ['send_message', 'comment_on_post', 'comment_on_story', 'comment_on_photo'],
  ai_messages: '',
  ai_comments: '',
  edges_data: [],
  loading_generate_campaign: false,
  selected_node: {},
  reset_campaign_data: false,
  block_editing: false,
  stats_data: [],
  generate_messages: true,
  loading_flow: false,
  disabled_nodes: [],
  campaign_status: 'pending',
  initial_group_data: [{ category: '', data: [], myGroups: [], requestedGroups: [] }],
  are_leads_from_groups: false,
  my_groups_filters: '',
  is_campaign_running_already: false,
  are_messages_missing: false,
  trigger: 0,
  skip_prompt: false,
  is_edit_campaign: false,
  select_custom_template: null,
  default_templates: [],
  flow_data: {},
  is_review_mode: false,
  created_at: new Date(),
  setup_method: '',
  steps: [],
  open_templates: false,
  navigate_to_builder: false,
  user_updated_sequence: false,
} as IOutreachAICampaignSlice

export const outreachAICampaignSlice = createSlice({
  name: 'outreachCampaign',
  initialState,
  reducers: {
    SET_PROMPT(state, action) {
      state.status = 'succeeded'
      state.prompt = action.payload
    },
    SET_CURRENT_COLLAPSE_KEY(state, action) {
      state.status = 'succeeded'
      state.current_collapse_key = action.payload
    },
    INCREMENT_COLLAPSE_KEY(state, action) {
      state.status = 'succeeded'
      if (state.current_collapse_key === '2') {
        state.current_collapse_key = '3'
      }
    },
    SET_SAVED_PROMPT_PARAMS: (state, action) => {
      state.saved_prompt_params = {
        ...state.saved_prompt_params,
        ...action.payload,
      }
    },
    RESET_SAVED_PROMPT_PARAMS: (state, action) => {
      state.status = 'succeeded'
      state.saved_prompt_params = {
        ...initialState.saved_prompt_params,
        campaign_type: state.saved_prompt_params.campaign_type,
      }
    },
    SET_ACTIVE_PLATFORMS(state, action) {
      state.status = 'succeeded'
      state.active_platforms = action.payload
    },
    SET_SELECTED_PLATFORMS_ACCOUNTS(state, action) {
      state.status = 'succeeded'
      state.selected_platforms_accounts = action.payload
    },
    SET_NODES_DATA(state, action) {
      // action.payload could either be an array (for initialization) or an object (for updates)
      if (Array.isArray(action.payload)) {
        state.nodes_data = action.payload
      } else {
        const { step_id, ...updates } = action.payload
        const nodeIndex = state.nodes_data.findIndex((node: any) => node.step_id === step_id)
        if (nodeIndex !== -1) {
          state.nodes_data[nodeIndex] = { ...state.nodes_data[nodeIndex], ...updates }
        } else {
          // the node doesn't exist
        }
      }
    },
    SET_SELECTED_LEADS(state, action) {
      state.status = 'succeeded'
      state.selected_leads = [...state.selected_leads, ...action.payload]
    },
    SET_STORED_LEADS_FROM_DB(state, action) {
      state.status = 'succeeded'
      state.stored_leads_from_db = action.payload
    },
    SET_STORED_TEMP_LEADS(state, action) {
      state.status = 'succeeded'
      state.stored_temp_leads = action.payload
    },
    REPLACE_SELECTED_LEADS(state, action) {
      state.status = 'succeeded'
      state.selected_leads = action.payload
    },
    RESET_SELECTED_LEADS(state, action) {
      state.status = 'succeeded'
      state.selected_leads = action.payload
    },
    UPDATE_SELECTED_LEADS(state, action) {
      state.status = 'succeeded'

      for (let lead_index of action?.payload?.leads_index) {
        const indexToUpdate = state.selected_leads?.findIndex((item: any) => item.index === lead_index.index)

        if (indexToUpdate !== -1) {
          state.selected_leads[indexToUpdate] = {
            ...state.selected_leads[indexToUpdate],
            ...lead_index,
          }
        }
      }
    },
    SET_INITIAL_AI_DATA(state, action) {
      state.status = 'succeeded'
      state.initial_ai_data = action.payload
    },
    UPDATE_INITIAL_AI_DATA(state, action) {
      state.status = 'succeeded'
      if (action?.payload !== undefined) {
        for (let lead_index of action?.payload?.leads_index) {
          const indexToUpdate = state.initial_ai_data?.data?.findIndex((item: any) => item.index === lead_index.index)

          if (indexToUpdate !== -1) {
            state.initial_ai_data.data[indexToUpdate] = {
              ...state.initial_ai_data.data[indexToUpdate],
              ...lead_index,
            }
          }
        }
      }
    },
    SET_INITIAL_AI_COMPANIES_DATA(state, action) {
      state.status = 'succeeded'
      state.initial_ai_companies_data = action.payload
    },
    UPDATE_INITIAL_AI__COMPANIES_DATA(state, action) {
      state.status = 'succeeded'
      if (action?.payload !== undefined) {
        for (let lead_index of action?.payload?.leads_index) {
          const indexToUpdate = state.initial_ai_companies_data?.data?.findIndex(
            (item: any) => item.index === lead_index.index,
          )
          if (indexToUpdate !== -1) {
            state.initial_ai_companies_data.data[indexToUpdate] = {
              ...state.initial_ai_companies_data.data[indexToUpdate],
              ...lead_index,
            }
          }
        }
      }
    },
    SET_INITIAL_AI_DATA_FILTERED(state, action) {
      state.status = 'succeeded'
      state.initial_ai_data_filtered = action.payload
    },
    SET_INITIAL_AI_COMPANIES_DATA_FILTERED(state, action) {
      state.status = 'succeeded'
      state.initial_ai_companies_data_filtered = action.payload
    },
    SET_AI_LEADS_TYPE(state, action) {
      state.status = 'succeeded'
      state.ai_leads_type = action.payload
    },
    SET_AI_CAMPAIGN_NAME(state, action) {
      state.status = 'succeeded'
      state.ai_campaign_name = action.payload
    },
    SET_CAMPAIGN_SETTINGS_AI(state, action) {
      state.status = 'succeeded'
      state.campaign_settings_ai = action.payload
    },
    SET_CAMPAIGN_ID_AI(state, action) {
      state.status = 'succeeded'
      state.campaign_id = action.payload
    },
    SET_AI_MESSAGES(state, action) {
      state.status = 'succeeded'
      state.ai_messages = action.payload
    },
    SET_AI_COMMENTS(state, action) {
      state.status = 'succeeded'
      state.ai_comments = action.payload
    },
    SET_EDGES_DATA(state, action) {
      state.status = 'succeeded'
      state.edges_data = action.payload
    },
    SET_LOADING_GENERATE_CAMPAIGN(state, action) {
      state.status = 'succeeded'
      state.loading_generate_campaign = action.payload
    },
    SET_SELECTED_NODE(state, action) {
      state.status = 'succeeded'
      state.selected_node = action.payload
    },
    SET_RESET_CAMPAIGN_DATA(state, action) {
      state.status = 'succeeded'
      state.reset_campaign_data = action.payload
    },
    SET_BLOCK_EDITING(state, action) {
      state.status = 'succeeded'
      state.block_editing = action.payload
    },
    SET_STATS_DATA(state, action) {
      state.status = 'succeeded'
      state.stats_data = action.payload
    },
    SET_GENERATE_MESSAGES(state, action) {
      state.status = 'succeeded'
      state.generate_messages = action.payload
    },
    SET_LAODING_FLOW(state, action) {
      state.status = 'succeeded'
      state.loading_flow = action.payload
    },
    SET_DISABLED_NODES(state, action) {
      const nodeId = action.payload
      const index = state.disabled_nodes.indexOf(nodeId)
      if (index !== -1) {
        // Node ID is already in the array; remove it
        state.disabled_nodes = state.disabled_nodes.filter((id: any) => id !== nodeId)
      } else {
        // Node ID is not in the array; add it
        state.disabled_nodes.push(nodeId)
      }
      state.status = 'succeeded'
    },
    TOGGLE_NODE_DISABLED(state, action) {
      const nodeId = action.payload
      const nodeIndex = state.nodes_data.findIndex((node: any) => node.step_id === nodeId)

      if (nodeIndex !== -1) {
        // const node = state.nodes_data[nodeIndex]
        // // Toggle the disabled state; if undefined, set it to true
        // node.disabled = !node.disabled
        const isCurrentlyDisabled = state.nodes_data[nodeIndex].data.disabled || false
        state.nodes_data[nodeIndex].data.disabled = !isCurrentlyDisabled
      }
    },
    SET_CAMPAIGN_STATUS(state, action) {
      state.status = 'succeeded'
      state.campaign_status = action.payload
    },
    SET_INITIAL_GROUP_DATA(state, action) {
      state.status = 'succeeded'
      state.initial_group_data = action.payload
    },
    SET_ARE_LEADS_FROM_GROUPS(state, action) {
      state.status = 'succeeded'
      state.are_leads_from_groups = action.payload
    },
    SET_MY_GROUPS_FILTERS(state, action) {
      state.status = 'succeeded'
      state.my_groups_filters = action.payload
    },
    SET_IS_CAMPAIGN_RUNNING_ALREADY(state, action) {
      state.status = 'succeeded'
      state.is_campaign_running_already = action.payload
    },
    SET_ARE_MESSAGES_MISSING(state, action) {
      state.status = 'succeeded'
      state.are_messages_missing = action.payload
    },
    SET_TRIGGER(state, action) {
      state.status = 'succeeded'
      state.trigger = action.payload
    },
    SET_SKIP_PROMPT(state, action) {
      state.status = 'succeeded'
      state.skip_prompt = action.payload
    },
    SET_IS_EDIT_CAMPAIGN(state, action) {
      state.status = 'succeeded'
      state.is_edit_campaign = action.payload
    },
    SET_SELECT_CUSTOM_TEMPLATE(state, action) {
      state.status = 'succeeded'
      state.select_custom_template = action.payload
    },
    SET_DEFAULT_TEMPLATES(state, action) {
      state.status = 'succeeded'
      state.default_templates = action.payload
    },
    SET_FLOW_DATA(state, action) {
      state.status = 'succeeded'
      state.flow_data = action.payload
    },
    SET_IS_REVIEW_MODE(state, action) {
      state.status = 'succeeded'
      state.is_review_mode = action.payload
    },
    SET_SETUP_METHOD(state, action) {
      state.status = 'succeeded'
      state.setup_method = action.payload
    },
    SET_CREATED_AT(state, action) {
      state.status = 'succeeded'
      state.created_at = action.payload
    },
    SET_STEPS(state, action) {
      state.status = 'succeeded'
      state.steps = action.payload
    },
    SET_OPEN_TEMPLATES(state, action) {
      state.status = 'succeeded'
      state.open_templates = action.payload
    },
    SET_NAVIGATE_TO_BUILDER(state, action) {
      state.status = 'succeeded'
      state.navigate_to_builder = action.payload
    },
    SET_USER_UPDATED_SEQUENCE(state, action) {
      state.status = 'succeeded'
      state.user_updated_sequence = action.payload
    },
    RESET_CAMPAIGN_AI(state) {
      return initialState
    },
    RESET_CAMPAIGN_AI_CUSTOM(state, action) {
      const { excludeFields = [] } = action.payload
      Object.keys(initialState).forEach((key) => {
        if (!excludeFields.includes(key)) {
          ;(state as any)[key] = (initialState as any)[key]
        }
      })
      state.status = 'succeeded'
    },
    SET_LAST_PROMPT_PARAMS(state, action) {
      state.status = 'succeeded'
      state.last_prompt_params = action.payload
    },
  },
})

export const {
  SET_PROMPT,
  SET_CURRENT_COLLAPSE_KEY,
  INCREMENT_COLLAPSE_KEY,
  SET_SAVED_PROMPT_PARAMS,
  RESET_SAVED_PROMPT_PARAMS,
  SET_ACTIVE_PLATFORMS,
  SET_SELECTED_PLATFORMS_ACCOUNTS,
  SET_NODES_DATA,
  SET_SELECTED_LEADS,
  SET_STORED_TEMP_LEADS,
  SET_STORED_LEADS_FROM_DB,
  SET_INITIAL_AI_DATA,
  SET_INITIAL_AI_COMPANIES_DATA,
  SET_INITIAL_AI_DATA_FILTERED,
  SET_INITIAL_AI_COMPANIES_DATA_FILTERED,
  SET_AI_CAMPAIGN_NAME,
  SET_CAMPAIGN_SETTINGS_AI,
  SET_CAMPAIGN_ID_AI,
  REPLACE_SELECTED_LEADS,
  RESET_SELECTED_LEADS,
  SET_AI_MESSAGES,
  SET_AI_COMMENTS,
  SET_EDGES_DATA,
  SET_LOADING_GENERATE_CAMPAIGN,
  SET_SELECTED_NODE,
  SET_RESET_CAMPAIGN_DATA,
  SET_BLOCK_EDITING,
  SET_STATS_DATA,
  SET_GENERATE_MESSAGES,
  SET_LAODING_FLOW,
  UPDATE_INITIAL_AI_DATA,
  UPDATE_INITIAL_AI__COMPANIES_DATA,
  SET_AI_LEADS_TYPE,
  UPDATE_SELECTED_LEADS,
  SET_DISABLED_NODES,
  TOGGLE_NODE_DISABLED,
  SET_CAMPAIGN_STATUS,
  SET_INITIAL_GROUP_DATA,
  SET_ARE_LEADS_FROM_GROUPS,
  SET_MY_GROUPS_FILTERS,
  SET_IS_CAMPAIGN_RUNNING_ALREADY,
  SET_ARE_MESSAGES_MISSING,
  SET_TRIGGER,
  SET_SKIP_PROMPT,
  SET_IS_EDIT_CAMPAIGN,
  SET_SELECT_CUSTOM_TEMPLATE,
  SET_DEFAULT_TEMPLATES,
  SET_FLOW_DATA,
  SET_IS_REVIEW_MODE,
  SET_SETUP_METHOD,
  SET_CREATED_AT,
  SET_STEPS,
  SET_OPEN_TEMPLATES,
  SET_NAVIGATE_TO_BUILDER,
  SET_USER_UPDATED_SEQUENCE,
  RESET_CAMPAIGN_AI,
  RESET_CAMPAIGN_AI_CUSTOM,
  SET_LAST_PROMPT_PARAMS,
} = outreachAICampaignSlice.actions
export const outreachAICampaignReducer = outreachAICampaignSlice.reducer
