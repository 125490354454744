import React, { useState } from 'react'
import { Dropdown, Checkbox, Radio, Divider, Space, Button, InputNumber, MenuProps } from 'antd'

interface TableRowSelectionDropdownProps {
  data: any[]
  pageSize: number
  currentPage: number
  selectedRowKeys: any[]
  onSelectChange: (selectedKeys: any[]) => void
}

const TableRowSelectionDropdown: React.FC<TableRowSelectionDropdownProps> = ({
  data,
  pageSize,
  currentPage,
  selectedRowKeys,
  onSelectChange,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const [customNumber, setCustomNumber] = useState<number | null>(25)

  const handleDropdownSelect = (key: string) => {
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const currentPageData = data.slice(startIndex, endIndex).map((item) => item.key)
    const allDataKeys = data.map((item) => item.key)

    let updatedKeys = [...selectedRowKeys]
    if (key === 'selectCurrentPage') {
      updatedKeys = Array.from(new Set([...selectedRowKeys, ...currentPageData]))
    } else if (key === 'selectAllData') {
      updatedKeys = allDataKeys
    } else if (key === 'deselectCurrentPage') {
      updatedKeys = selectedRowKeys.filter((key) => !currentPageData.includes(key))
    } else if (key === 'selectCustomNumber' && customNumber) {
      updatedKeys = allDataKeys.slice(0, customNumber)
    } else if (key === 'deselectAllData') {
      updatedKeys = []
    }

    onSelectChange(updatedKeys)
    setOpen(false)
  }

  const menuItems: MenuProps['items'] = [
    { key: 'selectCustomNumber', label: 'Select Custom Number of Leads' },
    { key: 'selectCurrentPage', label: `Select Current Page | ${pageSize}` },
    { key: 'selectAllData', label: `Select All Data | ${data?.length}` },
  ]

  const contentStyle: React.CSSProperties = {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.12), 0 9px 28px rgba(0, 0, 0, 0.05)',
  }

  return (
    <Dropdown
      menu={{ items: menuItems }}
      open={open}
      onOpenChange={(e) => setOpen(e)}
      trigger={['click']}
      dropdownRender={() => (
        <div style={contentStyle}>
          <Radio.Group
            onChange={(e) => setSelectedOption(e.target.value)}
            value={selectedOption}
            style={{ display: 'flex', padding: '8px 16px', flexDirection: 'column', gap: '8px' }}>
            {menuItems.map((item: any) => (
              <div key={item.key}>
                <Radio value={item.key}>{item.label}</Radio>
                {item.key === 'selectCustomNumber' && selectedOption === 'selectCustomNumber' && (
                  <div style={{ marginTop: 8 }}>
                    <InputNumber
                      min={1}
                      max={data?.length}
                      value={customNumber}
                      onChange={(value) => setCustomNumber(value)}
                      placeholder='Enter number'
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
              </div>
            ))}
          </Radio.Group>

          <Divider style={{ margin: 0 }} />
          <Space style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 10px' }}>
            {selectedRowKeys?.length > 0 ? (
              <span
                style={{ color: '#7043ff', cursor: 'pointer' }}
                onClick={() => {
                  handleDropdownSelect('deselectAllData')
                }}>
                Deselect
              </span>
            ) : (
              <span></span>
            )}
            <Button
              type='primary'
              onClick={() => {
                if (selectedOption) {
                  handleDropdownSelect(selectedOption)
                }
              }}>
              Apply
            </Button>
          </Space>
        </div>
      )}>
      <Checkbox
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
        checked={selectedRowKeys.length > 0 && selectedRowKeys.length === data.length}
        onClick={() => setOpen(!open)}
      />
    </Dropdown>
  )
}

export default TableRowSelectionDropdown
