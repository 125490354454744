import styles from './AffiliateSignUp.module.scss'
import convrt_logo_login from 'common/assets/svg/convrt_logo_login.svg'
import rightSection from 'common/assets/png/RightSection-new.png'
import rightSection2 from 'common/assets/png/RightSection2-new.png'
import rightSection3 from 'common/assets/png/RightSection3-new.png'
// import rightSection from 'common/assets/png/RightSection.png'
// import rightSection2 from 'common/assets/png/RightSection2.png'
// import rightSection3 from 'common/assets/png/RightSection3.png'
import InitialForm from './UI/InitialForm/InitialForm'
import VerifyEmail from './UI/VerifyEmail/VerifyEmail'
import { useLocalStorage } from 'usehooks-ts'
import GetToKnow from './UI/GetToKnow/GetToKnow'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'state'
import { SET_AFFILIATE_DATA, SET_SETTINGS } from './state/slice/signupSlice'
import { isJWTVaild } from 'common/utils/signupUtils'
import { useLazyGetSettingsQuery } from './state/api/SignUpApi'
const stepMap: any = {
  0: <InitialForm />,
  1: <VerifyEmail />,
  2: <GetToKnow />,
  3: <GetToKnow />,
  4: <GetToKnow />,
  5: <GetToKnow />,
}
const imageMap: any = {
  0: <img src={rightSection} className={styles.rightImage} alt='rightSection' />,
  1: <img src={rightSection2} className={styles.rightImage} alt='rightSection' />,
  2: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  3: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  4: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
  5: <img src={rightSection3} className={styles.rightImage} alt='rightSection' />,
}

export default function AffiliateSignUp() {
  const [step, setStep] = useLocalStorage('step', 0)
  const [user] = useLocalStorage<any>('user', null)
  const [affiliateToken] = useLocalStorage('affiliateToken', '')
  const [checked, setChecked] = useState(false)
  const [getSettings] = useLazyGetSettingsQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isMobile, emailToken } = useAppSelector((state) => state.General)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const handleNavigate = () => {
    window.open('https://www.convrt.ai/', '_self')
  }

  useEffect(() => {
    if (!checked) {
      const affiliateToken = localStorage.getItem('affiliateToken')
      if (!affiliateToken) {
        console.log('Affiliate token not found')
        if (emailToken || token) {
          setStep(1)
        } else {
          navigate('/signup')
        }
        return
      }
      const data = isJWTVaild(affiliateToken)
      if (data) {
        dispatch(SET_AFFILIATE_DATA(data))
      } else {
        navigate('/signup')
      }
      setChecked(true)
    }
  }, [affiliateToken])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getSettings()
      if (data) {
        dispatch(SET_SETTINGS(data?.settings))
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (user?.outside_onboarding?.step >= 0) {
      setStep(user?.outside_onboarding?.step)
    }
  }, [user])

  return (
    <>
      {isMobile ? (
        // <div className={styles.contentMobile}>
        //   <div className={styles.cardWrapperMobile}>
        //     <div className={styles.cardMobile}>
        //       <div className={styles.leftMobile}>
        //         <div className={styles.logo} style={{ paddingBottom: '30px' }}>
        //           <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
        //         </div>
        //       </div>
        //       <div className={styles.main}>{stepMap[step]}</div>
        //     </div>
        //   </div>
        // </div>
        <div className={styles.outerContainer} style={{ justifyContent: 'center' }}>
          <div className={styles.header} style={{ minHeight: '50px', alignItems: 'center', display: 'flex' }}>
            <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
          </div>
          <div className={styles.mainBody}>{stepMap[step]}</div>
        </div>
      ) : (
        // <div className={styles.page}>
        //   <div className={styles.innerPage}>
        //     <div className={styles.left}>
        //       <div className={styles.logo}>
        //         <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
        //       </div>
        //       <div className={styles.main}>
        //         <div className={styles.content}>{stepMap[step]}</div>
        //       </div>
        //     </div>
        //     <div className={styles.right}>{imageMap[step]}</div>
        //   </div>
        // </div>
        <div className={styles.outerContainer}>
          <div className={styles.header}>
            <img onClick={handleNavigate} style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='logo' />
          </div>
          <div className={styles.mainBodyWeb}>
            <div className={styles.leftMain}>{stepMap[step]}</div>
            <div className={styles.rightMain}>{imageMap[step]}</div>
          </div>
        </div>
      )}
    </>
  )
}
