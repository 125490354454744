import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import { AuthService } from 'common/services/authServices'
import { Buttons } from 'common/constants/label.constants'
import convrt_logo_login from 'common/assets/svg/convrt_logo_login.svg'

import styles from './reset-password.module.scss'
import { useAppSelector } from 'state'

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const [errorSignup, setErrorSignup] = useState('')
  const [isSuccessfully, setIsSuccessfully] = useState(false)
  const [isAllowed, setIsAllowed] = useState(false)
  const { isMobile } = useAppSelector((state) => state.General)

  const location = useLocation()

  const queryString = location.search.slice(1)
  const [, value] = queryString.split('=')

  useEffect(() => {
    const getIsAllowedResetPassword = async () => {
      const result = await AuthService.checkAllowedResetPassword({ email: value })
      setIsAllowed(result.is_allowed)
    }
    getIsAllowedResetPassword()
  }, [value])

  const emailFromState = value
  const formik = useFormik({
    initialValues: {
      email: emailFromState,
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: async (values) => {
      const result = await AuthService.changePassword(values)
      setIsSuccessfully(result.code === 201 ? true : false)
      if (result.code === 201) {
        setIsSuccessfully(true)
        navigate('/login')
      } else {
        setIsSuccessfully(false)
      }
      if (result.code === 'ERR_BAD_REQUEST') {
        setErrorSignup(result.response.data.message)
      } else {
        setErrorSignup(result.message)
      }
    },
  })

  const handleSignUpNavigate = () => {
    // navigate('/signup')
    window.open('https://bit.ly/40e4itx', '_self')
  }

  const handleTerms = () => {
    window.open('https://www.convrt.io/terms-of-use', '_blank')
  }
  const handlePrivacyPolicy = () => {
    window.open('https://www.convrt.io/privacy-policy', '_blank')
  }

  return (
    <>
      {isMobile ? (
        <>
          <div className={styles.holdingContainerMobile}>
            <div className={styles.page}>
              <div className={styles.innerPageMobile}>
                <div className={styles.leftMobile}>
                  <div className={styles.logoMobile}>
                    <div className={styles.logo}>
                      <img style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='' />
                    </div>{' '}
                  </div>
                  <div className={styles.main}>
                    <div className={styles.contentMobile}>
                      <div className={styles.cardWrapperMobile}>
                        <div className={styles.cardMobile}>
                          <>
                            {isAllowed ? (
                              <>
                                <div className={styles.form}>
                                  <div className={styles.header}>
                                    <p>Reset Password</p>
                                    <span>Please enter your new password</span>
                                  </div>
                                  <Form onFinish={formik.handleSubmit}>
                                    <div className={styles.formRow}>
                                      <span className={styles.type}>Email</span>
                                      <Form.Item
                                        style={{ marginTop: '10px' }}
                                        name='email'
                                        rules={[
                                          {
                                            type: 'email',
                                            message: 'Invalid email address',
                                          },
                                        ]}>
                                        <Input
                                          style={{ height: 40, borderRadius: '10px' }}
                                          {...formik.getFieldProps('email')}
                                          placeholder={emailFromState}
                                          value={emailFromState}
                                          readOnly={true}
                                          disabled={true}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className={styles.formRow}>
                                      <div className={styles.password}>
                                        <span className={styles.type}>New Password</span>
                                      </div>
                                      <Form.Item
                                        style={{ marginTop: '10px' }}
                                        name='newPassword'
                                        rules={[
                                          { required: true, message: 'New password is required' },
                                          { min: 8, message: 'Password must be at least 8 characters' },
                                        ]}>
                                        <Input.Password
                                          style={{ height: 40, borderRadius: '10px' }}
                                          {...formik.getFieldProps('newPassword')}
                                          placeholder='New Password'
                                          className='password'
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className={styles.formRow}>
                                      <div className={styles.password}>
                                        <span className={styles.type}>Confirm New Password</span>
                                      </div>

                                      <Form.Item
                                        style={{ marginTop: '10px' }}
                                        name='confirmPassword'
                                        rules={[
                                          { required: true, message: 'Confirm your password' },
                                          { min: 8, message: 'Password must be at least 8 characters' },
                                        ]}>
                                        <Input.Password
                                          style={{ height: 40, borderRadius: '10px' }}
                                          {...formik.getFieldProps('confirmPassword')}
                                          placeholder='Confirm new password'
                                          className='password'
                                        />
                                      </Form.Item>
                                    </div>
                                    {/* {errorLogin !== '' ? <p className={styles.error}>{errorLogin}</p> : null} */}
                                    {errorSignup !== '' ? <p className={styles.error}>{errorSignup}</p> : null}
                                    {isSuccessfully && <p className={styles.succeeded}>{'Sign Up successfully!'}</p>}

                                    <Form.Item>
                                      <Button type='primary' className={styles.btnLogin} htmlType='submit'>
                                        {Buttons.RESET_PASSWORD}
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                                <div className={styles.footer}>
                                  <span>
                                    By reseting your password, you agree to our{' '}
                                    <span
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={handleTerms}>
                                      Terms
                                    </span>{' '}
                                    &{' '}
                                    <span
                                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                      onClick={handlePrivacyPolicy}>
                                      Privacy Policy.
                                    </span>
                                  </span>
                                  <span style={{ paddingBottom: '20px' }}>
                                    Don’t have an account?{' '}
                                    <span
                                      onClick={handleSignUpNavigate}
                                      style={{ color: '#005AF4', cursor: 'pointer' }}>
                                      Sign Up Now!
                                    </span>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className={styles.header}>
                                <p>Link Expired</p>
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.holdingContainer}>
            <div className={styles.logo}>
              <img style={{ cursor: 'pointer' }} src={convrt_logo_login} alt='' />
            </div>

            {isAllowed ? (
              <>
                <div className={styles.form}>
                  <div className={styles.header}>
                    <p>Reset Password</p>
                    <span>Please enter your new password</span>
                  </div>
                  <Form onFinish={formik.handleSubmit}>
                    <div className={styles.formRow}>
                      <span className={styles.type}>Email</span>
                      <Form.Item
                        style={{ marginTop: '10px' }}
                        name='email'
                        rules={[
                          {
                            type: 'email',
                            message: 'Invalid email address',
                          },
                        ]}>
                        <Input
                          style={{ height: 40, borderRadius: '10px' }}
                          {...formik.getFieldProps('email')}
                          placeholder={emailFromState}
                          value={emailFromState}
                          readOnly={true}
                          disabled={true}
                        />
                      </Form.Item>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.password}>
                        <span className={styles.type}>New Password</span>
                      </div>
                      <Form.Item
                        style={{ marginTop: '10px' }}
                        name='newPassword'
                        rules={[
                          { required: true, message: 'New password is required' },
                          { min: 8, message: 'Password must be at least 8 characters' },
                        ]}>
                        <Input.Password
                          style={{ height: 40, borderRadius: '10px' }}
                          {...formik.getFieldProps('newPassword')}
                          placeholder='New password'
                          className='password'
                        />
                      </Form.Item>
                    </div>
                    <div className={styles.formRow}>
                      <div className={styles.password}>
                        <span className={styles.type}>Confirm New Password</span>
                      </div>

                      <Form.Item
                        style={{ marginTop: '10px' }}
                        name='confirmPassword'
                        rules={[
                          { required: true, message: 'Confirm your password' },
                          { min: 8, message: 'Password must be at least 8 characters' },
                        ]}>
                        <Input.Password
                          style={{ height: 40, borderRadius: '10px' }}
                          {...formik.getFieldProps('confirmPassword')}
                          placeholder='Confirm new password'
                          className='password'
                        />
                      </Form.Item>
                    </div>
                    {/* {errorLogin !== '' ? <p className={styles.error}>{errorLogin}</p> : null} */}
                    {errorSignup !== '' ? <p className={styles.error}>{errorSignup}</p> : null}
                    {isSuccessfully && <p className={styles.succeeded}>{'Sign Up successfully!'}</p>}

                    <Form.Item>
                      <Button type='primary' className={styles.btnLogin} htmlType='submit'>
                        {Buttons.RESET_PASSWORD}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className={styles.footer}>
                  <span>
                    By reseting your password, you agree to our{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
                      Terms
                    </span>{' '}
                    &{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicy}>
                      Privacy Policy.
                    </span>
                  </span>
                  <span style={{ paddingBottom: '20px' }}>
                    Don’t have an account?{' '}
                    <span onClick={handleSignUpNavigate} style={{ color: '#005AF4', cursor: 'pointer' }}>
                      Sign Up Now!
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {' '}
                <div className={styles.form}>
                  <div className={styles.header}>
                    <p>Link Expired</p>
                  </div>
                </div>
                <div className={styles.footer}>
                  <span>
                    By reseting your password, you agree to our{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTerms}>
                      Terms
                    </span>{' '}
                    &{' '}
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyPolicy}>
                      Privacy Policy.
                    </span>
                  </span>
                  <span style={{ paddingBottom: '20px' }}>
                    Don’t have an account?{' '}
                    <span onClick={handleSignUpNavigate} style={{ color: '#005AF4', cursor: 'pointer' }}>
                      Sign Up Now!
                    </span>
                  </span>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ResetPasswordPage
