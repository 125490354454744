import React, { useEffect, useState } from 'react'
import { Select, Input, Row, Col, Typography, Button, Card } from 'antd'
import { CountryRegionData } from 'react-country-region-selector'

import styles from './OnboardingICP.module.scss'
import { industries, personas, hobbies, countryAliases } from 'common/components/OutreachAI/Helpers/CampaignConstants'
import { useGetTagsMutation } from 'features/intent-signals/state/api/CompaniesApi'

import Business_persona from 'common/assets/svg/business.svg'
import Individual_persona from 'common/assets/svg/individual.svg'

const { TextArea } = Input
const { Option } = Select
const { Text } = Typography

const popularCountries = [
  'United States',
  'United Kingdom',
  'France',
  'Germany',
  'Italy',
  'Spain',
  'Netherlands',
  'Switzerland',
  'Sweden',
  'Norway',
  'Denmark',
  'Belgium',
  'Austria',
  'Ireland',
  'Finland',
  'Poland',
  'Portugal',
  'Canada',
  'Australia',
  'Japan',
  'China',
  'India',
  'Brazil',
  'Mexico',
  'South Korea',
  'Russia',
  'South Africa',
  'Saudi Arabia',
  'United Arab Emirates',
  'Singapore',
]

const promptTitle = [
  {
    id: 1,
    type: 'business',
    title: 'Business Persona',
    description:
      'Centered on business skills, job title, communities, company, locations, and professional attributes. Ideal for B2B targeting.',
  },
  {
    id: 2,
    type: 'individual',
    title: 'Individual Persona',
    description:
      'Persona based around personal hobbies, interests, locations, communities and social interactions. Ideal for B2C targeting.',
  },
]

const promptImageMap: any = {
  1: Business_persona,
  2: Individual_persona,
}

const industryValues = industries.map((industry) => ({ value: industry }))
const personasValues = personas.map((persona) => ({ value: persona }))
const hobbiesValues = hobbies.map((hobby) => ({ value: hobby }))

// const formatOptions = () => {
//   const sortedCountryData = [
//     ...popularCountries
//       .map(
//         (countryName) =>
//           CountryRegionData.find((country) => country[0] === countryName) as [string, string, string | undefined],
//       )
//       .filter(Boolean),

//     ...CountryRegionData.filter((country) => !popularCountries.includes(country[0])),
//   ]

//   return sortedCountryData.flatMap(([countryName, , regionsString]) => {
//     const regions = regionsString ? regionsString.split('|') : []
//     const countryOption = {
//       // countryCode: countryCode,
//       value: countryName,
//       label: (
//         <div>
//           <label style={{ fontWeight: 600, cursor: 'pointer' }}>
//             {countryName} <span style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>country</span>
//           </label>
//         </div>
//       ),
//     }
//     const regionOptions = regions.map((region) => {
//       const [regionName, regionCode] = region.split('~')
//       return {
//         // countryCode: countryCode,
//         value: `${regionName}, ${regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''}${countryName}`,
//         label: (
//           <div style={{ cursor: 'pointer' }}>
//             <label style={{ fontSize: '12px', cursor: 'pointer' }}>{`${regionName}, ${
//               regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''
//             }${countryName} `}</label>
//             <label style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>region</label>
//           </div>
//         ),
//       }
//     })

//     return [countryOption, ...regionOptions]
//   })
// }

const formatOptions = () => {
  const sortedCountryData = [
    ...popularCountries
      .map(
        (countryName) =>
          CountryRegionData.find((country) => country[0] === countryName) as [string, string, string | undefined],
      )
      .filter(Boolean),

    ...CountryRegionData.filter((country) => !popularCountries.includes(country[0])),
  ]

  // Add alias entries for specific countries
  const aliasOptions = Object.entries(countryAliases)
    .map(([alias, countryName]) => {
      const country = CountryRegionData.find((country) => country[0] === countryName)
      if (country) {
        const [, , regionsString] = country
        const regions = regionsString ? regionsString.split('|') : []
        const countryOption = {
          value: countryName,
          label: (
            <div>
              <label style={{ fontWeight: 600, cursor: 'pointer' }}>
                {countryName} <span style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>country</span>
              </label>
            </div>
          ),
          alias,
        }

        const regionOptions = regions.map((region) => {
          const [regionName, regionCode] = region.split('~')
          return {
            value: `${regionName}, ${regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''}${countryName}`,
            label: (
              <div style={{ cursor: 'pointer' }}>
                <label style={{ fontSize: '12px', cursor: 'pointer' }}>{`${regionName}, ${
                  regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''
                }${countryName} `}</label>
                <label style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>region</label>
              </div>
            ),
            alias,
          }
        })

        return [countryOption, ...regionOptions]
      }
      return []
    })
    .flat()

  const completeOptions = [
    ...aliasOptions,

    ...sortedCountryData.flatMap(([countryName, , regionsString]) => {
      const regions = regionsString ? regionsString.split('|') : []
      const countryOption = {
        value: countryName,
        label: (
          <div>
            <label style={{ fontWeight: 600, cursor: 'pointer' }}>
              {countryName} <span style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>country</span>
            </label>
          </div>
        ),
      }
      const regionOptions = regions.map((region) => {
        const [regionName, regionCode] = region.split('~')
        return {
          value: `${regionName}, ${regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''}${countryName}`,
          label: (
            <div style={{ cursor: 'pointer' }}>
              <label style={{ fontSize: '12px', cursor: 'pointer' }}>{`${regionName}, ${
                regionCode !== 'undefined' && regionCode ? `${regionCode}, ` : ''
              }${countryName} `}</label>
              <label style={{ fontSize: '11px', color: '#B0B0B0', cursor: 'pointer' }}>region</label>
            </div>
          ),
        }
      })

      return [countryOption, ...regionOptions]
    }),
  ]

  const uniqueOptions = completeOptions.filter(
    (option, index, self) => index === self.findIndex((o) => o.value === option.value),
  )

  return uniqueOptions
}

const parseLocation = (locations: string[]) => {
  return locations.map((location) => {
    const parts = location.split(',').map((part) => part.trim())

    if (parts.length === 3) {
      // Format: Region, State, Country
      return { region: parts[0], state: parts[1], country: parts[2] }
    } else if (parts.length === 2) {
      // Format: State, Country or Region, Country
      const [first, second] = parts
      // Assume the last part is always the country
      const isPossibleRegion = CountryRegionData.some(
        ([country, , regions]) => regions && regions.split('|').some((region) => region.includes(first)),
      )
      if (isPossibleRegion) {
        return { region: first, state: '', country: second }
      }
      return { state: first, country: second }
    } else if (parts.length === 1) {
      // Format: Country
      return { state: '', region: '', country: parts[0] }
    }

    return { state: '', region: '', country: '' }
  })
}

const BUSINESS_PERSONA = 'business'
const INDIVIDUAL_PERSONA = 'individual'
interface OnboardingICPProps {
  setICPData: (values: any) => void
  currentStep: number
  personaType: string
  setCurrentStep: (values: number) => void
  setPersonaType: (values: string) => void
  highlightMissingValues?: boolean
}

const OnboardingICP: React.FC<OnboardingICPProps> = ({
  setICPData,
  highlightMissingValues,
  currentStep,
  setCurrentStep,
  personaType,
  setPersonaType,
}) => {
  const [refetchGetTags] = useGetTagsMutation()
  const [rowsNumber, setRowsNumber] = useState(5)
  const [industry, setIndustry] = useState<string[] | undefined>(undefined)
  const [numberOfEmployees, setNumberOfEmployees] = useState<string[] | undefined>(undefined)
  const [location, setLocation] = useState<string[]>([])
  const [jobTitle, setJobTitle] = useState<string | undefined>(undefined)
  const [problem, setProblem] = useState<string>('')
  const [solution, setSolution] = useState<string>('')
  const [results, setResults] = useState<string>('')
  const [competitiveAdvantage, setCompetitiveAdvantage] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [interests, setInterests] = useState<any>([])
  const [name, setName] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const [newTag, setNewTag] = useState('')
  const options = formatOptions()

  const sortSelectedOptions = (options: any, selectedValues: any) => {
    const selectedOptions = options.filter((option: any) => selectedValues.includes(option.value))
    const unselectedOptions = options.filter((option: any) => !selectedValues.includes(option.value))
    return [...selectedOptions, ...unselectedOptions]
  }

  const sortedLocationOptions = sortSelectedOptions(options, location)

  const selectStyle: any = {
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '10px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.001em',
  }

  const addNewTag = () => {
    if (newTag.trim() !== '' && !tags.includes(newTag) && !interests.includes(newTag)) {
      setTags([...tags, newTag])
      setInterests([...interests, newTag])
      setNewTag('')
    }
  }

  const handleGetTags = async () => {
    if (!tags || tags.length === 0) {
      const result = await refetchGetTags('')
      setTags(result.data || [])
    }
  }

  const resetState = () => {
    setName('')
    setJobTitle(undefined)
    setIndustry(undefined)
    setNumberOfEmployees(undefined)
    setLocation([])
    setProblem('')
    setSolution('')
    setResults('')
    setCompetitiveAdvantage('')
    setDescription('')
    setInterests([])
  }

  const handlePersonaSelection = (type: string) => {
    setPersonaType(type)
    setCurrentStep(2)
    resetState()
  }

  useEffect(() => {
    if (newTag !== '') {
      const fetchData = async () => {
        const result = await refetchGetTags(newTag)
        setTags(result.data || [])
      }

      fetchData().catch(console.error)
    }
  }, [newTag])

  useEffect(() => {
    // RESIZE
    const updateSize = () => {
      if (window.innerHeight < 900) {
        setRowsNumber(5)
      } else {
        setRowsNumber(10)
      }
    }
    updateSize()
    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  useEffect(() => {
    const parsedLocations = parseLocation(location)

    const data = {
      name,
      job_title: jobTitle,
      industry,
      number_of_employees: numberOfEmployees,
      locations: location,
      parsed_locations: parsedLocations,
      problem,
      solution,
      results,
      competitive_advantage: competitiveAdvantage,
      description,
      persona_type: personaType,
      interests,
    }

    setICPData(data)
  }, [
    name,
    industry,
    numberOfEmployees,
    location,
    jobTitle,
    problem,
    solution,
    results,
    competitiveAdvantage,
    description,
    personaType,
    interests,
  ])

  return (
    <div className={styles.container}>
      {currentStep === 1 && (
        <>
          <Typography.Title style={{ textAlign: 'center' }} level={3}>
            Who Are You Targeting?
          </Typography.Title>
          <div style={{ textAlign: 'center' }}>
            Choose a persona type to create now - you can always add more personas later.
          </div>
          <div className={styles.buttonContainer}>
            {promptTitle.map((items, index) => (
              <div
                key={index}
                className={styles.cardBody}
                style={{
                  width: '280px',
                  margin: '20px',
                }}
                onClick={() => handlePersonaSelection(items.type)}>
                <Card className={styles.cardStyling}>
                  <div className={styles.cardHeader}>
                    <img src={promptImageMap[items.id]} alt='Prompt Icon' />
                  </div>
                  <h3 className={styles.warmUp}>{items.title}</h3>
                  <p className={styles.warmUpLorem}>{items.description}</p>
                </Card>
              </div>
            ))}
          </div>
        </>
      )}
      {currentStep === 2 && (
        <>
          <div onClick={() => setCurrentStep(1)} className={styles.backButton}>
            Back
          </div>
          {personaType === BUSINESS_PERSONA && (
            <>
              <div className={styles.header}>
                <Text className={styles.textAreaLabel}>
                  Persona Name <span style={{ color: !name ? 'red' : '', fontSize: '10px' }}>*</span>
                </Text>
                <Input
                  placeholder={`Please type your Persona Name`}
                  className={styles.input}
                  style={{ maxWidth: '250px' }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className={styles.outerBorder}>
                <div className={styles.icpContainer}>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      Our ideal persona is{' '}
                      <span style={{ color: !jobTitle?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      mode='multiple'
                      maxTagCount={1}
                      placeholder='Select job titles'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '160px' }}
                      value={jobTitle}
                      onChange={setJobTitle}
                      options={personasValues}
                    />
                  </div>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      working at <span style={{ color: !industry?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      mode='multiple' // limit to 3
                      maxTagCount={1}
                      placeholder='Select up to 3 industries'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '200px' }}
                      value={industry}
                      onChange={(value: string[]) => {
                        if (value.length > 3) {
                          const latest = value?.pop()
                          value?.pop()
                          const newVal: any = [...value, latest]
                          setIndustry(newVal)
                        } else {
                          setIndustry(value)
                        }
                      }}
                      options={industryValues}
                    />
                  </div>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      industry with{' '}
                      <span style={{ color: !numberOfEmployees?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      mode='multiple'
                      maxTagCount={1}
                      placeholder='Select number of employee’s'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '230px' }}
                      value={numberOfEmployees}
                      onChange={setNumberOfEmployees}>
                      <Option value='1-10'>1-10</Option>
                      <Option value='11-50'>11-50</Option>
                      <Option value='51-200'>51-200</Option>
                      <Option value='201-500'>201-500</Option>
                      <Option value='501-2000'>501-2000</Option>
                      <Option value='2000+'>2000+</Option>
                    </Select>
                  </div>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      employees located in{' '}
                      <span style={{ color: !location?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      mode='multiple'
                      maxTagCount={1}
                      placeholder='Select Location'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '350px' }}
                      dropdownStyle={{ minWidth: '350px' }}
                      value={location}
                      onChange={setLocation}
                      options={sortedLocationOptions}
                      filterOption={(input, option) => {
                        const aliasMatch = option?.alias && option.alias.toLowerCase().includes(input.toLowerCase())
                        const labelMatch = option?.value?.toLowerCase()?.includes(input.toLowerCase())
                        return aliasMatch || labelMatch
                      }}

                      // filterOption
                      // filterOption={(input, option) => {
                      //   return (
                      //     option?.value.toLowerCase().includes(input.toLowerCase()) ||
                      //     option?.countryCode?.toLowerCase().includes(input.toLowerCase())
                      //   )
                      // }}
                    />
                  </div>
                </div>
              </div>

              <Row className={styles.formRow} gutter={[16, 16]}>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Problem</Text>
                  <TextArea
                    placeholder='What is your personas problem?'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !problem ? 'red' : '' }}
                    value={problem}
                    onChange={(e) => setProblem(e.target.value)}
                  />
                </Col>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Solutions</Text>
                  <TextArea
                    placeholder='What is your personas solution?'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !solution ? 'red' : '' }}
                    value={solution}
                    onChange={(e) => setSolution(e.target.value)}
                  />
                </Col>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Results</Text>
                  <TextArea
                    placeholder='Write a bio about yourself'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !results ? 'red' : '' }}
                    value={results}
                    onChange={(e) => setResults(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className={styles.formRow} gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: 'left' }}>
                  <Text className={styles.textAreaLabel}>Competitive Advantage</Text>
                  <Input
                    placeholder='Please type your Competitive advantage'
                    className={styles.input}
                    value={competitiveAdvantage}
                    onChange={(e) => setCompetitiveAdvantage(e.target.value)}
                    style={{ borderColor: highlightMissingValues && !competitiveAdvantage ? 'red' : '' }}
                  />
                </Col>
              </Row>
            </>
          )}

          {personaType === INDIVIDUAL_PERSONA && (
            <>
              <div className={styles.header}>
                <Text className={styles.textAreaLabel}>
                  Persona Name <span style={{ color: !name ? 'red' : '', fontSize: '10px' }}>*</span>
                </Text>
                <Input
                  placeholder={`Please type your Persona Name`}
                  className={styles.input}
                  style={{ maxWidth: '250px' }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className={styles.outerBorder}>
                <div className={styles.icpContainer}>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      Located in <span style={{ color: !location?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      mode='multiple'
                      maxTagCount={1}
                      placeholder='Select Location'
                      className={`onboardingICP`}
                      style={{ ...selectStyle, minWidth: '350px' }}
                      dropdownStyle={{ minWidth: '350px' }}
                      value={location}
                      onChange={setLocation}
                      options={sortedLocationOptions}
                      filterOption={(input, option) => {
                        const aliasMatch = option?.alias && option.alias.toLowerCase().includes(input.toLowerCase())
                        const labelMatch = option?.value?.toLowerCase()?.includes(input.toLowerCase())
                        return aliasMatch || labelMatch
                      }}
                    />
                  </div>
                  <div className={styles.textSelect}>
                    <Text className={styles.label}>
                      interested in <span style={{ color: !interests?.length ? 'red' : '', fontSize: '10px' }}>*</span>
                    </Text>
                    <Select
                      onClick={handleGetTags}
                      className='onboardingICP'
                      mode='multiple'
                      size={'middle'}
                      placeholder='Search By Interests'
                      defaultValue={[]}
                      value={interests || undefined}
                      options={hobbiesValues}
                      onChange={(value: string | string[]) => {
                        setInterests(value)
                        setNewTag('')
                      }}
                      style={{ width: '250px' }}
                      searchValue={newTag}
                      onSearch={(value: string) => {
                        setNewTag(value)
                      }}
                      onInputKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation()
                          addNewTag()
                        }
                      }}
                      notFoundContent={newTag !== '' && <Button onClick={addNewTag}>{`Add "${newTag}"`}</Button>}>
                      {tags?.length > 0 &&
                        tags?.map((tag) => <Option value={tag} label={tag} children={undefined} key={tag} />)}
                    </Select>
                  </div>
                </div>
              </div>
              <Row className={styles.formRow} gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: 'left' }}>
                  <Text className={styles.textAreaLabel}>
                    Describe your persona (Convrt AI){' '}
                    <span style={{ color: !description ? 'red' : '', fontSize: '10px' }}>*</span>
                  </Text>
                  <TextArea
                    placeholder={`What is your persona's description?`}
                    className={styles.input}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ borderColor: highlightMissingValues && !description ? 'red' : '' }}
                  />
                </Col>
              </Row>

              <Row className={styles.formRow} gutter={[16, 16]}>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Problem</Text>
                  <TextArea
                    placeholder='What is your personas problem?'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !problem ? 'red' : '' }}
                    value={problem}
                    onChange={(e) => setProblem(e.target.value)}
                  />
                </Col>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Solutions</Text>
                  <TextArea
                    placeholder='What is your personas solution?'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !solution ? 'red' : '' }}
                    value={solution}
                    onChange={(e) => setSolution(e.target.value)}
                  />
                </Col>
                <Col span={8} className={styles.textAreaStyle}>
                  <Text className={styles.textAreaLabel}>Results</Text>
                  <TextArea
                    placeholder='Write a bio about yourself'
                    maxLength={500}
                    rows={rowsNumber}
                    className={'onboardingICP'}
                    showCount
                    style={{ resize: 'none', borderColor: highlightMissingValues && !results ? 'red' : '' }}
                    value={results}
                    onChange={(e) => setResults(e.target.value)}
                  />
                </Col>
              </Row>

              <Row className={styles.formRow} gutter={[16, 16]}>
                <Col span={24} style={{ textAlign: 'left' }}>
                  <Text className={styles.textAreaLabel}>Competitive Advantage</Text>
                  <Input
                    placeholder='Please type your Competitive advantage'
                    className={styles.input}
                    value={competitiveAdvantage}
                    onChange={(e) => setCompetitiveAdvantage(e.target.value)}
                    style={{ borderColor: highlightMissingValues && !competitiveAdvantage ? 'red' : '' }}
                  />
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default OnboardingICP
