import { Col, Drawer, Row } from 'antd'
import React from 'react'
import { useAppSelector } from 'state'

import arrowLeft from 'common/assets/svg/ArrowLeft.svg'
import styles from './VideoSeeMore.module.scss'
import { ReloadOutlined } from '@ant-design/icons'
import VideoCard from '../VideoCard'
const bodyStyle: React.CSSProperties = {
  backgroundColor: '#F3F4F6',
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  paddingTop: 15,
  paddingBottom: 10,
}

interface GettingStartedDrawerProps {
  videos: any
  handleOnClose: any
  init: any
}
export default function VideoSeeMore({ videos, handleOnClose, init }: GettingStartedDrawerProps) {
  const { videoSeeMoreOpen, isAdmin } = useAppSelector((state) => state.learning)

  const title = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', height: '100%', gap: 7 }}>
        <div style={{ alignItems: 'flex-end', display: 'flex', marginBottom: 2, cursor: 'pointer' }}>
          <img src={arrowLeft} alt='' onClick={handleOnClose} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div className={styles.title}>Learning Center {'>'} Videos</div>
          <div className={styles.subTitle}>Videos</div>
        </div>
      </div>

      <div>
        {isAdmin && (
          <ReloadOutlined
            onClick={() => {
              init()
            }}
          />
        )}
      </div>
    </div>
  )
  return (
    <Drawer
      className='learning-center-drawer'
      style={{
        display: videoSeeMoreOpen ? 'flex' : 'none',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)', // Add drop shadow
      }}
      open={videoSeeMoreOpen}
      onClose={handleOnClose}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
      closable={false}
      title={title}
      width={480}
      bodyStyle={bodyStyle}>
      <div
        style={{
          width: '100%',
          maxHeight: '100%',
        }}>
        <>
          <Row gutter={[12, 12]} style={{ height: 350, justifyContent: 'left' }}>
            {(videos && Array.isArray(videos) ? [...videos] : [])
              ?.sort((a: any, b: any) => a.priority - b.priority)
              ?.map((video: any, index: number) => {
                return (
                  <Col span={12} key={index}>
                    <VideoCard video={video} key={index} />
                  </Col>
                )
              })}
          </Row>
        </>
      </div>
    </Drawer>
  )
}
