import React from 'react'
import { Popover, TableProps, Tooltip } from 'antd'
import { FancyImage } from 'features/contacts/components/FancyImage/FancyImage'
import styles from './GroupsColumns.module.scss'
import group_unlocked_icon from 'common/assets/svg/group_unlocked_icon.svg'
import group_locked_icon from 'common/assets/svg/group_locked_icon.svg'
import TruncatedTooltip from 'GeneralComponents/TruncatedTooltip'
import { TagsList } from 'features/contacts/components/TagsList/TagsList'
import { InfoCircleOutlined, LinkOutlined } from '@ant-design/icons'
import { TagsListCut } from 'features/contacts/components/TagsList/TagsListCut'

export const getGroupsColumns = (platform: string, groupType?: string): TableProps<any>['columns'] => {
  if (platform === 'linkedin') {
    const columns: any = [
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'name',
        width: 250,
        ellipsis: true,
        render: (text: string, data: any) => {
          const name = data?.name || data?.full_name
          const connectionLevel = data?.connection_level?.match(/\d+(st|nd|rd|th)/)?.[0]
          return (
            <div className={styles.full_name}>
              <FancyImage
                url={data.profile_pic_url}
                className='smallRoundPicture'
                iscompany={false}
                style={{ marginRight: '5px' }}
                size={28}
                name={name}
              />
              <div className={styles.contactName}>
                <div className={styles.flexRow}>
                  <TruncatedTooltip
                    text={name}
                    maxLength={15}
                    tooltipPlacement='top'
                    style={{ fontWeight: 600, cursor: 'pointer', fontSize: '14px' }}
                  />
                  <span>· {connectionLevel} · </span>
                  <Tooltip title={data.username ? 'Visit LinkedIn' : 'No Website Available'}>
                    <LinkOutlined
                      onClick={() => data.username && window.open(data.username, '_blank')}
                      style={{
                        color: data.username ? '#1890ff' : 'gray',
                        cursor: data.username ? 'pointer' : 'default',
                      }}
                    />
                  </Tooltip>
                </div>
                <TruncatedTooltip
                  text={data.position || data.headline}
                  maxLength={22}
                  fontSize='12px'
                  tooltipPlacement='top'
                />
              </div>
            </div>
          )
        },
      },
      // {
      //   title: 'Headline',
      //   dataIndex: 'headline',
      //   key: 'headline',
      //   width: 300,
      //   render: (text: string, data: any) => {
      //     return <TruncatedTooltip text={text} maxLength={30} tooltipPlacement='topLeft' style={{ fontSize: '14px' }} />
      //   },
      // },
      groupType !== 'search_people' && {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 280,
        ellipsis: true,

        render: (text: string, data: any) => {
          if (!text) return <span>N/A</span>
          return (
            <div className={styles.company_name}>
              <div style={{ height: '28px', width: '28px' }}>
                <FancyImage
                  url={data.company_pic_url}
                  className='smallRoundPicture'
                  iscompany={false}
                  // style={{ marginRight: '5px' }}
                  size={28}
                  name={text}
                />
              </div>
              <TruncatedTooltip
                text={text}
                maxLength={20}
                tooltipPlacement='topLeft'
                style={{ fontWeight: 600, fontSize: '14px' }}
              />
            </div>
          )
        },
      },
      {
        title: 'Location',
        dataIndex: 'location',
        ellipsis: true,

        key: 'location',
        width: 280,
        render: (text: string, data: any) => (
          <TruncatedTooltip text={text} maxLength={50} tooltipPlacement='topLeft' style={{ fontSize: '14px' }} />
        ),
      },
      groupType === 'search_people' && {
        title: 'Bio',
        dataIndex: 'position',
        key: 'position',
        ellipsis: true,
        width: 300,
        render: (text: string, data: any) => (
          <TruncatedTooltip text={text} maxLength={40} tooltipPlacement='top' style={{ fontSize: '14px' }} />
        ),
      },
      // {
      //   title: 'Experience',
      //   dataIndex: 'experience',
      //   key: 'experience',
      //   width: 300,
      //   render: (text: string, data: any) => {
      //     const experience = text?.replace('Experience: ', '')

      //     return (
      //       <TruncatedTooltip
      //         text={experience}
      //         maxLength={30}
      //         tooltipPlacement='topLeft'
      //         style={{ fontSize: '14px' }}
      //       />
      //     )
      //   },
      // },
      groupType === 'search_people' && {
        title: 'Followers',
        dataIndex: 'number_of_followers',
        key: 'number_of_followers',
        width: 125,
        ellipsis: true,
        render: (text: string, data: any) => {
          const followersText = text?.match(/^.*?(followers|Followers)/i)?.[0] || ''

          return (
            <TruncatedTooltip text={followersText} maxLength={40} tooltipPlacement='top' style={{ fontSize: '14px' }} />
          )
        },
      },
      groupType !== 'search_people' && {
        title: 'Skills',
        dataIndex: 'skills',
        key: 'skills',
        width: 150,
        ellipsis: true,

        render: (text: string, data: any) => {
          const skillsArray = text?.split('\n')
          const skillNames = skillsArray?.map((skill) => skill?.split(/ {2,}/)[0])
          if (!skillNames || skillNames?.length === 0 || skillNames?.every((skill) => skill.trim() === '')) {
            return <></>
          }

          return <TagsList tags={skillNames} sliceNum={1} />
        },
      },
      groupType !== 'search_people' && {
        title: 'About',
        dataIndex: 'about',
        key: 'about',
        width: 80,
        // ellipsis: true,

        render: (text: string, data: any) => {
          // if (groupType === 'search_people') {

          // }

          const about = data?.about?.replace(/^About:\s*/, '')?.replace(/\s*…see more$/, '')
          const experience = data?.experience
          const headline = data?.headline
          const biography = groupType === 'search_people' ? data?.position : null
          const followersText = data?.number_of_followers?.match(/^.*?(followers|Followers)/i)?.[0] || ''
          if (!about && !experience && !headline && !biography && !followersText) return <></>

          return (
            <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <Popover
                content={
                  <div
                    className={styles.popoverContent}
                    style={{
                      maxHeight: '400px',
                      overflowY: 'auto',
                      paddingRight: '10px',
                    }}>
                    {headline && (
                      <div className={styles.sectionContent}>
                        <p>Headline</p>
                        <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{data?.headline}</div>
                      </div>
                    )}
                    {experience && (
                      <div className={styles.sectionContent}>
                        <p>Experience</p>
                        <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{data?.experience}</div>
                      </div>
                    )}
                    {about && (
                      <div className={styles.sectionContent}>
                        <p>About</p>
                        <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{about}</div>
                      </div>
                    )}
                    {biography && (
                      <div className={styles.sectionContent}>
                        <p>Biography</p>
                        <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{biography}</div>
                      </div>
                    )}
                    {followersText && (
                      <div className={styles.sectionContent}>
                        <p>Followers</p>
                        <div style={{ maxWidth: '300px', whiteSpace: 'pre-wrap' }}>{followersText}</div>
                      </div>
                    )}
                  </div>
                }
                trigger='hover'
                placement='top'>
                <InfoCircleOutlined style={{ fontSize: '16px', cursor: 'pointer', color: '#7043ff' }} />
              </Popover>
            </div>
          )
        },
      },
    ]?.filter(Boolean)

    return columns
  }

  const commonColumns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'name',
      width: 170,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string, data: any) => {
        const name = data?.name || data?.full_name
        return (
          <div className={styles.full_name}>
            <FancyImage
              url={data.profile_pic_url}
              className='smallRoundPicture'
              iscompany={false}
              style={{ marginRight: '5px' }}
              size={28}
              name={name}
            />
            <div className={styles.contactName}>
              <Tooltip placement='topLeft' title={name}>
                <span style={{ fontWeight: 600, cursor: 'pointer' }}>
                  {name?.length > 12 ? `${name.substring(0, 12)}...` : name}
                  {/* {name} */}
                </span>
              </Tooltip>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Handle',
      dataIndex: 'username',
      key: 'username',
      width: 120,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string, data: any) => {
        if (data.display) {
          return (
            <Tooltip placement='topLeft' title={data?.username}>
              <span style={{ fontWeight: 600, cursor: 'pointer' }}>
                {/* {data?.username?.length > 10 ? `${data?.username.substring(0, 10)}...` : data?.username} */}
                {data?.username}
              </span>
            </Tooltip>
          )
        } else if (data?.recipient_id) {
          return (
            <Tooltip placement='topLeft' title={data?.recipient}>
              <span style={{ fontWeight: 600, cursor: 'pointer' }}>
                {data?.recipient?.length > 10 ? `${data?.recipient.substring(0, 10)}...` : data?.recipient}
              </span>
            </Tooltip>
          )
        } else {
          return (
            <span className={styles.handle}>
              {text?.length > 22 ? 'blurhandleblurhandleblur...' : 'blurhandleblurhandleblur'.slice(0, text?.length)}
            </span>
          )
        }
      },
    },
    {
      title: 'Bio',
      dataIndex: 'biography',
      key: 'biography',
      width: 325,
      ellipsis: {
        showTitle: true,
      },
      render: (text: string, data: any) => (
        <Tooltip placement='top' title={text}>
          <span style={{ fontWeight: 500, cursor: 'pointer' }}>
            {text?.length > 60 ? `${text.substring(0, 60)?.trim()}...` : text}
            {/* {text} */}
          </span>
        </Tooltip>
      ),
    },
  ]

  const platformSpecificColumns: any = {
    instagram: [
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: 120,
        ellipsis: true,

        render: (text: string) => {
          // tag with category, if category is not available, show 'N/A'
          return (
            <div>
              {text ? (
                <TagsListCut tags={[text]} sliceNum={1} />
              ) : (
                <TagsListCut tags={['N/A']} sliceNum={1} toCapitalize={false} />
              )}
            </div>
          )
        },
      },
      {
        title: 'Followers',
        dataIndex: 'follower_count',
        key: 'follower_count',
        width: 100,
        ellipsis: true,

        render: (follower_count: any, data: any) => {
          return <span>{Number(follower_count).toLocaleString()}</span>
        },
      },
    ],
    facebook: [
      // Add Facebook specific columns here
    ],
    // Add other platforms if needed
  }
  const accountStatus = {
    title: 'Account Status',
    dataIndex: 'is_private',
    key: 'is_private',
    ellipsis: true,
    width: 130,
    render: (is_private: any) => (
      <div className={styles.accountStatus}>
        <img src={is_private ? group_locked_icon : group_unlocked_icon} alt='' />
      </div>
    ),
  }
  return [...commonColumns, ...(platformSpecificColumns[platform] || []), accountStatus]
}
