import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './PointsTableTooltip.module.scss'
import { useLazyGetFormattedPointsStoreQuery } from '../state/api/SettingsApi'

interface PointsTableTooltipProps {
  isMagic?: boolean
}
const PointsTableTooltip: React.FC<PointsTableTooltipProps> = ({ isMagic }) => {
  const [getPointsStore] = useLazyGetFormattedPointsStoreQuery()
  const [pointsStore, setPointsStore] = useState<any[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getPointsStore()
        if (data?.pointsStore) {
          if (isMagic) {
            const points = data?.pointsStore?.filter((point: any) => point.type === 'magic')
            setPointsStore(points)
          } else {
            const points = data?.pointsStore?.filter((point: any) => point.type !== 'magic')
            setPointsStore(points)
          }
        }
      } catch (error) {
        console.error('Error fetching points data:', error)
      }
    }
    fetchData()
  }, [getPointsStore])

  const pointsTable = (
    <div className={styles.pointsTable}>
      <table>
        <thead>
          <tr>
            <th style={{ borderRadius: '8px 0px 0px 0px' }}>Feature</th>
            <th style={{ borderRadius: '0px 8px 0px 0px' }}>Points Cost</th>
          </tr>
        </thead>
        <tbody>
          {pointsStore?.length > 0 ? (
            pointsStore.map((item, index) => (
              <tr key={index}>
                <td>{item.feature}</td>
                <td>{item.pointsCost}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={2}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )

  return (
    <Tooltip
      title={pointsTable}
      placement='bottom'
      trigger={['hover']}
      color='transparent'
      overlayInnerStyle={{ boxShadow: 'none', width: '280px' }}>
      <InfoCircleOutlined style={{ cursor: 'pointer', marginLeft: '0px', color: '#5E6C84' }} />
    </Tooltip>
  )
}

export default PointsTableTooltip
